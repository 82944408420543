import React from 'react';
import cx from 'classnames';

import InstructionLabel from '../../molecules/InstructionLabel';
import TicketList from '../TicketList';

import styles from './SelectTicket.module.scss';
import usePresenter from './SelectTicket.presenter';
import getProps from './SelectTicket.props';
import { SelectTicketCombinedProps } from './types';


const SelectTicket: React.FC<SelectTicketCombinedProps> = (props) => {
  const {
    className,
    classes,
    instructionLabel,
    ticketList,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.selectTicket;

  return (
    <div className={cx(currentStyle, className)}>
      <InstructionLabel
        className={cx(styles.instructionLabel, classes?.instructionLabel)}
        {...internalProps.instructionLabel}
        {...instructionLabel}/>
      <TicketList
        className={cx(styles.ticketList, classes?.ticketList)}
        {...internalProps.ticketList}
        {...ticketList}/>
    </div>
  );
};

export default SelectTicket;

