import React, { useMemo } from 'react';
import { TextSVG } from '../../../types';
import { getTransform } from '../../../utils';
import styles from './styles.module.scss';

const getTspan = (item: TextSVG) => {
  const multiplier = 0.34;
  const fontSize = parseFloat(item['font-size'] || '10');
  const tspanDy = fontSize * multiplier;

  return <tspan dy={tspanDy}>{item.text}</tspan>;
};

interface TextsContainerProps {
  texts: Array<TextSVG>
}

const TextsContainer: React.FC<TextsContainerProps> = ({ texts = [] }) => {
  const textsView = useMemo(() => {
    return texts.map((item, index) => {
      const renderProps = {
        transform: getTransform(item.transform),
        fontSize: item['font-size'],
        fontFamily: item['font-family'],
        textAnchor: item['text-anchor'],
        fill: item.fill,
        fontWeight: item['font-weight'],
        stroke: item.stroke,
        x: Number(item.x || 0),
        y: Number(item.y || 0),
      };

      const tspan = getTspan(item);

      return (
            <text key={`${index}-${item.text}`} {...renderProps} className={styles.text}>
                {tspan}
            </text>
      );
    });
  }, [texts]);
  return (
        <>
            {textsView}
        </>
  );
};

const memoizedTextsContainer = React.memo(TextsContainer);

export default memoizedTextsContainer;