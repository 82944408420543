import { useContext, useState, useEffect } from 'react';
import { useMutation } from 'react-query';
import { removeDuplicates, filterOutMLB, removePastEvents } from './util';
import { handleTagFilter } from '../../../lib/util';
import { AuthContext } from '../../../modules/auth';
import exclusiveCategoryEvents from '../../../modules/navigation/ExclusiveCategoryEvents.json';
import { Event } from '../../../modules/partnership';
import { getEvents } from '../../../modules/partnership/api';
import { ExclusivesEventBlockCombinedProps, ExclusivesEventBlockPresenterProps } from './types';

const useInteractor = (props: ExclusivesEventBlockCombinedProps): ExclusivesEventBlockPresenterProps => {
  const { account } = useContext(AuthContext);
  const { mutateAsync: fetchEvents, isLoading } = useMutation(getEvents);
  const [events, setEvents] = useState<Event[]>([]);
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const doFetchEvents = async () => {
      try {
        if (account) {
          const updatedQueryParam = handleTagFilter({ category: 'exclusive' }, account);
          const response = await fetchEvents(updatedQueryParam);
          response.items = removeDuplicates(response?.items || []);
          response.items = filterOutMLB(response.items);
          response.items = removePastEvents(response.items);
          setShowButton(response.total > 5);
          let eventsArr = [...response.items];
          // Only if there are not enough events to show exclusive widget
          if (eventsArr.length < 2) {
            for (let index = 0; index < exclusiveCategoryEvents.length; index++) {
              const category = exclusiveCategoryEvents[index];
              const categoryRequestQueryParam = handleTagFilter({ category: category.id }, account);
              const categoryResponse = await fetchEvents(categoryRequestQueryParam);
              categoryResponse.items = filterOutMLB(categoryResponse.items);
              categoryResponse.items = removePastEvents(categoryResponse.items);
              eventsArr = removeDuplicates([...eventsArr, ...categoryResponse.items]);
            }
          }
          // Sort events based on the event date
          eventsArr.sort((a, b) => {
            const aDate = (new Date(a.utc_date)).getTime();
            const bDate = (new Date(b.utc_date)).getTime();
            return aDate - bDate;
          });
          let total = Math.min(5, eventsArr.length);
          if (total < 5 && total > 2) {
            total = 2;
          }
          if (response.total > total) {
            setShowButton(true);
          }
          // Limiting the events to show only 3 or 6 cards in widget
          eventsArr.length = total;
          setEvents(eventsArr);
        }
      } catch {
        // TODO
      }
    };
    void doFetchEvents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchEvents, account]);

  return {
    ...props,
    account,
    events,
    fetchEvents,
    isLoading,
    showMoreEventsButton: showButton,
  };
};

export default useInteractor;