import cx from 'classnames';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useHistory, useLocation } from 'react-router-dom';

import {
  C1_BROKER_ID, CP1_EXCLUSIVE_TAG, REWARDS_ONLY_TAG, SPLIT_PAY_TAG, TERMS_MODAL,
} from '../../../lib/constants';
import { getTranslation as getTranslationGlobal } from '../../../lib/reactIUtils';
import { AriaLiveRegions, AriaRoles, UnitDisplaySettings } from '../../../lib/types';
import {
  CheckoutSteps, addQueryParam, getFaceValue, getFiveDigitsZipCode, getTicketListingTranslationKey,
  getTwoDigitsCountryCode, getUnitDisplaySettings, getUpdatedPhoneNumber, handleAmountFormattingString,
  handleDecimalValuesForDisplay, handleDisplayUnitName, handleFormattedNumberToNumberString, handleOrderTotalMiles, isValidPhoneNumber,
  setUpdatedPhoneNumber, shouldShowTermsAndConditionsCheckbox, useWindowSize,
} from '../../../lib/util';
import { useAnalyticsManager } from '../../../modules/analytics/useAnalyticsManager';
import { getAuthData } from '../../../modules/auth/AuthApi';
import { CardData, ListingDetails, Order } from '../../../modules/partnership';

import GenericErrorAsset from '../../../resources/legacyIcons/GenericError.svg';

import { ButtonCombinedProps } from '../../atoms/Button';
import { CheckBoxItemStateEnum } from '../../atoms/CheckBoxItem/types';
import { BillingInfoBlockCombinedProps } from '../../blocks/BillingInfoBlock/types';
import { CustomerInfoBlockCombinedProps } from '../../blocks/CustomerInfoBlock/types';
import { CustomerInfoReadOnlyBlockCombinedProps } from '../../blocks/CustomerInfoReadOnlyBlock/types';
import { ErrorBlockValueProps } from '../../blocks/ErrorBlock';
import { LegalBlockCombinedProps } from '../../blocks/LegalBlock/types';
import { LoyaltyBlockCombinedProps } from '../../blocks/LoyaltyBlock/types';
import { OrderInfoCombinedProps } from '../../blocks/OrderInfo/types';
import { PaymentInformationReadOnlyBlockCombinedProps } from '../../blocks/PaymentInformationReadOnlyBlock/types';
import { findPreviousPurchases, getEventPurchaseLimit } from '../../blocks/PrecheckoutBlock/utils';
import { StepperBlockCombinedProps } from '../../blocks/StepperBlock/types';
import { ContentStateEnum } from '../../molecules/Content/types';
import { RadioItemTypeEnum } from '../../molecules/RadioItem/types';
import { TextItemValueProps } from '../../molecules/TextItem';
import { TicketAlertModalProps } from '../../organisms/TicketAlertModal';

import useInteractor from './CheckoutManualPage.interactor';
import styles from './CheckoutManualPage.module.scss';
import customStyles from './Custom.module.scss';
import { CheckoutManualPageCombinedProps, LocationState, OrderItems } from './types';
import {
  BuildCheckoutProps, formatter, getCodeFromStep,
  getDollarValueForRewardPoints,
  getFormFromCode, getFormattedRewardPoints, round, roundUp, updateStepInQuery,
} from './utils';

export const getTranslation = (
  transKey: string,
  renderModal: (event?: React.MouseEvent<HTMLElement>) => void,
): React.ReactNode | undefined => {
  return (
    <Trans
      key={transKey}
      i18nKey={transKey}
      components={{
        hyperlink: (
          <Link
            onClick={renderModal}
            to={''}
            style={{ textDecoration: 'underline' }}
          />
        ),
      }}
    ></Trans>
  );
};

export const getApplyRewardsOptinTitleTranslation = (transKey: string, values?: Record<string, string>): React.ReactNode => {
  return (
    <Trans
      key={transKey}
      i18nKey={transKey}
      values={values}
      components={{
        semibold: (
          <span className={customStyles.boldTitle} />
        ),
      }}
    ></Trans>
  );
};

export const getEmailTranslation = (transKey: string): React.ReactNode => {
  return (
    <Trans
      key={transKey}
      i18nKey={transKey}
      components={{
        hyperlink: (
          // eslint-disable-next-line jsx-a11y/anchor-has-content
          <a
            href="mailto:care@vividseats.com"
            style={{ textDecoration: 'underline' }}
          />
        ),
      }}
    ></Trans>
  );
};

const usePresenter = (props: CheckoutManualPageCombinedProps): CheckoutManualPageCombinedProps => {
  const {
    account,
    step,
    ticketInfo,
    quantity,
    error,
    createOrder,
    refetchAccount,
    setAccountExternally,
    fetchOrders,
    isLoading,
  } = useInteractor(props);
  const { t } = useTranslation();
  const location = useLocation<LocationState>();
  const history = useHistory();

  const { width: windowSize } = useWindowSize();
  const { trackEvent } = useAnalyticsManager();

  useEffect(() => {
    // load the ioBlackBox library
    const initialScript = document.createElement('script');
    initialScript.innerHTML =
      "var io_bbout_element_id = 'ioBlackBox'; var io_install_stm = false; var io_install_flash = false; var io_exclude_stm = 12;";
    const accertifyScript = document.createElement('script');
    accertifyScript.setAttribute(
      'src',
      'https://mpsnare.iesnare.com/snare.js',
    );
    document.head.appendChild(initialScript);
    document.head.appendChild(accertifyScript);
    return () => {
      document.head.removeChild(initialScript);
      document.head.removeChild(accertifyScript);
    };
  }, []);

  let validatedStep = step;
  const hardTicket: boolean = ticketInfo
    ? t(
      getTicketListingTranslationKey(
        ticketInfo,
        'requiresShippingInfo',
      ),
    ) === 'true'
    : false;
  const confirmationPath = '/confirmation';
  const totalSteps = hardTicket ? 5 : 4;
  const purchaseLimit = getEventPurchaseLimit(ticketInfo?.event.tags || []);
  const unitName = handleDisplayUnitName(account);
  const [phoneNumberPrivacyChecked, setPhoneNumberPrivacyChecked] = useState(false);
  const [phoneNumberValidation, setPhoneNumberValidation] = useState(true);
  const [phoneNumberCheckboxValidation, setPhoneNumberCheckboxValidation] = useState(true);
  const [phoneNumberFieldValue, setPhoneNumberFieldValue] = useState('');
  const [toggleEditPhoneNumber, setToggleEditPhoneNumber] = useState(false);
  const [isTicketAlertModalOpen, setIsTicketAlertModalOpen] = useState(false);
  const [previousTickets, setPreviousTickets] = useState(0);
  const [hasAcceptedTerms, setHasAcceptedTerms] = useState(false);
  const [orderDisabled, setOrderDisabled] = useState(true);

  const [radioItemSelectionState, setRadioItemSelctionState] = useState<RadioItemTypeEnum | undefined>(undefined);

  useEffect(() => {
    const showTermsAndConditionsCheckbox = shouldShowTermsAndConditionsCheckbox(
      ticketInfo as ListingDetails,
    );
    setOrderDisabled(showTermsAndConditionsCheckbox);
  }, [ticketInfo]);

  useEffect(() => {
    const doFetchEvents = async () => {
      try {
        if (account && purchaseLimit && fetchOrders) {
          const response = await fetchOrders();
          setPreviousTickets(
            findPreviousPurchases(
              response.orders,
              ticketInfo?.event.id.toString() || '',
            ),
          );
        }
      } catch {
        // TODO
      }
    };
    void doFetchEvents();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchOrders]);

  const renderModal = (modalType: string): void => {
    const params = addQueryParam(history.location.search, {
      modal: modalType,
    });

    if (params) {
      history.push({
        search: params,
      });
    }
  };

  const openModal = (e?: React.MouseEvent<HTMLElement>) => {
    e?.preventDefault();
    renderModal(TERMS_MODAL);
  };

  const lastCompleted: number = +(
    window.sessionStorage.getItem('lastCompleted') || 0
  );
  const rewardType = account?.loyalty_program.loyalty_unit_name.toLowerCase();
  const { rewardSign, useDecimals }: UnitDisplaySettings =
    getUnitDisplaySettings(rewardType);
  const hasCp1BrokerId: boolean =
    ticketInfo?.listing.broker_id === C1_BROKER_ID;
  const isCp1Exclusive: boolean | undefined =
    ticketInfo?.event.tags?.includes(CP1_EXCLUSIVE_TAG) && hasCp1BrokerId;
  const isRewardsOnly: boolean | undefined =
    ticketInfo?.event.tags?.includes(REWARDS_ONLY_TAG);
  const isSplitPay: boolean | undefined =
    ticketInfo?.event.tags?.includes(SPLIT_PAY_TAG);
  const showLoyaltyBlockInSplitPay = isSplitPay &&
    (account?.loyalty_program.number_of_units || 0) > 0;
  const isCardOnly: boolean | undefined =
      !isRewardsOnly && !showLoyaltyBlockInSplitPay;
  const [cardDetails, setCardDetails] = useState<CardData>();
  const decimalPoints = useDecimals ? 2 : 0;

  // if we are beyond page one, make sure they have submitted the last page
  if (!!step && step > 1 && step > lastCompleted + 1) {
    // if not, then stay in last completed page
    const backStep = lastCompleted + 1;
    validatedStep = backStep;
  }
  const {
    customerBasicProps,
    billingBasicProps,
    loyaltyBasicProps,
    legalBasicProps,
    customerInfoROBascProps,
    paymentInfoROBascProps,
    stepperBasicProps,
    ticketBasicProps,
    orderErrorBlockBasicProps,
  } = BuildCheckoutProps(
    account || null,
    ticketInfo || null,
    hardTicket,
    isCp1Exclusive || false,
    isRewardsOnly || false,
    useDecimals || false,
    rewardSign || '',
  );
  useEffect(() => {
    if (billingBasicProps.phoneNumber?.description?.value) {
      setPhoneNumberFieldValue(
        getUpdatedPhoneNumber() ||
          billingBasicProps.phoneNumber?.description?.value
            ?.toString()
            .replace(/\s/g, '') ||
          '',
      );
    } else {
      setToggleEditPhoneNumber(true);
    }
  }, [billingBasicProps.phoneNumber?.description?.value]);

  useEffect(() => {
    setUpdatedPhoneNumber(phoneNumberFieldValue);
  }, [phoneNumberFieldValue]);

  // Slider logic
  const valuePerPoint: number =
    account?.loyalty_program.redeem_per_dollar || 1;
  const availablePoints: number =
    account?.loyalty_program.number_of_units || 0;
  const totalCostInPoints: number =
    (ticketInfo?.pricing.total || 0) / valuePerPoint;
  const totalCostInDollars: number =
    (ticketInfo?.pricing.total || 0);
  // slider goes up to total cost or as much as available to buy with points
  const maximumRewardPointsToBeUsed: number = useMemo(() => {
    const maximumPossibleValue = availablePoints >= totalCostInPoints || isRewardsOnly
      ? totalCostInPoints
      : availablePoints;

    const maxReward = useDecimals ? maximumPossibleValue : roundUp(maximumPossibleValue);
    return maxReward;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availablePoints, totalCostInPoints, useDecimals]);

  // This state is to store the value of reward points that's saved by user
  const [rewardPointsToBeRedeemed, setRewardPointsToBeRedeemed] = useState<number>(maximumRewardPointsToBeUsed);

  const [showLoayltyBlockError, setShowLoayltyBlockError] = useState(false);
  const [loyaltyBlockErrorMessage, setLoyaltyBlockErrorMessage] = useState('');
  const [rewardEditState, setRewardEditState] = useState<ContentStateEnum>('Default');
  const isEditingRewardPoints = useMemo(() => {
    return rewardEditState === 'Edit';
  }, [rewardEditState]);

  // This state is to store the value of reward points that's actively being edited by user
  const [activeEditedRewardPoints, setActiveEditedRewardPoints] = useState<string>('');

  // this state is to store edited rewards points after being formatted to show in OrderInfo
  const [editedRewardPoints, setEditedRewardPoints] = useState<number>(0);
  const [isTicketInfoLoaded, setIsTicketInfoLoaded] = useState(false);

  const [dollarValue, setDollarValue] = useState(0);

  // initiating the states after ticketInformatio has been fetched
  if (ticketInfo?.pricing.total && !isTicketInfoLoaded) {
    setActiveEditedRewardPoints(maximumRewardPointsToBeUsed.toString());
    setEditedRewardPoints(0);
    setRewardPointsToBeRedeemed(maximumRewardPointsToBeUsed);
    setDollarValue(isCardOnly ? totalCostInDollars : getDollarValueForRewardPoints(maximumRewardPointsToBeUsed, valuePerPoint));
    setIsTicketInfoLoaded(true);
  }

  const isApplyingRewards = useMemo(() => {
    return radioItemSelectionState === 'Apply';
  }, [radioItemSelectionState]);

  // TODO: disable/enable card form based on user entered value in active edit input field for reward amount
  const cardDisabled = useMemo(() => {
    return isApplyingRewards && rewardPointsToBeRedeemed >= totalCostInPoints;
  }, [rewardPointsToBeRedeemed, isApplyingRewards, totalCostInPoints]);

  /* this is triggered everytime there is a reward points input value change, to format
  the display value and update the reward points again with formatted values
  after a delay of 1 second, which resets on every typed character */
  useEffect(() => {
    const timeOutId = setTimeout(() => {
      const formattedValue = getFormattedRewardPoints(
        activeEditedRewardPoints, availablePoints, maximumRewardPointsToBeUsed, useDecimals,
      );

      if (!isRewardsOnly) {
        setDollarValue(getDollarValueForRewardPoints(formattedValue, valuePerPoint));
      }

      const updatedRewardPoints = handleDecimalValuesForDisplay(
        handleAmountFormattingString(formattedValue),
        useDecimals,
      );
      setActiveEditedRewardPoints(updatedRewardPoints);
      // updating it only when "Apply Rewards" is selected
      if (isApplyingRewards) {
        setEditedRewardPoints(formattedValue);
      }
    }, 1000);
    return () => clearTimeout(timeOutId);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeEditedRewardPoints]);

  const remainingCost = useMemo(() => {
    if (isCardOnly || !isApplyingRewards) {
      return totalCostInDollars;
    }

    // only if applying rewards remaining cost will be less than totalCost in dollars
    const remainingResult: number =
      totalCostInDollars - (dollarValue || 0);
    return remainingResult <= 0 ? 0 : remainingResult;
  }, [dollarValue, isApplyingRewards]);

  // Payment logic
  const ioBlackBoxRef = useRef<HTMLInputElement>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [orderItems, setOrderItems] = useState<OrderItems>();

  const handleInputChanged = (
    e: React.ChangeEvent<
    HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >,
  ) => {
    setPhoneNumberFieldValue(e.target.value);
  };

  const [orderLoading, setOrderLoading] = useState(false);

  const defaultButtonAction = async () => {
    if (!phoneNumberPrivacyChecked && validatedStep && validatedStep > 1) {
      setPhoneNumberCheckboxValidation(false);
      return;
    } else if (
      !isValidPhoneNumber(phoneNumberFieldValue || '') &&
      validatedStep &&
      validatedStep > 1
    ) {
      setPhoneNumberValidation(false);
      return;
    }
    if (
      validatedStep &&
      validatedStep >= totalSteps &&
      createOrder &&
      (cardDetails || remainingCost <= 0)
    ) {
      const isPurchaseLimitCrossed =
        purchaseLimit < (quantity || 0) + previousTickets;
      
      const newAccount = await getAuthData();

      if (isPurchaseLimitCrossed) {
        // error for purchase limit
        setIsTicketAlertModalOpen(true);
      } else if (
        isSplitPay &&
        radioItemSelectionState === 'Apply' && // this error should thrown only when user has selected to apply rewards
        newAccount?.loyalty_program.number_of_units &&
        newAccount.loyalty_program.number_of_units < rewardPointsToBeRedeemed
      ) {
        // error for no longer enough rewards
        const checkoutErrorBlockProps: ErrorBlockValueProps = {
          image: {
            imageSrc: GenericErrorAsset,
          },
          title: {
            value: t('insufficientRewards.title'),
          },
          message: {
            value: t('insufficientRewards.message'),
          },
          button: {},
        };

        history.push('/checkout-error', checkoutErrorBlockProps);
      } else if (
        isRewardsOnly &&
        newAccount?.loyalty_program.number_of_units &&
        newAccount.loyalty_program.number_of_units < totalCostInPoints
      ) {
        const checkoutErrorBlockProps: ErrorBlockValueProps = {
          image: {
            imageSrc: GenericErrorAsset,
          },
          title: {
            value: t('insufficientRewards.title'),
          },
          message: {
            value: t('insufficientRewards.message'),
          },
          button: {},
        };

        history.push('/checkout-error', checkoutErrorBlockProps);
      } else {
        try {
          let rewardPoints = 0;

          // redeem reward points only if apply reward points radio item is selected
          if (isApplyingRewards) {
            rewardPoints = rewardPointsToBeRedeemed > totalCostInPoints ? 
              handleOrderTotalMiles(
                totalCostInPoints,
                newAccount?.loyalty_program?.loyalty_unit_name,
              )
              : handleOrderTotalMiles(
                rewardPointsToBeRedeemed,
                newAccount?.loyalty_program?.loyalty_unit_name,
              );
          }

          // make an api call to check the validation
          const requestParams: Order = {
            id: 0,
            billing: {
              payment_nonce: cardDetails?.payload?.nonce || '',
              is_default: false,
              billing_address: {
                address_line_1: newAccount?.address.address_line1 || '',
                address_line_2: newAccount?.address.address_line2,
                address_line_3: newAccount?.address.address_line3,
                city: newAccount?.address.city || '',
                region: newAccount?.address.state_code || '',
                postal_code: newAccount
                  ? getFiveDigitsZipCode(
                    newAccount.address.country_code,
                    newAccount.address.postal_code,
                  )
                  : '',
                country: getTwoDigitsCountryCode(
                  newAccount?.address.country_code || '',
                ),
                first_name: newAccount?.first_name || '',
                last_name: newAccount?.last_name || '',
                phone_number: getUpdatedPhoneNumber() || newAccount?.phone || '',
              },
            },
            account: {
              email_address: newAccount?.email || '',
              first_name: newAccount?.first_name || '',
              last_name: newAccount?.last_name || '',
              marketing_optin: false,
              phone_number: newAccount?.phone || '',
            },
            assumed_price_per: ticketInfo?.listing.price_per || 0,
            delivery_id: ticketInfo?.pricing.delivery.id || 0,
            insurance: {
              declined: true,
            },
            loyalty: undefined,
            offer: undefined,
            override_order_email: false,
            payment_method: undefined,
            io_black_box:
              cardDetails?.ioBlackBox || ioBlackBoxRef.current?.value || '',
            production_id: ticketInfo?.listing.event_id || 0,
            quantity: ticketInfo?.pricing.quantity || 0,
            quote_id: ticketInfo?.pricing.id || '',
            redeem: {
              amount: rewardPoints,
              loyalty_unit_name:
              newAccount?.loyalty_program?.loyalty_unit_name || '',
            },
            order_total: {
              amount: ticketInfo?.pricing?.total || 0,
              currency: 'USD',
            },
            ticket_id: ticketInfo?.listing.id || '',
            exclusive_listings: hasCp1BrokerId,
          };
          setOrderLoading(true);
          const order = await createOrder(requestParams);
          refetchAccount?.();
          const currentOrderItems: OrderItems = {
            order: order,
            quantity: quantity,
            rewardsUsed: round(rewardPoints, 2),
            dollarsUsed: remainingCost,
            isRewardsOnly: isRewardsOnly,
            isCp1Exclusive: isCp1Exclusive,
            listing: ticketInfo,
          };
          setOrderItems(currentOrderItems);
          setOrderLoading(false);
          trackEvent('purchase', {
            orderId: order.id,
          });
          history.push(confirmationPath, { currentOrderItems });
        } catch {
          history.push('/checkout-error', orderErrorBlockBasicProps);
        }
      }
      setAccountExternally?.(newAccount);
    } else {
      setPhoneNumberCheckboxValidation(true);
      setPhoneNumberValidation(true);
      window.sessionStorage.setItem(
        'lastCompleted',
        (validatedStep || 0).toString(),
      );
      
      history.push(
        updateStepInQuery((validatedStep || 0) + 1, history.location.search),
        { location },
      );
    }
  };
  // All prop declarations
  const ticketAlertModalProps: TicketAlertModalProps = {
    open: isTicketAlertModalOpen,
    closeButton: {
      onClick: () => setIsTicketAlertModalOpen(false),
    },
    title: {
      value: t('purchaseLimitError.title'),
    },
    message: {
      value: t('purchaseLimitError.message', {
        purchaseLimit,
      }),
    },
    primaryButton: {
      text: {
        value: t('insufficientRewards.button'),
      },
      onClick: () => setIsTicketAlertModalOpen(false),
    },
  };

  // TODO move definitions into CustomerInfoBlock VIPER
  const customerInfoProps: CustomerInfoBlockCombinedProps = {
    ...customerBasicProps,
    button: {
      text: {
        value: t('customerInfo.button'),
      },
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      onClick: defaultButtonAction,
    },
  };

  // TODO move definitions into BillingInfoBlock VIPER
  const billingInfoProps: BillingInfoBlockCombinedProps = {
    ...billingBasicProps,
    classes: {
      checkBoxItemError: cx({
        [customStyles.phoneNumberPrivacyErrorMessage]:
          phoneNumberCheckboxValidation,
      }),
      button: customStyles.billingInfoButton,
    },
    ariaLive: AriaLiveRegions.ASSERTIVE,
    phoneNumberEditButton: {
      text: {
        value: t('billingInfo.phoneNumberEditButton'),
      },
      onClick: () => {
        setToggleEditPhoneNumber(true);
      },
    },
    phoneNumberSaveButton: {
      text: {
        value: t('billingInfo.phoneNumberSaveButton'),
      },
      onClick: () => {
        if (isValidPhoneNumber(phoneNumberFieldValue || '')) {
          setPhoneNumberValidation(true);
          setToggleEditPhoneNumber(false);
        } else {
          setPhoneNumberValidation(false);
        }
      },
    },
    checkBoxItem: {
      className: cx(props.checkBoxItem?.className, customStyles.customCheckbox),
      classes: {
        icon: cx(customStyles.phoneNumberPrivacyCheckboxIcon, {
          [customStyles.phoneNumberPrivacyCheckboxIconError]:
            !phoneNumberCheckboxValidation,
        }),
      },
      text: {
        size: 'Medium',
        value: t('billingInfo.phoneNumberTermsCheckbox'),
      },
      state: phoneNumberPrivacyChecked ? 'Selected' : 'Unselected',
      onCheckBoxItemClicked: (state?: CheckBoxItemStateEnum) => {
        setPhoneNumberPrivacyChecked(state === 'Selected');
        if (state === 'Selected') setPhoneNumberCheckboxValidation(true);
      },
    },
    checkBoxItemError: {
      value: t('billingInfo.phoneNumberTermsCheckboxError'),
      ariaRole: AriaRoles.ALERT,
      ariaAtomic: true,
    },
    phoneNumberField: {
      ...billingBasicProps.phoneNumberField,
      textInput: {
        ...billingBasicProps.phoneNumberField?.textInput,
        onTextChanged: handleInputChanged,
        textValue: phoneNumberFieldValue,
      },
      error: {
        ...billingBasicProps.phoneNumberField?.error,
        ariaRole: AriaRoles.ALERT,
        ariaAtomic: true,
      },
      ariaLive: AriaLiveRegions.ASSERTIVE,
      state: !phoneNumberValidation
        ? 'Error'
        : 'Default',
    },
    button: {
      text: {
        value: t('billingInfo.button'),
      },
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      onClick: defaultButtonAction,
    },
    phoneNumber: {
      ...billingBasicProps.phoneNumber,
      className: customStyles.phoneNumberTextItem,
      description: {
        ...billingBasicProps.phoneNumber?.description,
        value: phoneNumberFieldValue,
      },
    },
    shouldEnableTextField: toggleEditPhoneNumber,
  };

  const loyaltyProps: LoyaltyBlockCombinedProps = {
    ...loyaltyBasicProps,
    showError: showLoayltyBlockError,
    highlightMessage: {
      message: {
        ariaAtomic: true,
        ariaRole: AriaRoles.ALERT,
        value: loyaltyBlockErrorMessage,
      },
    },
    rewardOptionsList: {
      radioItems: [
        {
          state: isApplyingRewards ? 'Selected' : 'Default',
          type: 'Apply',
          content1: {
            state: rewardEditState,
            textRedeem: {
              value: t('loyaltyBlock.applyRewardsOption.description.redeem'),
            },
            texMiles: {
              value: handleDecimalValuesForDisplay(
                handleAmountFormattingString(rewardPointsToBeRedeemed.toFixed(decimalPoints)),
                useDecimals,
              ),
            },
            textMilesFor: {
              value: t('loyaltyBlock.applyRewardsOption.description.milesFor', {
                rewardType: unitName,
              }),
            },
            textDollar: {
              value: useDecimals
                ? ''
                : formatter.format(dollarValue || 0),
            },
            textInput: {
              ariaLive: AriaLiveRegions.POLITE,
              ariaAtomic: false,
              ariaLabel: handleAmountFormattingString(editedRewardPoints),
              textValue: activeEditedRewardPoints,
              onTextChanged: (event) => {
                setActiveEditedRewardPoints(event?.target?.value);
              },
              // To not select "Apply Rewards" option when clicked here
              onClick: (e) => {
                e?.stopPropagation();
              },
            },
            button: {
              text: {
                size: 'Medium',
                value: t('loyaltyBlock.applyRewardsOption.description.editButton'),
              },
              onClick: () => {
                setRewardEditState('Edit');
              },
            },
            saveButton: {
              text: {
                size: 'Medium',
                value: t('loyaltyBlock.applyRewardsOption.description.saveButton'),
              },
              onClick: (e) => {
                // To not trigger "Apply Rewards" option click when clicked on save button
                e?.stopPropagation();
                const rewardPointsNumberString = handleFormattedNumberToNumberString(activeEditedRewardPoints);
                const currentRewardPoints = useDecimals
                  ? round(rewardPointsNumberString)
                  : round(rewardPointsNumberString, decimalPoints);
                setRewardPointsToBeRedeemed(currentRewardPoints);
                setEditedRewardPoints(currentRewardPoints);

                // for reverting to default state
                setRewardEditState('Default');
                setShowLoayltyBlockError(false);
              },
            },
            cancelButton: {
              text: {
                size: 'Medium',
                value: t('loyaltyBlock.applyRewardsOption.description.cancelButton'),
              },
              onClick: () => {
                setDollarValue(getDollarValueForRewardPoints(rewardPointsToBeRedeemed, valuePerPoint));
                setActiveEditedRewardPoints(rewardPointsToBeRedeemed.toString());
                setRewardEditState('Default');
              },
            },
          },
          icon: {
            asset: isApplyingRewards ? 'RadioFilled' : 'RadioEmpty',
          },
          title: {
            value: getApplyRewardsOptinTitleTranslation('loyaltyBlock.applyRewardsOption.title', {
              rewardsBalance: handleAmountFormattingString(availablePoints),
              rewardType: unitName,
            }),
          },
          onClick: () => {
            setRadioItemSelctionState('Apply');
            setShowLoayltyBlockError(false);
            setEditedRewardPoints(rewardPointsToBeRedeemed);
          },
        },
        {
          state: radioItemSelectionState === 'NotApply' ? 'Selected' : 'Default',
          type: 'NotApply',
          title: {
            value: t('loyaltyBlock.doNotApplyRewards.title'),
          },
          icon: {
            asset: radioItemSelectionState === 'NotApply' ? 'RadioFilled' : 'RadioEmpty',
          },
          onClick: () => {
            // showing error for edit mode is still on
            if (isEditingRewardPoints) {
              setLoyaltyBlockErrorMessage(t('loyaltyBlock.errorMessages.editModeOnError'));
              setShowLoayltyBlockError(true);
              return;
            }

            setRadioItemSelctionState('NotApply');
            setShowLoayltyBlockError(false);
            setEditedRewardPoints(0);
          },
        },
      ],
    },
  };

  const stepperBlockProps: StepperBlockCombinedProps = {
    ...stepperBasicProps,
  };
  const notACapOneBroker =
    ticketInfo &&
    ticketInfo.listing &&
    C1_BROKER_ID !== ticketInfo.listing.broker_id;
  const showFaceValueCheck =
    (ticketInfo &&
      ticketInfo.listing &&
      ticketInfo.listing.show_face_value &&
      notACapOneBroker &&
      (validatedStep || 1) > 1) ||
    false;

  // TODO move definitions into OrderInfo VIPER
  const orderInfoProps: OrderInfoCombinedProps = {
    ...ticketBasicProps,
    classes: {
      topContent: (validatedStep || 1) !== 1 ? '' : customStyles.topContent,
    },
    showOrderDetails: (validatedStep || 1) !== 1,
    showFaceValue: showFaceValueCheck,
    miles: {
      value: t('ticketInfo.orderTotal.rewardsTemplate', {
        value: useDecimals
          ? formatter.format(editedRewardPoints)
          : handleAmountFormattingString(editedRewardPoints) || 0,
        unit: unitName,
      }),
    },
    amount: {
      value: t('ticketInfo.orderTotal.chargeWithCurrencyTemplate', {
        charge: formatter.format(isApplyingRewards ? remainingCost : totalCostInDollars),
        currency: ticketInfo?.pricing.currency,
      }),
    },
    seatInfo: {
      ...ticketBasicProps.seatInfo,
      quantity: {
        ...ticketBasicProps.seatInfo?.quantity,
        description: {
          ...ticketBasicProps.seatInfo?.quantity?.description,
          value: quantity,
        },
      },
    },
    isMobileDevice: !!windowSize && windowSize < 1024,
    faceValue: {
      ...ticketBasicProps.faceValue,
      value: t('ticketInfo.orderTotal.faceValueTemplate', {
        faceValue: getFaceValue(ticketInfo?.listing, t),
      }),
    },
  };

  const customerInfoROProps: CustomerInfoReadOnlyBlockCombinedProps = {
    ...customerInfoROBascProps,
  };
  const showCharge = remainingCost > 0 || !isApplyingRewards;
  const showRewards = (rewardPointsToBeRedeemed || 0) > 0 && isApplyingRewards;
  const rewardProps: TextItemValueProps = {
    ...paymentInfoROBascProps.rewards,
    description: {
      ...paymentInfoROBascProps.rewards?.description,
      colour: 'BaseDark',
      value: t('billingInfo.rewardsTemplate', {
        value: `${rewardSign}${handleDecimalValuesForDisplay(
          handleAmountFormattingString(round(rewardPointsToBeRedeemed, 2)),
          useDecimals,
        )}`,
        unit: unitName,
      }),
    },
  };
  const paymentInfoROProps: PaymentInformationReadOnlyBlockCombinedProps = {
    ...paymentInfoROBascProps,
    phoneNumber: {
      ...paymentInfoROBascProps.phoneNumber,
      description: {
        ...paymentInfoROBascProps.phoneNumber?.description,
        value: phoneNumberFieldValue,
      },
    },
    paymentMethod: showCharge
      ? {
        ...paymentInfoROBascProps.paymentMethod,
        description: {
          ...paymentInfoROBascProps.paymentMethod?.description,
          value: t('billingInfo.paymentTemplate', {
            cardNumber: account?.loyalty_program.last_four,
            cost: formatter.format(isApplyingRewards ? remainingCost : totalCostInDollars),
            currency: ticketInfo?.pricing.currency,
          }),
        },
        // show rewards-only on payment prop for alignment
      }
      : { ...rewardProps, showCard: false },
    rewards: showRewards && showCharge ? rewardProps : undefined,
    edit: {
      ...paymentInfoROBascProps.edit,
      text: {
        ...paymentInfoROBascProps.edit?.text,
        value: t('billingInfo.edit'),
      },
      onClick: () => {
        history.push(
          updateStepInQuery(lastCompleted, history.location.search),
          { location },
        );
      },
    },
  };

  const legalProps: LegalBlockCombinedProps = {
    ...legalBasicProps,
    description: {
      ...legalBasicProps.description,
      value: [
        getTranslation('legal.descriptionBase', openModal),
        t('legal.legalTemplate', {
          chargeDescription: showCharge
            ? t('legal.chargeDescription', {
              charge: formatter.format(isCardOnly ? totalCostInDollars : remainingCost),
              currency: ticketInfo?.pricing.currency,
            })
            : '',
          chargeOnly: showRewards ? '' : t('legal.chargeOnly'),
          chargeAndMiles:
            showCharge && showRewards ? t('legal.chargeAndMiles') : '',
          milesDescription: showRewards
            ? t('legal.milesDescription', {
              value: `${rewardSign}${handleDecimalValuesForDisplay(
                handleAmountFormattingString(
                  round(rewardPointsToBeRedeemed, 2),
                ),
                useDecimals,
              )}`,
              unit: unitName,
              lastFour: account?.loyalty_program.last_four,
            })
            : '',
          dollarDisclaimer: showCharge ? t('legal.dollarDisclaimer') : '',
          salesDisclaimer: t('legal.salesDisclaimer'),
          faceValueText: showFaceValueCheck ? t('legal.faceValueText') : '',
          accessibilityDisclaimerText: t('legal.accessibilityDisclaimerText'),
          // this is done as the i18n still shows these variables in the template
          descriptionBase: '',
          salesInformation: '',
        }),
        getEmailTranslation('legal.salesInformation'),
      ],
    },
    button: {
      ...legalBasicProps.button,
      text: {
        value: t('legal.button'),
      },
      disabled: orderDisabled,
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      onClick: async () => {
        setOrderDisabled(true);
        await defaultButtonAction();
        setOrderDisabled(!orderDisabled);
      },
    },
  };

  // button
  const showTermsAndConditionsCheckbox = shouldShowTermsAndConditionsCheckbox(
    ticketInfo as ListingDetails,
  );
  const code = getCodeFromStep(validatedStep || 1, hardTicket === true);
  const mobileButton: ButtonCombinedProps = {
    disabled: showTermsAndConditionsCheckbox && code === CheckoutSteps.CONFIRMATION ? !hasAcceptedTerms : undefined,
    text: {
      value: t('customerInfo.button'),
    },
    onClick: (e) => {
      // to prevent form submit on shipping info page/step load
      if (e && code === CheckoutSteps.BILLINGINFO) {
        e.preventDefault();
      }
      if (
        validatedStep &&
        validatedStep !== totalSteps - 1 &&
        code !== CheckoutSteps.SHIPPINGINFO
      )
        void defaultButtonAction();
    },
  };

  if (code === CheckoutSteps.PAYMENTINFO && mobileButton.text) {
    mobileButton.text.value = t('cardInfo.button');
    mobileButton.style = 'Contained';
  } else if (code === CheckoutSteps.CONFIRMATION && mobileButton.text) {
    mobileButton.text.value = t('legal.button');
    mobileButton.style = 'ContainedGreen';
  }

  return {
    ...props,
    cardInfoBlock: {
      preSubmitValidation: () => {
        // no validation for card only payment
        if (isCardOnly) {
          setShowLoayltyBlockError(false);
          return true;
        }

        // show error message for no option selected
        if (!radioItemSelectionState) {
          setLoyaltyBlockErrorMessage(t('loyaltyBlock.errorMessages.noOptionSelected'));
          setShowLoayltyBlockError(true);
          return false;
        }

        // show error message for stating that edit mode is still on
        if (isEditingRewardPoints) {
          setLoyaltyBlockErrorMessage(t('loyaltyBlock.errorMessages.editModeOnError'));
          setShowLoayltyBlockError(true);
          return false;
        }

        setShowLoayltyBlockError(false);
        return true;
      },
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      onCardTokenized: async (payload, hasValidationError) => {
        // to show error when no option is selected rewardPoints
        if ((isSplitPay || isRewardsOnly) && !radioItemSelectionState) {
          setLoyaltyBlockErrorMessage(t('loyaltyBlock.errorMessages.noOptionSelected'));
          setShowLoayltyBlockError(true);
          return;
        }
        if (!account || !ticketInfo || hasValidationError) {
          return;
        }
        setCardDetails({
          payload,
          ioBlackBox: ioBlackBoxRef?.current?.value || null,
        });
        await defaultButtonAction();
      },
      isCardDisabled: cardDisabled,
    },
    customerInfoBlock: customerInfoProps,
    customerInfoReadOnly: customerInfoROProps,
    billingInfoBlock: billingInfoProps,
    paymentInfoReadOnly: paymentInfoROProps,
    loyaltyBlock: loyaltyProps,
    legalBlock: legalProps,
    stepperBlock: stepperBlockProps,
    orderInfo: orderInfoProps,
    shippingInfoBlock: {
      phoneNumber: {
        textInput: {
          textValue: phoneNumberFieldValue,
        },
      },
    },
    step: validatedStep,
    isRewardsOnly,
    showRewards:
      isSplitPay && (account?.loyalty_program.number_of_units || 0) > 0,
    button: mobileButton,
    ticketAlertModalProps: ticketAlertModalProps,
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    onFormSubmit: async () => {
      if (ioBlackBoxRef?.current) {
        setCardDetails({
          payload: undefined,
          ioBlackBox: ioBlackBoxRef?.current?.value,
        });
      }
      await defaultButtonAction();
    },
    rewardsCost: {
      currentSelection: rewardPointsToBeRedeemed,
      dollarWorth: dollarValue,
      loaded: true,
      unit: unitName,
    },
    orderErrorBlock: orderErrorBlockBasicProps,
    error: error,
    currentForm: getFormFromCode(
      code,
      isRewardsOnly,
    ),
    hardTicket: hardTicket,
    ioBlackBoxRef: ioBlackBoxRef,
    isLoading: isLoading || orderLoading,
    showTermsAndConditionsCheckbox,
    hasAcceptedTerms,
    checkBoxItem: {
      text: {
        size: 'Medium',
        value: t('orderConfirmation.agreeToTermsAndConditions'),
      },
      state: hasAcceptedTerms ? 'Selected' : 'Unselected',
      onCheckBoxItemClicked: () => {
        setHasAcceptedTerms(!hasAcceptedTerms);
        setOrderDisabled(hasAcceptedTerms);
      },
      icon: {
        className: cx({
          [styles.phoneNumberPrivacyErrorCheckbox]:
            !phoneNumberCheckboxValidation,
        }),
      },
    },
  };
};

export default usePresenter;
