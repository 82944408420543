import React, { useContext, useEffect, useState } from 'react';
import { ListingMutation, TicketSelectionPageCombinedProps } from './types';
import { AuthContext } from '../../../modules/auth';
import { ListingsContext } from '../../../modules/listings';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { TopNavContext } from '../../../modules/topNav';
import { ListingDetails } from '../../../modules/partnership';
import { C1_BROKER_ID, CP1_EXCLUSIVE_TAG } from '../../../lib/constants';
import { RewardUnits, addQueryParam, checkSoldOut, getQueryParams, handleAmountFormattingString, handleDateFormat, handleDecimalValuesForDisplay, handleDisplayUnitName, handleLocationShort, handleTimeFormat, shouldShowSplitPaymentPrice } from '../../../lib/util';
import useInteractor from './TicketSelectionPage.interactor';
import { TicketInformationCombinedProps } from '../../molecules/TicketInformation/types';
import styles from './TicketSelectionPage.module.scss';
import SoldOutIcon from '../../../resources/images/Clock.png';

const usePresenter = (props: TicketSelectionPageCombinedProps): TicketSelectionPageCombinedProps => {
  const { account } = useContext(AuthContext);
  const { selectors: { composedListings: listings } } = useContext(ListingsContext);
  const { hasNoEventMap } = useContext(TopNavContext);
  const { event, fetchListing, isLoading, apiError } = useInteractor(props);
  const { t } = useTranslation();
  const history = useHistory();
  const isExclusiveEvent = event?.tags?.includes(CP1_EXCLUSIVE_TAG);
  const { search, pathname } = useLocation();
  const { eventId } = useParams<{ eventId: string }>();
  const [listing, setListing] = useState<ListingDetails>();
  const [apiParams, setApiParams] = useState<ListingMutation>({
    event_id: '',
    ticket_id: '',
    params: {
      quantity: 0,
    },
  });

  useEffect(() => {
    const queryParams = { ...getQueryParams(search) };
    if (Object.keys(queryParams).length !== 0) {
      setApiParams({
        event_id: eventId || '',
        ticket_id: queryParams.ticket_id || '',
        params: {
          quantity: Number(queryParams.quantity) || 0,
          exclusive_listings: queryParams.exclusive_listings === 'true',
        },
      });
    }
  }, [search, eventId]);

  useEffect(() => {
    const doFetchListing = async (queryParams: ListingMutation) => {
      try {
        if (queryParams?.ticket_id) {
          const response = await fetchListing(queryParams);
          setListing(response);
        }

      } catch {
        setListing(undefined);
      }
    };
    void doFetchListing(apiParams);
  }, [apiParams, fetchListing]);
  let eventInfoString;
  let eventDateString;
  let eventTimeString;
  let eventLocationString;
  if (event) {
    eventDateString = handleDateFormat(event.local_date);
    eventTimeString = `at ${handleTimeFormat(event.local_date)}`;
    eventLocationString = handleLocationShort(event.venue);
    eventInfoString = `${eventDateString} ${eventTimeString} - ${event.venue.name}, ${eventLocationString}`;
  }

  const ticketInformations: TicketInformationCombinedProps[] = [];
  if (event && listings?.length) {
    const showSplitPaymentPrice = shouldShowSplitPaymentPrice(event);
    const displayUnitName = handleDisplayUnitName(account);
    listings.map((singleTicket) => {
      const formattedValue =
        account?.loyalty_program.loyalty_unit_name.toLowerCase() === RewardUnits.MILES.toLowerCase()
          ? handleAmountFormattingString(singleTicket.loyalty_price.price_per) :
          `$${handleDecimalValuesForDisplay(handleAmountFormattingString(singleTicket.loyalty_price.price_per), true)}`;
      let ticketInformation: TicketInformationCombinedProps = {
        type: isExclusiveEvent ? 'Default' : 'MoreDetails',
        style: 'Rounded',
        title: {
          value: singleTicket.verbose_section_name
            || singleTicket.section,
        },
        location: {
          value: t('eventPage.location', {
            row: singleTicket.row,
          }),
        },
        value: {
          value: formattedValue,
        },
        valueType: {
          value: displayUnitName,
        },
        other: {
          value: t('eventPage.each'),
        },
        availability: {
          value: t('eventPage.availability', {
            quantity: singleTicket.quantity,
          }),
        },
        pointsValue: {
        },
        icon: {
          asset: 'ArrowRightFilled',
        },
        onClicked: () => {
          const params = addQueryParam(history.location.search, {
            ticket_id: singleTicket.id,
            quantity: String(singleTicket.quantities[0]),
            exclusive_listings: (singleTicket.broker_id === C1_BROKER_ID).toString(),
          });

          if (params) {
            history.push({
              search: params,
            });
          }
        },
      };
      if (showSplitPaymentPrice) {
        ticketInformation = {
          ...ticketInformation,
          pointsValue: {
            value: formattedValue,
          },
          pointsValueType: {
            value: t('eventPage.unitOr', {
              unit: displayUnitName,
            }),
          },
          value: {
            value: `$${singleTicket.price_per}`,
          },
          valueType: {
            value: t('eventPage.each'),
          },
        };
      }
      ticketInformations.push(ticketInformation);
    });
  }

  const eventDescription = event?.supplemental_data && event.supplemental_data.event_description ? event.supplemental_data.event_description : event?.description;

  const isSoldOut = event && checkSoldOut(event);
  return {
    ...props,
    c1XEventDetailsSliderBlock: {
      event,
    },
    ticketTypeSelectionBlock: {
      type: isSoldOut ? 'Soldout' : listing ? 'Precheckout' : undefined,
      text: {
        value: t('ticketSelectionPage.selectTickets'),
      },
      cXEventInfoHeader: {
        eventTypeTag: {
          label: {
            value: t('ticketSelectionPage.cardholderExclusive').toUpperCase(),
          },
        },
        title: {
          value: event?.name,
        },
        date: {
          value: eventDateString,
        },
        time: {
          value: eventTimeString,
        },
        location: {
          value: eventLocationString,
        },
      },
      ticketList: {
        ticketInformations: ticketInformations,
      },
      description: {
        value: eventDescription && <div dangerouslySetInnerHTML={{
          __html:
          `${eventDescription}`,
        }}></div>,
      },
      ticketAvailabilityCardSoldOut: {
        image: {
          imageSrc: SoldOutIcon,
        },
        label: {
          value: t('ticketSelectionPage.soldOut').toUpperCase(),
        },
        message: {
          value: t('ticketSelectionPage.soldOutMessage'),
        },
        clickableSection: {
          text: {
            value: t('ticketSelectionPage.exploreExclusives'),
          },
          onClick: () => {
            history.push('/exclusive-events');
          },
        },
      },
      precheckout: {
        listing: listing,
        isExclusive: event?.tags?.includes(CP1_EXCLUSIVE_TAG),
        image: {
          imageSrc: '',
          className: styles.hidePreCheckoutImage,
        },
        onBackButtonClick: () => {
          setListing(undefined);
          history.push(pathname);
        },
        topNav: {
          backButton: {
            onClick: () => {
              setListing(undefined);
              history.push(pathname);
            },
          },
        },
      },
    },
    isLoading: isLoading,
    apiError: apiError,
  };
};

export default usePresenter;
