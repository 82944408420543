import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getVenue } from '../../../modules/partnership/api';
import categoryEvents from '../../../modules/navigation/CategoryEvents.json';
import { TopNavTab } from '../../../modules/navigation/types';
import { useMutation } from 'react-query';
import { VenueLandingPageCombinedProps, VenueLandingPagePresenterProps } from './types';

const useInteractor = (props: VenueLandingPageCombinedProps): VenueLandingPagePresenterProps => {

  const { venueId } = useParams<{ venueId: string }>();
  const { mutateAsync, data } = useMutation(getVenue);
  const eventCategory: TopNavTab[] = JSON.parse(JSON.stringify(categoryEvents));
  const allCategory: TopNavTab = {
    id: '',
    name: 'All Events',
    children: [],
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    props.setTopDisclaimer(data?.disclaimer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.setTopDisclaimer, data]);

  return {
    ...props,
    venueId: venueId,
    fetchVenue: mutateAsync,
    venue: data,
    categories: [allCategory, ...eventCategory],
  };

};
export default useInteractor;