import { TicketTypeSelectionBlockProps } from '../../blocks/TicketTypeSelectionBlock';


type TicketSelectionPageInternalProps = {
  ticketTypeSelectionBlock?: TicketTypeSelectionBlockProps;
};

type TicketSelectionPagePropsValues = {
  '': TicketSelectionPageInternalProps;
};

const propValues: TicketSelectionPagePropsValues = {
  '': {
    ticketTypeSelectionBlock: {
    },
  },
};

const getProps = (type: string): TicketSelectionPageInternalProps => {
  const values: TicketSelectionPageInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
