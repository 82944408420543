import React from 'react';
import cx from 'classnames';

import LandingPageBannerBlock from '../../blocks/LandingPageBannerBlock';
import EventListingBlock from '../../blocks/EventListingBlock';

import styles from './PerformerLandingPage.module.scss';
import usePresenter from './PerformerLandingPage.presenter';
import getProps from './PerformerLandingPage.props';
import { PerformerLandingPageCombinedProps } from './types';


const PerformerLandingPage: React.FC<PerformerLandingPageCombinedProps> = (props) => {
  const {
    className,
    classes,
    landingPageBannerBlock,
    performerEvents,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.performerLandingPage;

  return (
    <div className={cx(currentStyle, className)}>
      <div className={cx(styles.container, classes?.container)}>
        <LandingPageBannerBlock
          className={cx(styles.landingPageBannerBlock, classes?.landingPageBannerBlock)}
          {...internalProps.landingPageBannerBlock}
          {...landingPageBannerBlock}/>
        <EventListingBlock
          className={cx(styles.performerEvents, classes?.performerEvents)}
          {...internalProps.performerEvents}
          {...performerEvents}/>
      </div>
    </div>
  );
};

export default PerformerLandingPage;

