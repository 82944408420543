import React from 'react';
import cx from 'classnames';

import Icon from '../../atoms/Icon';
import Text from '../../atoms/Text';
import RecentlyViewedList from '../../organisms/RecentlyViewedList';

import styles from './RecentlyViewedBlock.module.scss';
import usePresenter from './RecentlyViewedBlock.presenter';
import getProps from './RecentlyViewedBlock.props';
import { RecentlyViewedBlockCombinedProps } from './types';


const RecentlyViewedBlock: React.FC<RecentlyViewedBlockCombinedProps> = (props) => {
  const {
    className,
    classes,
    title,
    icon,
    recentlyViewedList,
    // MANUAL OVERRIDE STARTS
    showRecentEventList,
    // MANUAL OVERRIDE ENDS
  } = usePresenter(props);

  if (!showRecentEventList) {
    return null;
  }

  const internalProps = getProps('');
  const currentStyle = styles.recentlyViewedBlock;

  return (
    <div className={cx(currentStyle, className)}>
      {/* MANUAL OVERRIDE -  */}
      <div className={cx(styles.topContent, classes?.topContent)}>
        <Icon
          className={cx(styles.icon, classes?.icon)}
          {...internalProps.icon}
          {...icon}/>
        <Text
          className={cx(styles.title, classes?.title)}
          {...internalProps.title}
          {...title}/>
      </div>
      <RecentlyViewedList
        className={cx(styles.recentlyViewedList, classes?.recentlyViewedList)}
        {...internalProps.recentlyViewedList}
        {...recentlyViewedList}/>
    </div>
  );
};

export default RecentlyViewedBlock;

