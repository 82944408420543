import { TFunction } from 'i18next';
import { useEffect, useState } from 'react';
import { round } from '../legacy/molecules/Slider/utils';
import { Account } from '../modules/auth/types';
import {
  Event,
  EventsQueryParams,
  Listing,
  ListingDetails,
  ListingsQueryParams,
  PerformerDetail,
  Venue,
} from '../modules/partnership/types';
import { ALWAYS_SUPPRESS_VSINVENTORY_TAG, C1_BROKER_ID, C1_NOT_COLLAPSED_TAG, CASH_ONLY_TAG, CP1_EXCLUSIVE_TAG, CP1_NOT_EXCLUSIVE_TAG, GUEST_LIST_EVENT, SOLD_OUT_TAG, SPLIT_PAY_TAG } from './constants';
import { WindowSize, UnitDisplaySettings, ScrollOffset, UseGetEventStatus, EventStatus } from './types';
import { TextColourEnum } from '../components/atoms/Text/types';
import { defaultDateToTodayIfEmpty } from '../legacy/molecules/Filter/utils';

export interface UrlAndQueryType {
  path: string;
  queryParams: string;
}

export interface TicketListingMapping {
  ticket_listing: string;
  ticket_details: string;
  delivery_method: string;
  confimation_page: string[];
  requiresShippingInfo?: string;
}

export interface ImageViewType {
  desktop: string;
  mobile: string;
}
export interface PerformerImage {
  hero: ImageViewType;
  modal: ImageViewType;
  widget: ImageViewType;
  [key: string]: ImageViewType | string[];
}
export type ImageType = 'hero' | 'modal' | 'widget' | 'carousel';
export type ViewPoint = 'desktop' | 'mobile';

export const getTwoDigitsCountryCode = (code: string): string => {
  if (code.toUpperCase() === 'USA') return 'US';
  if (code.toUpperCase() === 'CAN') return 'CA';
  return code;
};

export const handleCountryCodeUS = (countryCode: string): boolean => {
  if (countryCode.toUpperCase() === 'USA' || countryCode.toUpperCase() === 'US') {
    return true;
  }
  return false;
};

export const getFiveDigitsZipCode = (countryCode: string, postalCode: string): string => {
  if (handleCountryCodeUS(countryCode)) {
    return postalCode.substring(0, 5);
  }
  return postalCode;
};

export const getQueryParams = (url: string): Record<string, string> => {
  const results: Record<string, string> = {};
  const queryParams = url.split('?');
  if (queryParams.length > 1) {
    const params = queryParams[1].split('&');

    for (let i = 0, len = params.length; i < len; i += 1) {
      const parts = params[i].split('=');
      if (parts.length > 1) {
        const [key, value] = parts;
        results[key] = value;
      }
    }
  }
  return results;
};

export const removeIgnoredParams = (params: Record<string, string>, ignoredParams: string[]): Record<string, string> => {
  ignoredParams.forEach(param => {
    params[param] = '';
  });

  return params;
};

export const mapQueryString = (payload: Record<string, unknown>): string =>
  Object.entries(payload)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');

export const addQueryParam = (
  url: string,
  payload: Record<string, string>,
): string => {
  let queryParams = getQueryParams(url);
  queryParams = {
    ...queryParams,
    ...payload,
  };
  const queryString = mapQueryString(queryParams);
  const baseUrl = url.split('?')[0];
  return `${baseUrl}?${queryString}`;
};

export type DatePartsType = {
  [key in Intl.DateTimeFormatPartTypes]?: string;
};

export const formatDateToMonthDay = (date: Date): string => {
  const dateParts: DatePartsType = new Intl.DateTimeFormat('en-US', {
    day: 'numeric',
    month: 'short',
  })
    .formatToParts(new Date(date))
    .reduce((acc, part) => {
      acc[part.type] = part.value;
      return acc;
    }, {});
  return `${dateParts.month} ${dateParts.day}`;
};

export const formatDateToApiDate = (date: Date): string => {
  const dateParts: DatePartsType = new Intl.DateTimeFormat('en-US', {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
  })
    .formatToParts(new Date(date))
    .reduce((acc, part) => {
      acc[part.type] = part.value;
      return acc;
    }, {});
  return `${dateParts.year}-${dateParts.month}-${dateParts.day}`;
};

export const getLocalDate = (time: string): string => {
  const localDate = new Date(time).toLocaleDateString('en-us', {
    weekday: 'short',
    month: 'short',
    day: 'numeric',
  });
  return localDate;
};

export const getLocalTime = (time: string): string => {
  const localTime = new Date(time).toLocaleTimeString('en-us', {
    hour: 'numeric',
    minute: '2-digit',
    hour12: true,
  });
  return localTime;
};

export enum DateFormats {
  // example: Fri, Jul 19 at 7:30pm
  TICKETINFO = 'ticketInfoFormat',
  // example: Jul 18, 2020
  CONFIRMATION = 'confirmationFormat',
}

/**
 * Formats a date to one of the following formats:
 * TICKETINFO:
 * Fri, Jul 19 at 7:30pm
 * CONFIRMATION:
 * Jul 18, 2020
 */
export function formatDate(
  dateAndTime: Date,
  t: TFunction,
  format: DateFormats,
): string {
  const dateParts: DatePartsType = new Intl.DateTimeFormat('en-US', {
    weekday: 'short',
    day: 'numeric',
    month: 'short',
    year: 'numeric',
    hour: 'numeric',
    hour12: true,
    minute: '2-digit',
  })
    .formatToParts(dateAndTime)
    .reduce((acc, part) => {
      acc[part.type] = part.value;
      return acc;
    }, {});
  return t(`ticketInfo.dateTemplate.${format}`, {
    day_week: dateParts.weekday,
    month: dateParts.month,
    day: dateParts.day,
    hour: dateParts.hour,
    minute: dateParts.minute,
    period: dateParts.dayPeriod?.toLocaleLowerCase(),
    year: dateParts.year,
  });
}

export const isValidUrl = (input: string): boolean => {
  let url;
  try {
    url = new URL(input);
  } catch (e) {
    return false;
  }
  return url.protocol === 'http:' || url.protocol === 'https:';
};

export const handleDateFormat = (date: Date): string => {
  const dateParts: DatePartsType = new Intl.DateTimeFormat('en-US', {
    weekday: 'short',
    month: 'short',
    day: 'numeric',
  })
    .formatToParts(new Date(date))
    .reduce((acc, part) => {
      acc[part.type] = part.value;
      return acc;
    }, {});
  return `${dateParts.weekday}, ${dateParts.month} ${dateParts.day}`;
};

export const handleTimeFormat = (date: Date): string => {
  return new Intl.DateTimeFormat('en-US', {
    hour: 'numeric',
    minute: 'numeric',
  }).format(new Date(date));
};

export const setEventDescription = (event: Event, mobile = false): string => {
  const eventDate = handleDateFormat(event.local_date);
  const eventTime = handleTimeFormat(event.local_date);

  if (mobile) {
    return ` ${eventDate} at ${eventTime} \n ${event.venue.name}\n ${event.venue.city} ${event.venue.state_code}`;
  }
  return ` ${eventDate} at ${eventTime} - ${event.venue.name}, ${event.venue.city} ${event.venue.state_code}`;
};

export const getWeekday = (date: Date): string => {
  return new Intl.DateTimeFormat('en-US', {
    weekday: 'short',
  }).format(new Date(date));
};

export const getWeekdayAndTime = (date: Date): string => {
  return `${getWeekday(date)} ${getLocalTime(date.toString())}`;
};

export const getYear = (date: Date): string => {
  return String(new Date(date).getFullYear());
};

export enum CheckoutSteps {
  CUSTOMERINFO = 'customerInfo',
  BILLINGINFO = 'billingInfo',
  SHIPPINGINFO = 'shippingInfo',
  PAYMENTINFO = 'paymentInfo',
  CONFIRMATION = 'confirmation',
}

export const handleLoyaltyUnitTags = (loyaltyUnitName: string): string => {
  let loyaltyTag = '';
  switch (loyaltyUnitName.toLowerCase().replace(/ /g, '_')) {
    case 'miles':
      loyaltyTag = 'C1_MILES';
      break;
    case 'cash':
    case 'cash_rewards':
    case 'rewards_cash':
      loyaltyTag = 'C1_CASH_REWARDS';
      break;

    default:
      break;
  }
  return loyaltyTag;
};

export const transformProgramType = (programType = ''): string => {
  return programType.trim().toUpperCase().replace(/\s/g, '_');
};

const getProgramType = (account?: Account): string => {
  if (account?.loyalty_program?.program_type_tag) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return account.loyalty_program.program_type_tag;
  }
  return transformProgramType(account?.loyalty_program?.program_type);
};


export const getNetworkTagType = (account?: Account): string => {
  if (account?.loyalty_program?.processing_network) {
    return account.loyalty_program.processing_network.toUpperCase();
  }
  return '';
};
export const capitalize = (title: string): string => {
  return title.charAt(0).toUpperCase() + title.slice(1).toLowerCase();
};

export const handleTagFilter = (
  params: EventsQueryParams,
  account: Account,
  includeSecondary?: boolean,
  isC1XLandingPage?: boolean,
): EventsQueryParams => {
  let updatedParams: EventsQueryParams = { ...params };
  const loyaltyTag = handleLoyaltyUnitTags(
    account.loyalty_program.loyalty_unit_name,
  );
  let shouldCategoryAndRegionChange;
  // Adding default tags in tag_filter
  const tags: string[] = [
    CP1_EXCLUSIVE_TAG,
    loyaltyTag,
    getProgramType(account),
    getNetworkTagType(account),
  ];
  for (const param in params) {
    switch (params[param]) {
      case 'exclusive':
        shouldCategoryAndRegionChange = true;
        break;
      case 'C1_Dining':
        shouldCategoryAndRegionChange = true;
        tags.push('DINING');
        break;
      case 'C1_Music':
        shouldCategoryAndRegionChange = true;
        tags.push('MUSIC');
        break;
      case 'C1_Sports':
        shouldCategoryAndRegionChange = true;
        tags.push('SPORTS');
        if (isC1XLandingPage) {
          tags.push(C1_NOT_COLLAPSED_TAG);
        }
        break;
      case 'C1_TRAVEL':
        shouldCategoryAndRegionChange = true;
        tags.push('C1_TRAVEL');
        break;
      case 'C1_ARTSCULTURE':
        shouldCategoryAndRegionChange = true;
        tags.push('C1_ARTSCULTURE');
        break;
      case 'C1_PROMOTED_PRODUCTION':
        shouldCategoryAndRegionChange = true;
        tags.push('PROMOTED_PRODUCTION');
        break;
      default:
        break;
    }
  }

  updatedParams = {
    ...params,
    tag_filter: tags.join(' AND '),
  };

  if (shouldCategoryAndRegionChange) {
    updatedParams = {
      ...updatedParams,
      category: '',
    };
  }

  if (includeSecondary) {
    updatedParams = {
      ...updatedParams,
      secondary_tag_filter: CP1_NOT_EXCLUSIVE_TAG,
    };
  }
  if (!updatedParams.date_start) {
    updatedParams.date_start = defaultDateToTodayIfEmpty(
      updatedParams.date_start,
    );
  }
  return updatedParams;
};

export const handleTagFilterForListings = (
  params: ListingsQueryParams,
  account: Account,
): ListingsQueryParams => {
  const programType = getProgramType(account);
  const tags: string[] = [programType];
  return {
    ...params,
    tag_filter: tags.join(' AND '),
  };
};

export const handleTagFilterForSearch = (account?: Account): string => {
  const loyaltyTag = handleLoyaltyUnitTags(
    account?.loyalty_program?.loyalty_unit_name || '',
  );
  const programType = getProgramType(account);
  const networkTag = getNetworkTagType(account);
  const tags: string[] = [loyaltyTag, programType, networkTag];
  return tags.join(' AND ');
};

export enum RewardUnits {
  MILES = 'Miles',
  CASH_REWARDS = 'Rewards Cash',
  CASH = 'Cash',
}

export const isValidPhoneNumber = (value: string): boolean => {
  return /^\d{10}$/.test(value);
};

export const removeWhiteSpacesFromString = (str: string): string => {
  return str.replace(/\s/g, '');
};

export const seprateUrlAndQuery = (url: string): UrlAndQueryType => {
  const urlString = url.split('?');
  return {
    queryParams: urlString.length > 1 ? urlString[1] : '',
    path: urlString[0],
  };
};

export const handleCarouselImages = (event: Event, type: 'carousel'): string | string[] => {
  if (
    event.supplemental_data?.performer_images?.length && 
    event.supplemental_data.performer_images[0].url
  ) {
    try {
      const images: PerformerImage = JSON.parse(event.supplemental_data.performer_images[0].url);
      if (images) {
        const imageUrls = images[`${event.id}`] as string[];
        if (!imageUrls) {
          throw new Error('No carousel images found. Return fallback image');
        }
        return images[`${event.id}`] as string[]; 
      }
    } catch (e) {
      return event.image;
    }
  }
  return event.image;
};

export const handleVariableImage = (event: Event, type: ImageType, viewPoint: ViewPoint): string => {
  if (
    event.supplemental_data?.performer_images?.length && 
    event.supplemental_data.performer_images[0].url
  ) {
    try {
      const images: PerformerImage = JSON.parse(event.supplemental_data.performer_images[0].url);
      if (images && type !== 'carousel') {
        const imageType = images[`${type}`];
        return imageType[`${viewPoint}`];
      }
    } catch (e) {
      return event.supplemental_data.performer_images[0].url;
    }
    return '';
  }
  return event.image;
};

export const handleDecimalValuesForDisplay = (
  value: string,
  useDecimals: boolean,
): string => {
  if (useDecimals && !value.includes('.')) {
    return value + '.00';
  } else if (useDecimals) {
    const splittedValue = value.split('.');
    return splittedValue[1].length === 1 ? value + '0' : value;
  }
  return value;
};

export const handleDecimalValuesForDisplayReturnFreeIfZero = (value: string, useDecimals: boolean, t: TFunction, displayFree?: boolean): string => {
  const retVal = handleDecimalValuesForDisplay(value, useDecimals);
  if (displayFree && retVal === '$0.00') {
    return t('breakdown.deliveryFeeFree');
  }
  return retVal;
};

export const handleAmountFormattingString = (value: number | string | undefined): string => {
  return Number(value).toLocaleString();
};

/**
 * To remove commas from formattedNumber
 * @param formattedNumber
 * @returns number string without commas
 */
export const handleFormattedNumberToNumberString = (formattedNumber: string): string => {
  // removed commas from string
  return formattedNumber.replace(/\D/g, '');
};

export const handleDisplayUnitName = (account: Account | undefined): string => {
  let displayName = '';
  if (account && account?.loyalty_program?.unit_display_name) {
    displayName = account.loyalty_program.unit_display_name;
  } else {
    displayName = account?.loyalty_program?.loyalty_unit_name
      ? account.loyalty_program.loyalty_unit_name
      : '';
  }
  return displayName;
};

export const getFaceValue = (listing: Listing | undefined, t: TFunction): string => {
  if (listing && listing?.face_value && listing.face_value > 0) {
    return t('ticketInfo.orderTotal.faceValuePresent', { amount: String(listing?.face_value) });
  }
  return t('ticketInfo.orderTotal.faceValueAbsent');
};

export const isEventGuestList = (tags: string[], stockType: string, deliveryId: number) => {
  return (tags.includes(GUEST_LIST_EVENT)
    && stockType.toUpperCase() === 'HARD'
    && deliveryId === 8);
};

export const getTicketListingTranslationKey = (
  listing: ListingDetails,
  fieldName: string,
): string => {
  if (
    isEventGuestList(
      listing.event.tags || [],
      listing.listing.stock_type.value,
      listing.pricing.delivery.id,
    )
  ) {
    return 'precheckoutBlock.guestList';
  }
  let deliveryMethod = '';
  for (let i = 0; i < listing.delivery_options.length; i++) {
    if (listing.delivery_options[i].id === listing.pricing.delivery.id) {
      deliveryMethod = listing.delivery_options[i].id.toString();
    }
  }
  return `deliveryInformation.${listing.listing.stock_type.value.toUpperCase()}.${deliveryMethod}.${fieldName}`;
};

export const getOrderDetailsTranslationKey = (
  stockType: string,
  deliveryMethod: string,
  fieldName: string,
): string => {
  return `deliveryInformation.${
    stockType && stockType.toUpperCase()
  }.${deliveryMethod}.${fieldName}`;
};

export const getUnitDisplaySettings = (
  loyaltyUnitName: string | undefined,
): UnitDisplaySettings => {
  const rewardSign =
    loyaltyUnitName?.toLowerCase() !== RewardUnits.MILES.toLowerCase() ? '$' : '';
  const useDecimals = loyaltyUnitName?.toLowerCase() !== RewardUnits.MILES.toLowerCase();
  return { rewardSign, useDecimals };
};
export const setUpdatedPhoneNumber = (phoneNumber: string): void => {
  window.sessionStorage.setItem('updatedPhoneNumber', phoneNumber);
};

export const getUpdatedPhoneNumber = (): string => {
  return window.sessionStorage.getItem('updatedPhoneNumber') || '';
};

export const handleOrderTotalMiles = (
  amount: number,
  loyaltyUnitName?: string,
): number => {
  if (loyaltyUnitName) {
    return loyaltyUnitName.toLowerCase() === RewardUnits.MILES.toLowerCase()
      ? round(amount)
      : amount;
  }
  return amount;
};

export const isValidDate = (date: Date = new Date()): boolean => {
  return isNaN(date.getTime()) ? false : true;
};

export const handleLocation = (venue: Venue): string => {
  const location: string[] = [];
  if (venue.name) {
    location.push(venue.name);
  }
  if (venue.city) {
    location.push(venue.city);
  }
  if (venue.state_code) {
    location.push(venue.state_code);
  }
  return location.join(', ');
};
export const handleLocationShort = (venue: Venue): string => {
  const location: string[] = [];
  if (venue.name) {
    location.push(venue.name);
  }
  if (venue.city) {
    location.push(venue.city);
  }
  if (venue.state_code) {
    location.push(venue.state_code);
  }
  return location.join(', ');
};

export function shouldShowSplitPaymentPrice(event: Event): boolean {
  const isSplitPaymentEvent = event.tags?.includes(SPLIT_PAY_TAG);
  const isCreditCardPaymentEvent = event.tags?.includes(CASH_ONLY_TAG);

  return Boolean(isCreditCardPaymentEvent || isSplitPaymentEvent);
}

export const checkSoldOut = (event: Event, checkExclusive = false): boolean => {
  let soldOut = false;
  if (event.tags) {
    let isSoldOut = event.tags.includes(SOLD_OUT_TAG) && event.listing_count === 0;
    // in some places we need to check for exclusive events
    if (checkExclusive) {
      isSoldOut = isSoldOut && event.tags.includes(CP1_EXCLUSIVE_TAG);
    }
    const isSuppressInventorySoldOut = event.tags.includes(ALWAYS_SUPPRESS_VSINVENTORY_TAG) &&
      event.tags.includes(SOLD_OUT_TAG) && event.exclusive_listing_count === 0;
    soldOut = isSoldOut || isSuppressInventorySoldOut;
  }

  return soldOut;
};

export const useProgressiveImage = (src) => {
  const [sourceLoaded, setSourceLoaded] = useState(null);
  useEffect(() => {
    const img = new Image();
    img.src = src;
    setSourceLoaded(null);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    img.onload = () => setSourceLoaded(src);
  }, [src]);

  return sourceLoaded;
};

export const useProgressiveFonts = (): boolean => {
  const [webFontsLoaded, setWebFontsLoaded] = useState(false);
  useEffect(() => {
    const areFontsReady = async () => {
      await document.fonts.ready;
      setWebFontsLoaded(true);
    };
    void areFontsReady();
  });

  return webFontsLoaded;
};

export const shouldShowTermsAndConditionsCheckbox = (ticket: ListingDetails): boolean => {
  // price breakdown is returned only when feature flag
  // is enabled, no other mechanism exists to check for
  // feature flags in this repo, at this point
  const hasCp1BrokerId: boolean = ticket?.listing.broker_id === C1_BROKER_ID;
  const isCp1Exclusive: boolean | undefined = ticket?.event.tags?.includes(CP1_EXCLUSIVE_TAG) && hasCp1BrokerId;
  const isNY = ticket?.event?.venue?.state_code === 'NY';
  const hasPriceBreakdown = !!ticket?.listing?.price_breakdown;
  
  return isNY && hasPriceBreakdown && !isCp1Exclusive;
};

export const useWindowSize = (): WindowSize => {
  const [windowSize, setWindowSize] = useState<WindowSize>({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);

    // Call handler to initialize values
    handleResize();

    // Remove listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return windowSize;
};

export const useScrollOffset = (): ScrollOffset => {
  const { scrollX, scrollY } = window;
  const [scrollOffset, setScrollOffset] = useState<ScrollOffset>({
    scrollX, scrollY,
  });
  useEffect(() => {
    const onScroll = () => {
      setScrollOffset({
        scrollX: window.scrollX,
        scrollY: window.scrollY,
      });
    };
    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  return scrollOffset;
};

export const getEventStatus = (event: Event, t: TFunction, checkExclusive = false): EventStatus => {
  let statusColor: TextColourEnum | undefined = undefined;
  let status = undefined;
  
  if (checkSoldOut(event, checkExclusive)) {
    status = t('eventCard.soldOut');
    statusColor = 'Negative';
  } else if (event.listing_count < 5) {
    status = t('eventCard.lowTickets');
    statusColor = 'Warning';
  }  
  return { 
    statusLabel: status,
    statusColor,
  };
};

export const handleEventCardDateFormat = (date: Date): string => {
  const dateParts: DatePartsType = new Intl.DateTimeFormat('en-US', {
    weekday: 'short',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    year: 'numeric',
    minute: 'numeric',
  })
    .formatToParts(new Date(date))
    .reduce((acc, part) => {
      acc[part.type] = part.value;
      return acc;
    }, {});
  return `${dateParts.weekday}, ${dateParts.month} ${dateParts.day}, ${dateParts.year} at ${dateParts.hour}:${dateParts.minute}${dateParts.dayPeriod?.toLowerCase()}`;
};

export const removeHtmlTags = (value?: string) => {
  const htmlTagRegex = /<[^>]*>/g;
  return value ? value.replace(htmlTagRegex, '') : '';
};

export const isVividEvent = (listing?: ListingDetails): boolean => {
  return !(listing?.listing?.broker_id === C1_BROKER_ID) || false;
};

export const isNYEvent = (listing?: ListingDetails): boolean => {
  return listing?.event?.venue?.state_code === 'NY';
};

export const handlePerformerImage = (
  type: ImageType,
  viewPoint: ViewPoint,
  performer?: PerformerDetail,
): string => {
  if (!performer) {
    return '';
  }
  const performerImageDefault: string = performer.image || '';
  if (!performer.supplemental_image) {
    return performerImageDefault;
  }

  try {
    const images: PerformerImage = JSON.parse(performer.supplemental_image.url);
    if (images && viewPoint) {
      const imageType: ImageViewType | string[] = images[`${type}`];
      if (Array.isArray(imageType)) {
        return imageType[0];
      }
      return imageType[`${viewPoint}`];
    }
  } catch (e) {
    console.error('unable to get supplemental image');
  }
  return performerImageDefault;
};

export const displayMaintenanceWindow = (): boolean => {
  const now = new Date();
  const year = now.getUTCFullYear();
  const month = now.getUTCMonth();
  const day = now.getUTCDate();
  const hours = now.getUTCHours();
  const minutes = now.getUTCMinutes();
  const seconds = now.getUTCSeconds();
  const milliseconds = now.getUTCMilliseconds();
  const nowUtc = new Date(Date.UTC(year, month, day, hours, minutes, seconds, milliseconds));
  const maintenanceStart = new Date(Date.UTC(2023, 5, 25, 4, 0));  // June 25th, 00:00 ET
  const maintenanceEnd = new Date(Date.UTC(2023, 5, 25, 8, 0));    // June 25th, 04:00 ET
  return nowUtc >= maintenanceStart && nowUtc <= maintenanceEnd;
};

const getUserAgenet = (): string => {
  return navigator.userAgent.toLowerCase();
};

export const isAndroidDevice = (): boolean => {
  const userAgent = getUserAgenet();
  return userAgent.indexOf('android') > -1;
};

export const isSafariBrowser = (): boolean => {
  const userAgent = getUserAgenet();
  return /^((?!chrome|android).)*safari/i.test(userAgent);
};

export const isFirefoxBrowser = (): boolean => {
  const userAgent = getUserAgenet();
  return /firefox/i.test(userAgent);
};
