import React from 'react';
import cx from 'classnames';

import TextItem from '../TextItem';

import styles from './SeatInfo.module.scss';
import usePresenter from './SeatInfo.presenter';
import getProps from './SeatInfo.props';
import { SeatInfoCombinedProps } from './types';


const SeatInfo: React.FC<SeatInfoCombinedProps> = (props) => {
  const {
    className,
    classes,
    section,
    row,
    quantity,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.seatInfo;

  return (
    <div className={cx(currentStyle, className)}>
      <TextItem
        className={cx(styles.section, classes?.section)}
        {...internalProps.section}
        {...section}/>
      <TextItem
        className={cx(styles.row, classes?.row)}
        {...internalProps.row}
        {...row}/>
      <TextItem
        className={cx(styles.quantity, classes?.quantity)}
        {...internalProps.quantity}
        {...quantity}/>
    </div>
  );
};

export default SeatInfo;

