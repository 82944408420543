import { useTranslation } from 'react-i18next';
import useInteractor from './VenueLandingPage.interactor';
import { VenueLandingPageCombinedProps } from './types';
import { useEffect } from 'react';
import { CONTENT_ID } from '../../../lib/constants';

const usePresenter = (props: VenueLandingPageCombinedProps): VenueLandingPageCombinedProps => {
  const { venue, venueId, categories, fetchVenue } = useInteractor(props);
  const { t } = useTranslation();
  useEffect(() => {
    if (fetchVenue && venueId) {
      void fetchVenue(venueId);
    }
  }, [venueId, fetchVenue]);
  return {
    ...props,
    imageBlock: {
      performerPageBanner: {
        imageSrc: venue?.image,
        title: {
          value: venue?.name,
          id: CONTENT_ID,
        },
      },
    },
    filtersBlock: {
      filterOne: {
        type: 'CategoryFilter',
        optionFilterList: categories,
        defaultOption: categories ? categories[0] : undefined,
      },
      filterTwo: {
        defaultDateOption: {
          title: t('dateFilters.any'),
          type: 'Default',
        },
        type: 'DateFilter',
      },
    },
    eventCardsBlock: {
      defaultParams: {
        venue_id: Number(venueId),
      },
    },
  };
};

export default usePresenter;
