import { useTranslation } from 'react-i18next';
import { SportTeamsLandingPageCombinedProps } from './types';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { dateFilterOptions, dynamicRegionList, getLocation, locationFilterDefaultOptions } from '../../molecules/Filter/utils';
import { getQueryParams } from '../../../lib/util';
import { DropdownOption } from '../../molecules/Filter/types';
import { TopNavTab } from '../../../modules/navigation/types';
import useInteractor from './SportTeamsLandingPage.interactor';

const usePresenter = (props: SportTeamsLandingPageCombinedProps): SportTeamsLandingPageCombinedProps => {
  const { team, performerId, opponents, currentRegion, locationList } = useInteractor(props);
  const { t } = useTranslation();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const regionId = query.get('region_id');
  const [locationFilters, setLocationFilter] = useState<DropdownOption[]>([]);
  const [opponentId, setOpponentId] = useState<number>(-1);
  const cardsPerPage = 5;
  const defaultLocation = getLocation(locationList, regionId);

  useEffect(() => {
    setLocationFilter(locationFilterDefaultOptions(currentRegion));
  }, [currentRegion]);

  useEffect(() => {
    const params = getQueryParams(search);
    setOpponentId(params.opponent_id ? Number(params.opponent_id) : -1);
  }, [search]);

  const handleRegionFilter = (value: string) => {
    if (value.length >= 3) {
      if (locationList) {
        const options: DropdownOption[] = [
          ...locationFilterDefaultOptions(currentRegion),
          ...dynamicRegionList(locationList, value),
        ];
        setLocationFilter(options);
      }
    } else {
      const options: DropdownOption[] = [
        ...locationFilterDefaultOptions(currentRegion),
      ];
      setLocationFilter(options);
    }
  };

  const tabStates: TopNavTab[] = ['all', 'HOME', 'AWAY'].map(
    (tab): TopNavTab => {
      return {
        name: t(`sportsTeamPage.${tab}`),
        children: [],
        id: tab !== 'all' ? tab : '',
        role: 'tab',
      };
    },
  );

  const opponentOptions: DropdownOption[] =
    opponents?.items.map((item): DropdownOption => {
      return {
        title: item.name,
        type: 'SelectValue',
        value: {
          value: String(item.id),
        },
      };
    }) || [];

  opponentOptions.unshift({
    title: t('sportsTeamPage.allOpponents'),
    type: 'SelectValue',
    value: {},
  });

  return {
    ...props,
    landingPageBannerBlock: {
      landingPageBanner: {
        image: {
          imageSrc: team?.image,
        },
        text: {
          value: team?.name,
        },
      },
    },
    // shows nearby events
    sportTeamsOpponent: {
      cardType: 'NoThumbnailImage',
      defaultParams: {
        performer_id: performerId,
        opponent_id: opponentId,
      },
      cardsPerPage: cardsPerPage,
      filterGroup: {
        categoryName: 'home_or_away',
        categories: tabStates,
        titleValue: t('shopByWidget.shopBy'),
        filters: [
          {
            type: 'IconFilter',
            filterType: 'DateFilter',
            options: dateFilterOptions,
            filterIcon: 'Calendar',
          },
          {
            type: 'IconTextFilter',
            filterType: 'KeyValueFilter',
            filterIcon: 'VS',
            filterName: 'opponent_id',
            options: opponentOptions,
          },
        ],
        otherFilterOptions: {
          type: 'TextIconFilter',
          filterType: 'SearchLocationFilter',
          filterTitle: t('shopByWidget.near'),
          filterName: 'region_id',
          defaultSelectedOption: defaultLocation,
          options: locationFilters,
          onTextChanged: handleRegionFilter,
        },
      },
      emptyStateBanner: {
        type: 'NoButton',
        title: {
          value: t('sportsTeamPage.noGamesNearby'),
        },
      },
    },
    sportTeamsSchedule: {
      cardType: 'NoThumbnailImage',
      cardsPerPage: cardsPerPage,
      defaultParams: { performer_id: performerId },
      ignoreParams: ['region_id'],
      text: {
        type: 'Subheading',
        size: 'Medium',
        style: 'Regular',
        colour: 'SubduedDark',
        align: 'Left',
        value: t('sportsTeamPage.schedule'),
      },
      showTitleMobile: true,
      filterGroup: undefined,
      emptyStateBanner: {
        type: 'NoButton',
        title: {
          value: t('search.noEventsTitle'),
        },
        message: {
          value: t('search.noEventsMessage'),
        },
      },
    },
  };
};

export default usePresenter;
