import React from 'react';
import cx from 'classnames';

import Divider from '../../atoms/Divider';
import Logo from '../../atoms/Logo';
import FooterMenuList from '../../molecules/FooterMenuList';

import styles from './Footer.module.scss';
import usePresenter from './Footer.presenter';
import getProps from './Footer.props';
import { FooterCombinedProps } from './types';


const Footer: React.FC<FooterCombinedProps> = (props) => {
  const {
    className,
    classes,
    logo,
    footerMenuList,
    // MANUAL OVERRIDE STARTS
    divider,
    type,
    // MANUAL OVERRIDE ENDS
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.footer;

  return (
    <div className={cx(currentStyle, className)}>
      {/* MANUAL OVERRIDE */}
      <Divider className={styles.divider} {...internalProps.divider} {...divider} />
      <div className={cx(styles.content, classes?.content)}>
        <Logo
          className={cx(styles.logo, classes?.logo)}
          {...internalProps.logo}
          {...logo}/>
        {type !== 'SignedOut' ? <FooterMenuList
          className={cx(styles.footerMenuList, classes?.footerMenuList)}
          {...internalProps.footerMenuList}
          {...footerMenuList}/> : null}
      </div>
    </div>
  );
};

export default Footer;

