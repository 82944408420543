import { useContext } from 'react';

import categoryEvents from '../../../modules/navigation/CategoryEvents.json';
import { TopNavTab } from '../../../modules/navigation/types';
import { RegionContext } from '../../../modules/region';

import { HomepageCombinedProps, HomepagePresenterProps } from './types';

const useInteractor = (props: HomepageCombinedProps): HomepagePresenterProps => {
  const eventCategory: TopNavTab[] = JSON.parse(JSON.stringify(categoryEvents));
  const { currentRegion, allRegions } = useContext(RegionContext);

  const allCategory: TopNavTab = {
    id: '',
    name: 'All Events',
    children: [],
  };

  return {
    ...props,
    categories: [allCategory, ...eventCategory],
    currentRegion: currentRegion,
    locationList: allRegions,
  };
};

export default useInteractor;