import { InstructionLabelProps } from '../../molecules/InstructionLabel';
import { TicketListProps } from '../TicketList';


type SelectTicketInternalProps = {
  instructionLabel?: InstructionLabelProps;
  ticketList?: TicketListProps;
};

type SelectTicketPropsValues = {
  '': SelectTicketInternalProps;
};

const propValues: SelectTicketPropsValues = {
  '': {
    instructionLabel: {
      size: 'Medium',
      type: 'Default',
      style: 'WhiteBG',
    },
    ticketList: {
    },
  },
};

const getProps = (type: string): SelectTicketInternalProps => {
  const values: SelectTicketInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
