import { ButtonProps } from '../../atoms/Button';
import { CardTitleBannerProps } from '../../molecules/CardTitleBanner';
import { ExclusivesCardListProps } from '../../organisms/ExclusivesCardList';


type ExclusivesEventBlockInternalProps = {
  cardTitleBanner?: CardTitleBannerProps;
  exclusivesCardList?: ExclusivesCardListProps;
  button?: ButtonProps;
};

type ExclusivesEventBlockPropsValues = {
  '': ExclusivesEventBlockInternalProps;
};

const propValues: ExclusivesEventBlockPropsValues = {
  '': {
    cardTitleBanner: {
    },
    exclusivesCardList: {
    },
    button: {
      type: 'Text',
      style: 'Text',
      size: 'Small',
    },
  },
};

const getProps = (type: string): ExclusivesEventBlockInternalProps => {
  const values: ExclusivesEventBlockInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
