import { LandingPageBannerBlockProps } from '../../blocks/LandingPageBannerBlock';
import { EventListingBlockProps } from '../../blocks/EventListingBlock';


type VenueLandingPageInternalProps = {
  landingPageBannerBlock?: LandingPageBannerBlockProps;
  venueEvents?: EventListingBlockProps;
};

type VenueLandingPagePropsValues = {
  '': VenueLandingPageInternalProps;
};

const propValues: VenueLandingPagePropsValues = {
  '': {
    landingPageBannerBlock: {
    },
    venueEvents: {
      state: 'Filled',
    },
  },
};

const getProps = (type: string): VenueLandingPageInternalProps => {
  const values: VenueLandingPageInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
