import cx from 'classnames';
import React, { useState } from 'react';
import { Route, Switch } from 'react-router-dom';

import { CONTENT_ID } from '../../../lib/constants';
import { Logout } from '../../../modules/auth/AuthContext';
import { OptimizelyExperiment } from '../../../modules/optimizely';
import { EXPERIMENTS } from '../../../modules/optimizely/constants';

import TopDisclaimer from '../../../legacy/atoms/TopDisclaimer';
import FooterLegacy from '../../../legacy/organisms/Footer';
import OnBoardingModalLegacy from '../../../legacy/organisms/OnBoardingModal';
import TopNavLegacy from '../../../legacy/organisms/TopNav';
import CheckoutManualPage from '../../../legacy/pages/CheckoutManualPage';
import ErrorPage from '../../../legacy/pages/ErrorPage';
import EventPageLegacy from '../../../legacy/pages/EventPage';
import ExclusivesEventsLandingPageLegacy from '../../../legacy/pages/ExclusivesEventsLandingPage';
import HelpPage from '../../../legacy/pages/HelpPage';
import HomepageLegacy from '../../../legacy/pages/Homepage';
import MaintenancePage from '../../../legacy/pages/MaintenancePage';
import MyTicketsPage from '../../../legacy/pages/MyTicketsPage';
import PerformersPageLegacy from '../../../legacy/pages/PerformersPage';
import ReceiptPage from '../../../legacy/pages/ReceiptPage';
import SearchMatchPage from '../../../legacy/pages/SearchMatchPage';
import SportsLandingPage from '../../pages/SportsLandingPage';
import SportsLandingPageLegacy from '../../../legacy/pages/SportsLandingPage';
import VenueLandingPageLegacy from '../../../legacy/pages/VenueLandingPage';

import TermsAndPrivacyModal from '../../organisms/TermsAndPrivacy';

import Button from '../../atoms/Button';
import Divider from '../../atoms/Divider';
import AlertModal from '../../organisms/AlertModal';
import Footer from '../../organisms/Footer';
import TopNav from '../../organisms/TopNav';
import EventPage from '../../pages/EventPage';
import ExclusivesEventsLandingPage from '../../pages/ExclusivesEventsLandingPage';
import Homepage from '../../pages/Homepage';
import PerformersPage from '../../pages/PerformersPage';
import VenueLandingPage from '../../pages/VenueLandingPage';

import customStyles from './Custom.module.scss';
import styles from './MainLayout.module.scss';
import stylesLegacy from './MainLayoutLegacy.module.scss';
import usePresenter from './MainLayout.presenter';
import getProps from './MainLayout.props';
import { MainLayoutCombinedProps } from './types';


const MainLayout: React.FC<MainLayoutCombinedProps> = (props) => {
  const {
    className,
    classes,
    topNav,
    homepage,
    divider,
    footer,
    // MANUAL OVERRIDE STARTS
    topNavLegacy,
    disableTopNav,
    loading,
    locationMaintenance,
    inMaintenance,
    shouldShowFooter,
    shouldShowUpdatedTopNav,
    skipToMainContentButton,
    openOnBoardingModal,
    errorBlock,
    termsAndPrivacyModalContent,
    handleCloseOnBoardingModal,
    handleCloseTermsAndPrivacyModal,
    openTermsAndPrivacyModal,
    // MANUAL OVERRIDE ENDS
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.mainLayout;

  // MANUAL OVERRIDE STARTS
  const [topDisclaimer, setTopDisclaimer] = useState<string>();

  // footer view
  const legacyFooterView = <div className={stylesLegacy.footerContainer}>
    <FooterLegacy className={stylesLegacy.footer} />
  </div>;
  const footerView = <div className={styles.footerContainer}>
    <Footer
      className={cx(styles.footer, classes?.footer)}
      {...internalProps.footer}
      {...footer}/>
  </div>;
  const footerNavView = shouldShowFooter ? (
    <div className={cx(styles.footerContainer, classes?.footerContainer)}>
      <OptimizelyExperiment
        experiment={EXPERIMENTS.NEW_DESIGN}
        control={legacyFooterView}
        variation1={footerView}
        showControlOverride={!shouldShowUpdatedTopNav}
      />
    </div>
  ) : undefined;

  // topnav view
  let legacyTopNavView = (<div className={stylesLegacy.navContainer}>
    <TopNavLegacy className={stylesLegacy.topNav} {...topNavLegacy} />
  </div>);
  if (disableTopNav) {
    legacyTopNavView = (<div className={stylesLegacy.topNavDisable}>
      <TopNavLegacy className={stylesLegacy.topNav} {...topNavLegacy} />
    </div>);
  }

  const updatedTopNavView = (
    <div className={styles.navContainer}>
      <TopNav className={styles.topNav} {...topNav} />
    </div>
  );
  
  if (inMaintenance) {
    return (
      <>
        <div className={cx(currentStyle, className)}>
          <div className={cx(styles.navContainer, classes?.navContainer)}>
            <TopNav
              className={cx(styles.topNav, classes?.topNav)}
              {...internalProps.topNav}
              {...topNav}
              type="SignedOut"/>
          </div>
          <Route path='/'>
            <MaintenancePage
              maintenanceBlock={locationMaintenance}
              className={cx(styles.homepage, classes?.homepage)} />
          </Route>
          <div className={cx(styles.footerContainer, classes?.footerContainer)}>
            <Divider
              className={cx(styles.divider, classes?.divider)}
              {...internalProps.divider}
              {...divider}/>
            <Footer
              className={cx(styles.footer, classes?.footer)}
              {...internalProps.footer}
              {...footer}/>
          </div>
        </div>
      </>
    );
  }

  return !!loading ? null : (
    <>
      {/* // MANUAL OVERRIDE STARTS */}
      <Button
        href={`#${CONTENT_ID}`}
        className={cx(customStyles.skipToMainContent)}
        {...internalProps.skipToMainContentButton}
        {...skipToMainContentButton}
      />
      {/* // MANUAL OVERRIDE ENDS */}
      <div className={cx(stylesLegacy.mainLayout, currentStyle, className)}>
        {!!topDisclaimer && <TopDisclaimer disclaimer={topDisclaimer} />}
        <OptimizelyExperiment
          experiment={EXPERIMENTS.NEW_DESIGN}
          control={legacyTopNavView}
          variation1={updatedTopNavView}
          showControlOverride={!shouldShowUpdatedTopNav || openOnBoardingModal}
        />
        <Switch>
          <Route exact path='/'>
            <OptimizelyExperiment
              experiment={EXPERIMENTS.NEW_DESIGN}
              control={<HomepageLegacy className={stylesLegacy.homepage} setTopDisclaimer={setTopDisclaimer} />}
              variation1={<Homepage className={styles.homepage} />}
            />
          </Route>
          <Route exact path='/logout'>
            <Logout />
          </Route>
          <Route exact path='/mytickets'>
            <MyTicketsPage className={stylesLegacy.homepage} />
          </Route>
          <Route exact path='/exclusive-events'>
            <OptimizelyExperiment
              experiment={EXPERIMENTS.NEW_DESIGN}
              control={<ExclusivesEventsLandingPageLegacy className={stylesLegacy.homepage} />}
              variation1={<ExclusivesEventsLandingPage className={cx(styles.homepage, styles.otherPages)} />}
            />
          </Route>

          <Route exact path={['/nba', '/nhl', '/nfl', '/mls', '/mlb']}>
            <OptimizelyExperiment 
              experiment={EXPERIMENTS.NEW_DESIGN}
              control={<SportsLandingPageLegacy className={stylesLegacy.homepage} />}
              variation1={<SportsLandingPage className={stylesLegacy.homepage} />}
            />
          </Route>

          <Route exact path='/performers/:performerId'>
            <OptimizelyExperiment
              experiment={EXPERIMENTS.NEW_DESIGN}
              control={<PerformersPageLegacy className={stylesLegacy.homepage} setTopDisclaimer={setTopDisclaimer} />}
              variation1={<PerformersPage className={cx(styles.homepage, styles.otherPages)} />}
            />
          </Route>

          <Route exact path='/events/:eventId'>
            <OptimizelyExperiment
              experiment={EXPERIMENTS.NEW_DESIGN}
              control={<EventPageLegacy className={stylesLegacy.homepage} setTopDisclaimer={setTopDisclaimer} />}
              variation1={<EventPage className={cx(styles.homepage, styles.otherPages)} />}
            />
          </Route>

          <Route exact path='/venues/:venueId'>
            <OptimizelyExperiment
              experiment={EXPERIMENTS.NEW_DESIGN}
              control={<VenueLandingPageLegacy className={stylesLegacy.homepage} setTopDisclaimer={setTopDisclaimer} />}
              variation1={<VenueLandingPage className={cx(styles.homepage, styles.otherPages)} />}
            />

          </Route>

          {/* Capital One doesn't support cities */}
          {/* <Route exact path='/cities/:cityId'>
        <div>City landing page</div>
      </Route> */}

          <Route exact path='/search'>
            <SearchMatchPage className={stylesLegacy.homepage} />
          </Route>

          <Route exact path='/checkout/:ticketId'>
            <CheckoutManualPage className={stylesLegacy.homepage} />
          </Route>
          <Route exact path='/confirmation'>
            <ReceiptPage className={stylesLegacy.homepage} />
          </Route>

          <Route exact path='/help'>
            <HelpPage className={stylesLegacy.homepage} />
          </Route>
          <Route path='/'>
            <ErrorPage errorBlock={errorBlock} className={stylesLegacy.homepage} />
          </Route>
        </Switch>
        {footerNavView}
      </div>
      <AlertModal />
      <TermsAndPrivacyModal
        modalBodyContent={termsAndPrivacyModalContent}
        show={openTermsAndPrivacyModal}
        handleClose={handleCloseTermsAndPrivacyModal}
      />
      <OnBoardingModalLegacy
        show={openOnBoardingModal}
        handleClose={handleCloseOnBoardingModal}
      />
    </>
  );
  // MANUAL OVERRIDE ENDS
};

export default MainLayout;

