import { CardProps } from '../../molecules/Card';


type ExclusivesCardListInternalProps = {
  card?: CardProps;
};

type ExclusivesCardListPropsValues = {
  '': ExclusivesCardListInternalProps;
};

const propValues: ExclusivesCardListPropsValues = {
  '': {
    card: {
      type: 'Default',
      format: 'Long',
    },
  },
};

const getProps = (type: string): ExclusivesCardListInternalProps => {
  const values: ExclusivesCardListInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
