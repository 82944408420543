import React, { useContext } from 'react';
import { useQuery } from 'react-query';
import {
  getPerformerOpponents,
} from '../../../modules/partnership/api';
import { RegionContext } from '../../../modules/region';
import { SportTeamsLandingPageCombinedProps } from './types';

const useInteractor = (
  props: SportTeamsLandingPageCombinedProps,
): SportTeamsLandingPageCombinedProps => {
  const { performerId } = props;
  const { data: opponentsData } = useQuery(
    ['getPerformerOpponents', performerId],
    () => getPerformerOpponents(performerId?.toString() || ''),
  );
  const { currentRegion, allRegions } = useContext(RegionContext);

  const blockProps = {};
  return {
    ...props,
    ...blockProps,
    opponents: opponentsData,
    currentRegion: currentRegion,
    locationList: allRegions,
  };
};
export default useInteractor;
