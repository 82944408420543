import { DividerProps } from '../../atoms/Divider';
import { ButtonProps } from '../../atoms/Button';
import { TextProps } from '../../atoms/Text';
import { EmptyStateBannerProps } from '../../molecules/EmptyStateBanner';
import { EventsCardListProps } from '../../organisms/EventsCardList';
import { FilterGroupProps } from '../../organisms/FilterGroup';


type EventListingBlockInternalProps = {
  text?: TextProps;
  filterGroup?: FilterGroupProps;
  emptyStateBanner?: EmptyStateBannerProps;
  divider?: DividerProps;
  eventsCardList?: EventsCardListProps;
  showMoreButton?: ButtonProps;
};

type EventListingBlockPropsValues = {
  'Empty': EventListingBlockInternalProps;
  'Filled': EventListingBlockInternalProps;
};

const propValues: EventListingBlockPropsValues = {
  'Empty': {
    text: {
      type: 'Subheading',
      size: 'Medium',
      style: 'Regular',
      colour: 'SubduedDark',
      align: 'Left',
    },
    filterGroup: {
      type: 'TwoPairsOtherFilter', // MANUAL OVERRIDE
    },
    emptyStateBanner: {
    },
    divider: {
      type: 'Horizontal',
      style: 'Thin',
      colour: 'DigitalGrey15',
    },
  },
  'Filled': {
    text: {
      type: 'Subheading',
      size: 'Medium',
      style: 'Regular',
      colour: 'SubduedDark',
      align: 'Left',
    },
    filterGroup: {
      type: 'TwoPairsOtherFilter', // MANUAL OVERRIDE
    },
    eventsCardList: {
    },
    showMoreButton: {
      type: 'Text',
      style: 'Text',
      size: 'Small',
    },
    divider: {
      type: 'Horizontal',
      style: 'Thin',
      colour: 'DigitalGrey15',
    },
  },
};

const getProps = (type: string): EventListingBlockInternalProps => {
  const values: EventListingBlockInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
