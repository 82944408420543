import React, { useContext, useEffect } from 'react';
import {
  RouteProps,
  Route,
  useLocation,
  useHistory,
  Redirect,
} from 'react-router-dom';
import Cookie from 'js-cookie';
import { AuthContext } from '.';
import { UnauthorizedRedirect } from './AuthContext';
import ErrorHandler from '../error';

const CheckUserAccess: React.FC<RouteProps> = (props) => {
  const { account, loading, error, verifyAccessToken } = useContext(AuthContext);
  const { search, pathname } = useLocation();
  const query = new URLSearchParams(search);
  const assertion = query.get('assertion');
  const code = query.get('code');
  const lastFour = query.get('lastFour');
  const history = useHistory();
  const redirectUrl = Cookie.get('redirect_url');
  useEffect(() => {
    if (code && !loading) {
      verifyAccessToken({ access_token: code, token_type: 'code' });
      history.replace({});
    }
    if (assertion && !loading) {
      verifyAccessToken({ access_token: assertion, last_four: lastFour });
      query.delete('assertion');
      history.replace({
        search: query.toString(),
      });
    }
  }, [assertion, lastFour, loading, verifyAccessToken, history, code]);

  if (loading || assertion || code) {
    return <Route {...props} />;
  }
  if (pathname === '/help') {
    return <Route {...props} />;
  }
  if (error) {
    return <ErrorHandler isAuthError={true} error={error} />;
  }
  if (!account) {
    return <UnauthorizedRedirect redirectUrl={window.location.href} />;
  }
  if (redirectUrl) {
    Cookie.remove('redirect_url');
    window.location.assign(redirectUrl);
    return null;
  }
  return <Route {...props} />;
};


const ProtectedRoute: React.FC<RouteProps> = (props) => {
  return <CheckUserAccess {...props} />;
};

export default ProtectedRoute;
