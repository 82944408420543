import React, { createContext, useContext, useEffect, useState } from 'react';
import { AuthContext } from '../auth';
import { Region } from '../partnership';

import { getRegions, getRegionsByIp } from '../partnership/api';
import { Props } from '../../lib/types';
import { sortRegionByName } from './utils';

export type RegionContextValue = {
  error: Error | null;
  loading?: boolean;
  allRegions?: Region[];
  currentRegion?: Region;
  setPerformerId?: React.Dispatch<React.SetStateAction<number | undefined>>;
};

const initialContext: RegionContextValue = {
  error: null,
  loading: false,
  allRegions: undefined,
  currentRegion: undefined,
  setPerformerId: undefined,
};

export const RegionContext = createContext<RegionContextValue>(initialContext);

export const RegionProvider: React.FC<Props> = ({ children }) => {
  const { account } = useContext(AuthContext);
  const [currentRegion, setCurrentRegion] = useState<Region>();
  const [allRegions, setAllRegions] = useState<Region[]>();
  const [loading, setLoading] = useState(false);
  const [performerId, setPerformerId] = useState<number | undefined>(undefined);
  useEffect(() => {
    const doFetchRegions = async () => {
      try {
        const currentRegionResponse = await getRegionsByIp();
        setLoading(true);
        const allRegionResponse = await getRegions({
          performer_id: performerId,
        });
        setCurrentRegion(currentRegionResponse[0]);
        setAllRegions(sortRegionByName(allRegionResponse));
        setLoading(false);
      } catch {
        //
      }
    };

    if (account) {
      void doFetchRegions();
    }
  }, [account, performerId]);
  
  return (
    <RegionContext.Provider value={{
      error: null, // TODO
      loading: loading,
      currentRegion: currentRegion,
      allRegions: allRegions,
      setPerformerId,
    }}>
      {children}
    </RegionContext.Provider>
  );
};
