import { TextColourEnum } from '../../atoms/Text/types';
import { TagTypeEnum } from '../../molecules/Tag/types';

export const status = {
  orderCreated: [
    1, 2, 3, 4, 14, 15, 16, 21, 25, 31, 32, 35, 38, 39, 44, 48, 58, 67, 68, 91,
    93,
  ],
  orderConfirmed: [
    5, 6, 7, 8, 9, 13, 30, 33, 40, 41, 42, 43, 45, 46, 47, 49, 50, 51, 52, 53,
    54, 55, 56, 57, 59, 60, 61, 63, 64, 65, 66, 69, 70, 71, 72, 73, 75, 76, 77,
    78, 80, 81, 82, 83, 84, 85, 86, 87, 88, 90, 92,
  ],
  canceled: [17, 18, 22, 23, 36, 62, 74, 79, 89],
};

export const ticketStatus = (statusId: number): [string, TagTypeEnum, TextColourEnum] => {
  if (status.orderCreated.includes(statusId)) {
    return ['myTickets.orderCreated', 'Warning', 'SubduedDark'];
  } else if (status.orderConfirmed.includes(statusId)) {
    return ['myTickets.orderConfirmed', 'Positive', 'Positive'];
  } else if (status.canceled.includes(statusId)) {
    return ['myTickets.orderCanceled', 'Negative', 'Negative'];
  }

  return ['', 'Negative', 'Negative'];
};
