import { useContext, useEffect } from 'react';

import categoryEvents from '../../../modules/navigation/CategoryEvents.json';
import { TopNavTab } from '../../../modules/navigation/types';
import { RegionContext } from '../../../modules/region';

import { HomepageCombinedProps, HomepagePresenterProps } from './types';

const useInteractor = (props: HomepageCombinedProps): HomepagePresenterProps => {
  const eventCategory: TopNavTab[] = JSON.parse(JSON.stringify(categoryEvents));
  const { currentRegion, allRegions } = useContext(RegionContext);

  const allCategory: TopNavTab = {
    id: '',
    name: 'All Events',
    children: [],
  };

  useEffect(() => {
    props.setTopDisclaimer(); 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.setTopDisclaimer]);

  return {
    ...props,
    categories: [allCategory, ...eventCategory],
    currentRegion: currentRegion,
    locationList: allRegions,
  };
};

export default useInteractor;