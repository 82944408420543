import { IconProps } from '../../atoms/Icon';
import { TextProps } from '../../atoms/Text';
import { RecentlyViewedListProps } from '../../organisms/RecentlyViewedList';


type RecentlyViewedBlockInternalProps = {
  title?: TextProps;
  icon?: IconProps;
  recentlyViewedList?: RecentlyViewedListProps;
};

type RecentlyViewedBlockPropsValues = {
  '': RecentlyViewedBlockInternalProps;
};

const propValues: RecentlyViewedBlockPropsValues = {
  '': {
    title: {
      type: 'Subheading', // MANUAL OVERRIDE
      size: 'Large',
      style: 'Light', // MANUAL OVERRIDE
      colour: 'SubduedDark', // MANUAL OVERRIDE
      align: 'Left',
    },
    icon: {
      asset: 'ClockLarge',
      style: 'DigitalGrey60',
    },
    recentlyViewedList: {
    },
  },
};

const getProps = (type: string): RecentlyViewedBlockInternalProps => {
  const values: RecentlyViewedBlockInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
