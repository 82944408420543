import React, { RefObject } from 'react';
import cx from 'classnames';
import { Link } from 'react-router-dom';

import { useSnapItem } from '../../../modules/snap/SnapListContext/useSnapItem';

import Text from '../../atoms/Text';
import Divider from '../../atoms/Divider';
import EventStatusTag from '../../atoms/EventStatusTag';
import EventTypeTag from '../../atoms/EventTypeTag';
import Icon from '../../atoms/Icon';
import Image from '../../atoms/Image';
import DateAndTime from '../DateAndTime';

import styles from './SpecialEventCard.module.scss';
import usePresenter from './SpecialEventCard.presenter';
import getProps from './SpecialEventCard.props';
import { SpecialEventCardCombinedProps } from './types';


const SpecialEventCard: React.FC<SpecialEventCardCombinedProps> = (props) => {
  const {
    style,
    className,
    classes,
    disabled,
    buttonType,
    buttonForm,
    href,
    target,
    externalLink,
    onClick,
    image,
    eventTypeTag,
    eventStatusTag,
    titleText,
    descriptionText,
    dateAndTime,
    location,
    divider,
    acttionText,
    actionText,
    icon,
    index, // MANUAL OVERRIDE
    ariaLabel, // MANUAL OVERRIDE
  } = usePresenter(props);
  const { elementRef } = useSnapItem(index);

  const variantName = `${style}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`specialEventCard${variantName}`];


  let componentView;

  switch (variantName) {
    case 'Dark': {
      componentView = (
        // MANUAL OVERRIDE - using Link instead of button
        <Link
          ref={elementRef as RefObject<HTMLAnchorElement>} //eslint-disable-line @typescript-eslint/no-unnecessary-type-assertion
          data-item="true"
          title={ariaLabel}
          to={{ pathname: href || '', state: { canGoBack: true } }}
          className={cx(currentStyle, className)}>
          <div className={cx(styles.topContent, classes?.topContent)}>
            <Image
              className={cx(styles.image, classes?.image)}
              {...internalProps.image}
              {...image} />
            <EventTypeTag
              className={cx(styles.eventTypeTag, classes?.eventTypeTag)}
              {...internalProps.eventTypeTag}
              {...eventTypeTag} />
          </div>
          <div className={cx(styles.bottomContent, classes?.bottomContent)}>
            <div className={cx(styles.content, classes?.content)}>
              <EventStatusTag
                className={cx(styles.eventStatusTag, classes?.eventStatusTag)}
                {...internalProps.eventStatusTag}
                {...eventStatusTag} />
              <Text
                className={cx(styles.titleText, classes?.titleText)}
                {...internalProps.titleText}
                {...titleText} />
              <Text
                className={cx(styles.descriptionText, classes?.descriptionText)}
                {...internalProps.descriptionText}
                {...descriptionText} />
              <div className={cx(styles.eventLogistics, classes?.eventLogistics)}>
                <DateAndTime
                  className={cx(styles.dateAndTime, classes?.dateAndTime)}
                  {...internalProps.dateAndTime}
                  {...dateAndTime} />
                <DateAndTime
                  className={cx(styles.location, classes?.location)}
                  {...internalProps.location}
                  {...location} />
              </div>
              <div className={cx(styles.actionContent, classes?.actionContent)}>
                <Divider
                  className={cx(styles.divider, classes?.divider)}
                  {...internalProps.divider}
                  {...divider} />
                <div className={cx(styles.textIcon, classes?.textIcon)}>
                  <Text
                    className={cx(styles.acttionText, classes?.acttionText)}
                    {...internalProps.acttionText}
                    {...acttionText} />
                  <Text
                    className={cx(styles.actionText, classes?.actionText)}
                    {...internalProps.actionText}
                    {...actionText} />
                  <Icon
                    className={cx(styles.icon, classes?.icon)}
                    {...internalProps.icon}
                    {...icon} />
                </div>
              </div>
            </div>
          </div>
        </Link>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default SpecialEventCard;

