import React, { RefObject } from 'react';
import cx from 'classnames';
import { Link } from 'react-router-dom';

import { useSnapItem } from '../../../modules/snap/SnapListContext/useSnapItem';

import Text from '../../atoms/Text';
import Divider from '../../atoms/Divider';
import EventStatusTag from '../../atoms/EventStatusTag';
import EventTypeTag from '../../atoms/EventTypeTag';
import Icon from '../../atoms/Icon';
import Image from '../../atoms/Image';

import styles from './EventCardLight.module.scss';
import usePresenter from './EventCardLight.presenter';
import getProps from './EventCardLight.props';
import { EventCardLightCombinedProps } from './types';


const EventCardLight: React.FC<EventCardLightCombinedProps> = (props) => {
  const {
    className,
    classes,
    disabled,
    buttonType,
    buttonForm,
    href,
    target,
    externalLink,
    onClick,
    image,
    eventTypeTag,
    /* MANUAL OVERRIDE - removed eventTypeTag1 */
    eventStatusTag,
    titleText,
    dateAndTimeText,
    locationAndPriceText,
    divider,
    actionText,
    icon,
    // MANUAL OVERRIDE STARTS
    index,
    ariaLabel,
    displayEventStatusTag,
    displayEventTypeTag,
    // MANUAL OVERRIDE ENDS
  } = usePresenter(props);

  const variantName = '';
  const internalProps = getProps(variantName);
  const currentStyle = styles[`eventCardLight${variantName}`];

  // MANUAL OVERRIDE STARTS
  const { elementRef } = useSnapItem(index);
  const eventTypeTagView = displayEventTypeTag ? <EventTypeTag
    className={cx(styles.eventTypeTag, classes?.eventTypeTag)}
    {...internalProps.eventTypeTag}
    {...eventTypeTag} /> : undefined;

  const eventStatusTagView = displayEventStatusTag ? <EventStatusTag
    className={cx(styles.eventStatusTag, classes?.eventStatusTag)}
    {...internalProps.eventStatusTag}
    {...eventStatusTag} /> : undefined;
  // MANUAL OVERRIDE ENDS


  let componentView;

  switch (variantName) {
    case '': {
      componentView = (
        // MANUAL OVERRIDE - replaced button tag
        <Link
          ref={elementRef as RefObject<HTMLAnchorElement>}
          data-item="true" 
          aria-disabled={!!disabled}
          title={ariaLabel} 
          to={{ pathname: href || '', state: { canGoBack: true } }}
          className={cx(currentStyle, className)} >
          <div className={cx(styles.topContent, classes?.topContent)}>
            <Image
              className={cx(styles.image, classes?.image)}
              {...internalProps.image}
              {...image}/>
            {/* MANUAL OVERRIDE */}
            { eventTypeTagView }
          </div>
          <div className={cx(styles.bottomContent, classes?.bottomContent)}>
            <div className={cx(styles.content, classes?.content)}>
              {/* MANUAL OVERRIDE - removed eventTypeTag1 */}
              <div className={cx(styles.status, classes?.status)}>
                {/* MANUAL OVERRIDE */}
                { eventStatusTagView }
              </div>
              <Text
                className={cx(styles.titleText, classes?.titleText)}
                {...internalProps.titleText}
                {...titleText}/>
              <div className={cx(styles.eventLogistics, classes?.eventLogistics)}>
                <div className={cx(styles.dateAndTime, classes?.dateAndTime)}>
                  <Text
                    className={cx(styles.dateAndTimeText, classes?.dateAndTimeText)}
                    {...internalProps.dateAndTimeText}
                    {...dateAndTimeText}/>
                </div>
                <div className={cx(styles.locationAndPrice, classes?.locationAndPrice)}>
                  <Text
                    className={cx(styles.locationAndPriceText, classes?.locationAndPriceText)}
                    {...internalProps.locationAndPriceText}
                    {...locationAndPriceText}/>
                </div>
              </div>
            </div>
            <div className={cx(styles.actionContent, classes?.actionContent)}>
              <Divider
                className={cx(styles.divider, classes?.divider)}
                {...internalProps.divider}
                {...divider}/>
              <div className={cx(styles.textIcon, classes?.textIcon)}>
                <Text
                  className={cx(styles.actionText, classes?.actionText)}
                  {...internalProps.actionText}
                  {...actionText}/>
                <Icon
                  className={cx(styles.icon, classes?.icon)}
                  {...internalProps.icon}
                  {...icon}/>
              </div>
            </div>
          </div>
        </Link>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default EventCardLight;

