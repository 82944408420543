import React from 'react';
import cx from 'classnames';

import Divider from '../../atoms/Divider';
import Text from '../../atoms/Text';

import customStyles from './Custom.module.scss';
import styles from './PrimaryBanner.module.scss';
import usePresenter from './PrimaryBanner.presenter';
import getProps from './PrimaryBanner.props';
import { PrimaryBannerCombinedProps } from './types';


const PrimaryBanner: React.FC<PrimaryBannerCombinedProps> = (props) => {
  const {
    className,
    classes,
    imageSrc,
    imageSrcAlt,
    imageFallback,
    title,
    divider,
    message,
    // MANUAL OVERRIDE STARTS
    isExclusive,
    // MANUAL OVERRIDE ENDS
  } = usePresenter(props);

  const backgroundClass = isExclusive ? customStyles.exclusivePrimaryBannerBackground : customStyles.primaryBannerBackground;

  const internalProps = getProps('');
  const currentStyle = styles.primaryBanner;

  return (
    // MANUAL OVERRIDE - added a custom style class for exlusive page primary banner
    <div className={cx(currentStyle, className, backgroundClass)}>
      <div className={cx(styles.textContainer, classes?.textContainer)}>
        <div className={cx(styles.content, classes?.content)}>
          <div className={cx(styles.leftContent, classes?.leftContent)}/>
          <div className={cx(styles.messageContent, classes?.messageContent)}>
            <Text
              className={cx(styles.title, classes?.title)}
              {...internalProps.title}
              {...title}/>
            <Divider
              className={cx(styles.divider, classes?.divider)}
              {...internalProps.divider}
              {...divider}/>
            {/* MANUAL OVERRIDE - added a custom class */}
            <Text
              className={cx(styles.message, classes?.message, customStyles.message)}
              {...internalProps.message}
              {...message}/>
          </div>
        </div>
      </div>
      {/* MANUAL OVERRIDE - replaced the class messageContent with messageContentMobile */}
      <div className={cx(styles.messageContentMobile, classes?.messageContentMobile)}>
        <Text
          className={cx(styles.title, classes?.title)}
          {...internalProps.title}
          {...title}/>
        <Divider
          className={cx(styles.divider, classes?.divider)}
          {...internalProps.divider}
          {...divider}/>
        {/* MANUAL OVERRIDE - added a custom class */}
        <Text
          className={cx(styles.message, classes?.message, customStyles.message)}
          {...internalProps.message}
          {...message}/>
      </div>
    </div>
  );
};

export default PrimaryBanner;

