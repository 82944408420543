import { useLocation } from 'react-router-dom';
import useInteractor from './SportTeamsNearLocationBlock.interactor';
import { SportTeamsNearLocationBlockCombinedProps } from './types';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { PerformerDetail, PerformerPageResponse, PerformersQueryParams } from '../../../modules/partnership';
import { DropdownOption } from '../../molecules/Filter/types';
import { getQueryParams } from '../../../lib/util';
import { dynamicRegionList, getDefaultOption, getLocation, locationFilterDefaultOptions } from '../../molecules/Filter/utils';
import { ThumbnailCardValueProps } from '../../molecules/ThumbnailCard';

const usePresenter = (props: SportTeamsNearLocationBlockCombinedProps): SportTeamsNearLocationBlockCombinedProps => {
  const {
    fetchTeams,
    getPerformer,
    currentRegion,
    sportsLeague,
    locationList,
  } = useInteractor(props);

  const { search } = useLocation();
  const { t } = useTranslation();
  const query = new URLSearchParams(search);
  const regionId = query.get('region_id');
  const [teamCards, setTeamCards] = useState<PerformerDetail[]>();
  const [locationFilters, setLocationFilter] = useState<DropdownOption[]>([]);
  const [apiParams, setApiParams] = useState<PerformersQueryParams>({
    region_id: currentRegion?.id,
    ...getQueryParams(search),
    subgenre_id: sportsLeague,
  });

  const defaultLocation = getLocation(locationList, regionId);

  useEffect(() => {
    setLocationFilter(locationFilterDefaultOptions(currentRegion));
  }, [currentRegion]);

  const handleRegionFilter = (value: string) => {
    if (value.length >= 3) {
      if (locationList) {
        const options: DropdownOption[] = [
          ...locationFilterDefaultOptions(currentRegion),
          ...dynamicRegionList(locationList, value),
        ];
        setLocationFilter(options);
      }
    } else {
      const options: DropdownOption[] = [
        ...locationFilterDefaultOptions(currentRegion),
      ];
      setLocationFilter(options);
    }
  };

  useEffect(() => {
    setTeamCards([]);
    const queryParams = { ...getQueryParams(search) };
    const currentRegionParams = {
      region_id: currentRegion?.id,
    };

    setApiParams({
      ...currentRegionParams,
      ...queryParams,
      subgenre_id: sportsLeague,
    });
  }, [search, currentRegion, sportsLeague]);

  useEffect(() => {
    const doFetchTeams = async (queryParams: PerformersQueryParams) => {
      try {
        let teamDetails;
        if (fetchTeams && getPerformer) {
          teamDetails = await fetchTeams(queryParams).then(
            (page: PerformerPageResponse) => {
              return Promise.all(
                page.items.map(async (item) => {
                  const team = await getPerformer(String(item.id));
                  return team;
                }),
              );
            },
          );
        }
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        setTeamCards(teamDetails);
      } catch {
        // TODO
      }
    };

    const queryParams = { ...apiParams };
    if (currentRegion?.id || regionId) {
      void doFetchTeams(queryParams);
    }
  }, [apiParams, fetchTeams, getPerformer]);

  const cards = teamCards?.map((item): ThumbnailCardValueProps => {
    return {
      title: {
        value: item.name,
      },
      imageSrc: item.image,
      linkPath: `/performers/${item.id}`,
    };
  });

  return {
    ...props,
    state: cards?.length ? 'Filled' : 'Empty',
    thumbnailCardList: {
      thumbnailCards: cards,
    },
    filter: {
      type: 'TextIconFilter',
      filterType: 'SearchLocationFilter',
      filterTitle: t('sportsLeague.teamsNear'),
      filterName: 'region_id',
      defaultOption: getDefaultOption(locationFilters),
      defaultSelectedOption: defaultLocation,
      options: locationFilters,
      onTextChange: handleRegionFilter,
    },
  };
};

export default usePresenter;
