import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { DropdownOption } from '../../molecules/Filter/types';
import { dateFilterOptions, dynamicRegionList, getLocation, locationFilterDefaultOptions } from '../../molecules/Filter/utils';

import useInteractor from './Homepage.interactor';
import { HomepageCombinedProps } from './types';

const usePresenter = (props: HomepageCombinedProps): HomepageCombinedProps => {
  const { categories, currentRegion, locationList } = useInteractor(props);
  const { search } = useLocation();
  const { t } = useTranslation();

  const [locationFilters, setLocationFilter] = useState<DropdownOption[]>([]);
  const query = new URLSearchParams(search);
  const regionId = query.get('region_id');
  const defaultLocation = getLocation(locationList, regionId);

  useEffect(() => {
    setLocationFilter(locationFilterDefaultOptions(currentRegion));
  }, [currentRegion]);

  const handleRegionFilter = (value: string) => {
    if (value.length >= 3) {
      if (locationList) {
        const options: DropdownOption[] = [
          ...locationFilterDefaultOptions(currentRegion),
          ...dynamicRegionList(locationList, value),
        ];
        setLocationFilter(options);
      }
    } else {
      const options: DropdownOption[] = [
        ...locationFilterDefaultOptions(currentRegion),
      ];
      setLocationFilter(options);
    }
  };
  return {
    ...props,
    setTopDisclaimer: () => undefined,
    primaryBannerBlock: {
      primaryBanner: {
        message: {
          type: 'BannerSubheading',
          colour: 'BaseLight',
          size: 'Small',
          value: t('homePageBanner.title'),
        },
        title: {
          type: 'BannerHeading',
          size: 'Large',
          colour: 'BaseLight',
          style: 'Light',
          value: t('homePageBanner.message'),
        },
      },
    },
    eventListingBlock: {
      cardType: 'Exclusive',
      text: {
        type: 'Subheading',
        size: 'Medium',
        style: 'Regular',
        colour: 'SubduedDark',
        align: 'Left',
        value: t('shopByWidget.shopBy'),
      },
      filterGroup: {
        titleValue: t('shopByWidget.shopBy'),
        categoryName: 'category',
        categories: categories,
        filters: [
          {
            type: 'IconTextFilter',
            filterType: 'SearchLocationFilter',
            filterIcon: 'LocationMarker',
            filterTitle: t('shopByWidget.near'),
            filterName: 'region_id',
            options: locationFilters,
            defaultSelectedOption: defaultLocation,
            onTextChanged: handleRegionFilter,
          },
          {
            type: 'IconFilter',
            filterType: 'DateFilter',
            filterIcon: 'Calendar',
            options: dateFilterOptions,
          },
        ],
      },
      requiredFields: currentRegion && currentRegion.id ? ['region_id'] : undefined,
    },
  };
};

export default usePresenter;
