import React, { useMemo, useRef, useState } from 'react';
import cx from 'classnames';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useHistory, useLocation } from 'react-router-dom';

import { LIVE_CHAT_URL } from '../../../lib/config';
import { TERMS_MODAL } from '../../../lib/constants';
import { FAQCategoryStateType } from '../../../modules/navigation/types';

import { DropdownMenuItemCombinedProps } from '../../atoms/DropdownMenuItem/types';
import { TabCombinedProps } from '../../atoms/Tab/types';
import { FAQItemCombinedProps } from '../../molecules/FAQItem/types';

import customStyles from './Custom.module.scss';
import { QuestionsBlockCombinedProps } from './types';

export const getTranslation = (
  transKey: string,
  showTermsOfServiceModal: VoidFunction,
): React.ReactNode => {
  return (
    <Trans
      i18nKey={transKey}
      components={{
        paragraph: <p className={customStyles.answerParagraph} />,
        bulletList: <ul className={customStyles.bulletList} />,
        bullet: <li />,
        bold: <strong />,
        italic: <i />,
        hyperlink: (
          <a href="https://axs.com" style={{ textDecoration: 'underline' }} />
        ),
        privacyLink: (
          <Link to="/privacy" style={{ textDecoration: 'underline' }} />
        ),
        preSaleLink: (
          <a
            href="https://capitalone.com/pre-sales"
            style={{ textDecoration: 'underline' }}
          />
        ),
        capOneLink: (
          <a
            href="https://capitalone.com"
            style={{ textDecoration: 'underline' }}
          />
        ),
        termsOfUseLink: (
          <button
            className={customStyles.linkButton}
            onClick={showTermsOfServiceModal}
          />
        ),
        vividSeatSupportEmail: (
          <a
            href="mailto: support@coe.vividseats.com"
            style={{ textDecoration: 'underline' }}
          />
        ),
      }}
    ></Trans>
  );
};

export const sendEMail = (transKey: string): React.ReactNode => {
  return (
    <Trans
      i18nKey={transKey}
      components={{
        hyperlink: <a href="mailto: support@coe.vividseats.com" />,
      }}
    ></Trans>
  );
};

const usePresenter = (props: QuestionsBlockCombinedProps): QuestionsBlockCombinedProps => {
  const { t } = useTranslation();
  const shouldCollapseAll = useRef(false);
  const history = useHistory();
  const { search } = useLocation();
  const query = useMemo(() => new URLSearchParams(search), [search]);
  const modalQuery = query.get('modal');
  const showTermsOfServiceModal = () => {
    if (modalQuery !== TERMS_MODAL) {
      query.set('modal', TERMS_MODAL);
      // setting URLSearchParams didn't work so used history hook
      history.push(`?${query.toString()}`);
    }
  };
  const [selectedCategory, setSelectedCategory] =
      useState<FAQCategoryStateType>('TopQuestions');
  const [openDropdown, setOpenDropdown] = useState(false); // menu dropdown
  const fAQItems: FAQItemCombinedProps[] = [];

  const arrayOfFAQStates: FAQCategoryStateType[] = [
    'TopQuestions',
    'GenInfo',
    'BuyingPricing',
    'TicketSeatInfo',
    'TicketDeliveryInfo',
    'Cancellation',
    'ContactUs',
  ];

  const questionCount: Record<FAQCategoryStateType, number> = {
    TopQuestions: 7,
    GenInfo: 8,
    BuyingPricing: 14,
    TicketSeatInfo: 11,
    TicketDeliveryInfo: 5,
    Cancellation: 5,
    ContactUs: 0,
  };

  // start index from one to match the question answers number in translation file
  for (let index = 1; index <= questionCount[selectedCategory]; index++) {
    fAQItems.push({
      state: 'Collapsed',
      id: index,
      icon: {
        asset: 'ChevronDown',
      },
      question: {
        value: t(`${selectedCategory}.q${index}`),
      },
      answer: {
        value: getTranslation(
          `${selectedCategory}.a${index}`,
          showTermsOfServiceModal,
        ),
      },
      shouldCollapseAll: shouldCollapseAll.current,
    });
  }

  const dropdownMenuItems: DropdownMenuItemCombinedProps[] = arrayOfFAQStates.map(
    (state) => {
      return {
        id: state,
        type: selectedCategory === state ? 'IconText' : 'Text',
        dropdownMenuItemType: 'button',
        onDropdownMenuItemClicked: () => {
          setOpenDropdown(false);
          setSelectedCategory(state);
        },
        text: {
          colour: 'ActionActive',
          value: t(`${state}.title`),
        },
      };
    },
  );

  const tabs: TabCombinedProps[] = arrayOfFAQStates.map(
    (state: FAQCategoryStateType) => {
      return {
        colour: 'Dark',
        style: 'Rounded',
        type: selectedCategory === state ? 'Active' : 'Default',
        onClick: () => {
          setSelectedCategory(state);
          shouldCollapseAll.current = true;
        },
        text: {
          style: 'SemiBold',
          colour: selectedCategory === state ? 'BaseDark' : 'SubduedLight',
          value: t(`${state}.title`),
        },
      };
    },
  );

  return {
    ...props,
    className: cx(props.className, customStyles.customQuestionsBlock),
    classes: {
      title: cx(props.classes?.title, customStyles.customTitle),
    },
    showDropdown: openDropdown,
    title: {
      value: t('footer.help'),
      classes: {
        value: customStyles.customValue,
      },
    },
    button: {
      onClick: () => {
        setOpenDropdown((p) => !p);
      },
      text: {
        value: t(`${selectedCategory}.title`),
      },
    },
    fAQList: {
      fAQItems: selectedCategory === 'ContactUs' ? undefined : fAQItems,
    },
    tabList: {
      tabs: tabs,
    },
    dropdownSelectList: {
      dropdownMenuItems,
    },
    contactUs: {
      contactUsItem: {
        title: {
          value: t('ContactUs.forHelpTitle'),
        },
        message: {
          value: t('ContactUs.forHelpMessage'),
        },
      },
      vividChat: {
        title: {
          value: t('ContactUs.sendChatTitle'),
        },
        message: {
          value: t('ContactUs.sendChatMessage'),
        },
        button: {
          onClick: (e) => {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            window.open(LIVE_CHAT_URL, '');
          },
          text: {
            value: t('ContactUs.sendChatButton'),
          },
        },
      },
      vividEmail: {
        title: {
          value: t('ContactUs.sendEmailTitle'),
        },
        message: {
          value: sendEMail('ContactUs.sendEmailMessage'),
        },
        button: {
          onClick: (e) => {
            window.location.href = 'mailto: support@coe.vividseats.com';
            e?.preventDefault();
          },
          text: {
            value: t('ContactUs.sendEmailButton'),
          },
        },
      },
      vividPhone: {
        title: {
          value: t('ContactUs.callTitle'),
        },
        message: {
          value: t('ContactUs.callMessage'),
        },
        button: {
          onClick: (e) => {
            e?.preventDefault();
            window.location.href = 'tel:+18007369097';
          },
          text: {
            value: '1-800-736-9097',
          },
        },
      },
    },
  };
};

export default usePresenter;
