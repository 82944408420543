import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import GenericErrorAsset from '../../../resources/legacyIcons/GenericError.svg';
import { handleLoyaltyUnitTags } from '../../../lib/util';
import { CP1_EXCLUSIVE_TAG } from '../../../lib/constants';
import { ErrorBlockCombinedProps } from '../../blocks/ErrorBlock/types';
import { EventPageProps } from '.';
import useInteractor from './EventPage.interactor';
import { EventPagePresenterProps } from './types';
import { ListingsContext } from '../../../modules/listings';

const usePresenter = (props: EventPageProps): EventPagePresenterProps => {
  const { 
    account, 
    eventId,
    event,
    addRecentEvent,
    isLoading,
    isContextLoading,
    showGeneralAdmissionPage,
  } = useInteractor(props);
  const { t } = useTranslation();
  const history = useHistory();
  const [updatedRecentEvents, setUpdatedRecentEvents] = useState(false);
  useEffect(() => {
    if (addRecentEvent && !updatedRecentEvents) {
      setUpdatedRecentEvents(true);
      addRecentEvent(Number(eventId));
    }
  }, [addRecentEvent, eventId]);

  if (
    account &&
    account.loyalty_program &&
    event?.tags?.length &&
    event?.tags.includes(CP1_EXCLUSIVE_TAG) &&
    (!event.tags.includes(
      handleLoyaltyUnitTags(
        account.loyalty_program
          ? account.loyalty_program.loyalty_unit_name
          : '',
      ),
    ) ||
      (event.tags.includes(CP1_EXCLUSIVE_TAG) &&
        !event?.tags?.includes(
          account.loyalty_program
            ? account?.loyalty_program?.program_type_tag
            : '',
        )) ||
      !event?.tags?.includes(
        account?.loyalty_program?.processing_network
          ? account?.loyalty_program?.processing_network.toUpperCase()
          : '',
      ))
  ) {
    const orderErrorBlockProps: ErrorBlockCombinedProps = {
      image: {
        imageSrc: GenericErrorAsset,
      },
      title: {
        value: t('notRedeemAbleEvents.title'),
      },
      message: {
        value: t('notRedeemAbleEvents.message'),
      },
      button: {},
    };

    history.push('/event-error', orderErrorBlockProps);
  }

  return {
    ...props,
    isLoading,
    showGeneralAdmissionPage,
    event,
    eventId,
    isContextLoading,
  };
};

export default usePresenter;
