import React, { RefObject } from 'react';
import cx from 'classnames';
import { Link } from 'react-router-dom';

import { useSnapItem } from '../../../modules/snap/SnapListContext/useSnapItem';

import Text from '../../atoms/Text';
import Divider from '../../atoms/Divider';
import Icon from '../../atoms/Icon';

import styles from './MlbCard.module.scss';
import usePresenter from './MlbCard.presenter';
import getProps from './MlbCard.props';
import { MlbCardCombinedProps } from './types';


const MlbCard: React.FC<MlbCardCombinedProps> = (props) => {
  const {
    type,
    className,
    classes,
    disabled,
    buttonType,
    buttonForm,
    href,
    target,
    externalLink,
    onClick,
    titleText,
    bodyText,
    divider,
    actionText,
    icon,
    // MANUAL OVERRIDE STARTS
    index,
    // MANUAL OVERRIDE ENDS
  } = usePresenter(props);

  // MANUAL OVERRIDE STARTS
  const { elementRef } = useSnapItem(index);
  // MANUAL OVERRIDE ENDS

  const variantName = `${type}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`mlbCard${variantName}`];


  let componentView;

  switch (variantName) {
    case 'Light': {
      componentView = (
        // MANUAL OVERRIDE - using Link instead of button
        <Link
          type={buttonType}
          data-item="true"
          ref={elementRef as RefObject<HTMLAnchorElement>}
          to={{ pathname: href || '', state: { canGoBack: true } }}
          className={cx(currentStyle, className)}
        >
          <div className={cx(styles.content, classes?.content)}>
            <div className={cx(styles.topContent, classes?.topContent)}>
              <Text
                className={cx(styles.titleText, classes?.titleText)}
                {...internalProps.titleText}
                {...titleText}/>
              <Text
                className={cx(styles.bodyText, classes?.bodyText)}
                {...internalProps.bodyText}
                {...bodyText}/>
            </div>
            <div className={cx(styles.actionContent, classes?.actionContent)}>
              <Divider
                className={cx(styles.divider, classes?.divider)}
                {...internalProps.divider}
                {...divider}/>
              <div className={cx(styles.textIcon, classes?.textIcon)}>
                <Text
                  className={cx(styles.actionText, classes?.actionText)}
                  {...internalProps.actionText}
                  {...actionText}/>
                <Icon
                  className={cx(styles.icon, classes?.icon)}
                  {...internalProps.icon}
                  {...icon}/>
              </div>
            </div>
          </div>
        </Link>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default MlbCard;

