import React, { useContext } from 'react';
import styles from './EventPage.module.scss';
import TicketSelectionPage from '../TicketSelectionPage';

import { ListingsContext, ListingsProvider } from '../../../modules/listings';
import Spinner from '../../atoms/Spinner';
import SeatSelectionPage from '../../../legacy/pages/SeatSelectionPage';
import { EventPageProps } from '.';
import { FilterProvider } from '../../../modules/filter';
import usePresenter from './EventPage.presenter';

const EventPage: React.FC<EventPageProps> = (props) => {
  const {
    event,
    isLoading,
    showGeneralAdmissionPage,
    isContextLoading,
  } = usePresenter(props);

  if (isLoading || isContextLoading) {
    return <Spinner />;
  }
  const seatSelection = (
    <SeatSelectionPage className={styles.seatSelectionPage} 
    event={event} 
    />
  );
  const ticketSelection = (
    <TicketSelectionPage className={styles.ticketSelectionPage} 
    event={event} 
    />
  );

  return showGeneralAdmissionPage ? ticketSelection : seatSelection;
};

export default EventPage;
