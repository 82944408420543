import { useEffect, useState } from 'react';
import { PrecheckoutBlockProps } from './types';
import { IconTextDescriptionCombinedProps } from '../../molecules/IconTextDescription/types';
import { DropdownItem } from '../../atoms/DropdownSelect/types';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { getQueryParams, addQueryParam, handleDateFormat, handleTimeFormat, RewardUnits, handleVariableImage, handleDisplayUnitName, getTicketListingTranslationKey, handleDecimalValuesForDisplay, handleAmountFormattingString, getUnitDisplaySettings, shouldShowSplitPaymentPrice, handleLocation } from '../../../lib/util';
import { useTranslation } from 'react-i18next';
import { TicketAlertModalProps, TicketAlertModalTitleMessage } from '../../organisms/TicketAlertModal/types';
import { determineDeliveryOption, findPreviousPurchases, formatPoints, getEventPurchaseLimit } from './utils';
import { C1_BROKER_ID, REWARDS_ONLY_TAG } from '../../../lib/constants';
import useInteractor from './PrecheckoutBlock.interactor';

const usePresenter = (props: PrecheckoutBlockProps): PrecheckoutBlockProps => {
  const { account, fetchOrders } = useInteractor(props);
  const { listing, isExclusive, image, onBackButtonClick } = props;
  const showSplitPaymentPrice = (listing) && shouldShowSplitPaymentPrice(listing.event);
  const isRewardsOnlyEvent = listing?.event.tags?.includes(REWARDS_ONLY_TAG);
  const { t } = useTranslation();
  const { search } = useLocation();
  const history = useHistory();
  const query = getQueryParams(search);
  const { eventId } = useParams<{ eventId: string }>();
  const [isTicketAlertModalOpen, setIsTicketAlertModalOpen] = useState(false);
  const hasCp1BrokerId = listing?.listing.broker_id === C1_BROKER_ID;
  const purchaseLimit = getEventPurchaseLimit(listing?.event.tags || []);
  const isMiles = account?.loyalty_program?.loyalty_unit_name.toLowerCase() === RewardUnits.MILES.toLowerCase();

  const defaultTicketAlertModalTitleMessage: TicketAlertModalTitleMessage = {
    title: t('insufficientRewards.title'),
    message: t('insufficientRewards.message'),
  };
  const purchaseLimitModalTitleMessage: TicketAlertModalTitleMessage = {
    title: t('purchaseLimitError.title'),
    message: t('purchaseLimitError.message', {
      purchaseLimit,
    }),
  };
  const [ticketAlertModalTitleMessage, setTicketAlertModalTitleMessage] = useState({
    title: '',
    message: '',
  });
  const rewardType = account?.loyalty_program?.loyalty_unit_name.toLowerCase();
  const { rewardSign, useDecimals } = getUnitDisplaySettings(rewardType);
  // We need to show Rewards when there is no SPLIT_PAYMENT or CREDIT_CARD tag OR it is an Rewards only event
  const pointsValue = (!showSplitPaymentPrice || isRewardsOnlyEvent) ? listing?.listing?.loyalty_price?.price_per : listing?.listing.price_per;
  const sign = showSplitPaymentPrice ? '$' : rewardSign;
  const [previousTickets, setPreviousTickets] = useState(0);
  const pointsDisplayValue = `${sign}${handleDecimalValuesForDisplay(handleAmountFormattingString(pointsValue), useDecimals)}`;
  const quantitySelection = {
    value: String(query.quantity),
  };
  useEffect(() => {
    const doFetchEvents = async () => {
      try {
        if (account && purchaseLimit && fetchOrders) {
          const response = await fetchOrders();
          setPreviousTickets(findPreviousPurchases(response.orders, eventId));
        }
      } catch {
        // TODO
      }
    };
    void doFetchEvents();
  }, [fetchOrders]);

  const updateQuantity = (quantity?: string | undefined) => {
    const params = addQueryParam(history.location.search, {
      'quantity': quantity || '',
    });
    if (params) {
      history.push({
        search: params,
      });
    }
  };

  const handleCheckoutEvent = () => {
    if (listing && account) {
      const isLoyaltyPointLimitCrossed = isRewardsOnlyEvent && listing?.listing.loyalty_price.price_per * +quantitySelection.value > account.loyalty_program.number_of_units;
      const isPurchaseLimitCrossed = purchaseLimit < (Number(query.quantity) + previousTickets);
      if (isPurchaseLimitCrossed) {
        setTicketAlertModalTitleMessage(purchaseLimitModalTitleMessage);
        setIsTicketAlertModalOpen(true);
        return;
      }

      if (isLoyaltyPointLimitCrossed) {
        setTicketAlertModalTitleMessage(defaultTicketAlertModalTitleMessage);
        setIsTicketAlertModalOpen(true);
        return;
      }
    }

    history.push({
      pathname: `/checkout/${query.ticket_id}`,
      search: `?eventId=${eventId}&quantity=${query.quantity}&step=1&exclusive_listings=${hasCp1BrokerId}&delivery_id=${determineDeliveryOption(listing?.pricing.delivery.id || 0)}`,
    });
  };

  const guranteedDescription: IconTextDescriptionCombinedProps = {
    type: 'Default',
    icon: {
      asset: 'CheckmarkInCircle',
    },
    description: {
      value: t('precheckoutBlock.ticketsGuaranteed'),
    },
  };

  const descriptions: IconTextDescriptionCombinedProps[] | [] = [
    {
      type: 'WithLogo',
      description: {
        value: hasCp1BrokerId ? t('precheckoutBlock.cp1') : t('precheckoutBlock.vivid'),
      },
      logo: {
        asset: hasCp1BrokerId ? 'LogoC1Default' : 'VividLogoWithColour',
        logoAlt: hasCp1BrokerId ? t('precheckoutBlock.cp1LogoScreenReaderText') : t('precheckoutBlock.vividLogoScreenReaderText'),
      },
      icon: {
        asset: 'Ticket',
      },
    },
    {
      type: 'Default',
      description: {
        value: t('precheckoutBlock.notes', {
          note: listing?.listing.notes,
        }),
      },
      icon: {
        asset: 'Profile',
      },
    },
    {
      type: 'Default',
      description: {
        value: listing && t(getTicketListingTranslationKey(listing, 'ticket_details')),
      },
      icon: {
        asset: 'Mobile',
      },
    },
    guranteedDescription];

  const dropdownItems: DropdownItem[] | [] =
    listing?.listing.quantities ? listing?.listing.quantities.map((quantity) => {
      const pointsTotal = quantity * listing?.listing.loyalty_price.price_per;
      const isSingleTicket = quantity === 1;
      const label = showSplitPaymentPrice ?
        t(isSingleTicket ? 'precheckoutBlock.singleCost' : 'precheckoutBlock.totalCost', {
          quantity: quantity,
          totalPrice: quantity * listing?.listing.price_per,
          totalPoints: handleDecimalValuesForDisplay(formatPoints(pointsTotal, isMiles), !isMiles),
          type: handleDisplayUnitName(account),
        })
        :
        t(isSingleTicket ? 'precheckoutBlock.singlePoints' : 'precheckoutBlock.totalPoints', {
          quantity: quantity,
          totalPoints: handleDecimalValuesForDisplay(formatPoints(pointsTotal, isMiles), !isMiles),
          type: handleDisplayUnitName(account),
        });

      return ({
        label: label,
        value: String(quantity),
        ariaLabel: t('shopByWidget.selectedOptionAnnouncement', {
          option: label,
        }),
      } as DropdownItem);
    }).reverse() : [];

  const ticketAlertModalView: TicketAlertModalProps = {
    open: isTicketAlertModalOpen,
    closeButton: {
      onClick: () => setIsTicketAlertModalOpen(false),
    },
    title: {
      value: ticketAlertModalTitleMessage.title,
    },
    message: {
      value: ticketAlertModalTitleMessage.message,
    },
    primaryButton: {
      text: {
        value: t('insufficientRewards.button'),
      },
      onClick: () => setIsTicketAlertModalOpen(false),
    },
  };

  const precheckoutBlock: PrecheckoutBlockProps = {
    topNav: {
      listing: listing,
      onBackButtonClicked: onBackButtonClick,
      backButton: {
        onClick: onBackButtonClick,
      },
      type: 'TicketSeatSelection',
    },
    exclusiveEventBanner: {
      imageSrc: listing && handleVariableImage(listing.event, 'hero', 'mobile'),
    },
    cardInfoHeader: {
      title: {
        value: listing?.event.name,
      },
      date: {
        value: listing && handleDateFormat(listing?.event.local_date),
      },
      time: {
        value: listing && handleTimeFormat(listing.event.local_date),
      },
      location: {
        value: listing && handleLocation(listing?.event.venue),
      },
    },
    image: image || {
      imageSrc: listing && handleVariableImage(listing.event, 'hero', 'desktop'),
    },
    cardNavigation: {
      button: {
        text: {
          value: t('precheckoutBlock.backToTickets'),
          colour: 'ActionHover',
        },
        icon: {
          asset: 'ArrowLeft',
        },
        onClick: onBackButtonClick,
      },
    },
    ticketInfo: {
      ticketSelectionInformation: {
        title: {
          value: listing?.listing.verbose_section_name,
        },
        location: {
          value: t('eventPage.location', {
            row: listing?.listing.row,
          }),
        },
        points: {
          value: listing?.listing.loyalty_price.price_per,
        },
        text1: {
          value: `${handleDisplayUnitName(account)} ${isRewardsOnlyEvent ? '' : t('eventPage.or')}`,
        },
        value: {
          value: isRewardsOnlyEvent ? '' : `$${listing?.listing.price_per}`,
        },
        text2: {
          value: isRewardsOnlyEvent ? '' : t('eventPage.each'),
        },
      },
      ticketInformation: {
        title: {
          value: listing?.listing.verbose_section_name || listing?.listing.seating.section,
        },
        location: {
          value: t('eventPage.location', {
            row: listing?.listing.seating.row,
          }),
        },
        value: {
          value: pointsDisplayValue,
        },
        valueType: {
          value: showSplitPaymentPrice ?
            t('precheckoutBlock.cost', {
              currency: listing?.listing.currency,
              points: handleDecimalValuesForDisplay(formatPoints(Number(listing?.listing.loyalty_price.price_per), isMiles), !isMiles),
              type: handleDisplayUnitName(account),
            }) : handleDisplayUnitName(account),
        },
        other: {
          value: isExclusive ? t('eventPage.each') : t('precheckoutBlock.eachPlusFees'),
        },
      },
      additionalTicketInformationList: {
        iconTextDescriptions: descriptions,
      },
      ticketQuantitySelection: {
        dropdown: {
          state: 'Default',
          label: {
            value: t('precheckoutBlock.quantity'),
          },
          dropdownSelect: {
            selection: quantitySelection,
            dropdownItems: dropdownItems,
            onDropdownSelectClicked: updateQuantity,
          },
        },
        button: {
          text: {
            value: t('precheckoutBlock.checkout'),
          },
          onClick: () => handleCheckoutEvent(),
        },
      },
    },
    ticketAlertModalProps: ticketAlertModalView,
  };



  return {
    ...props,
    ...precheckoutBlock,
  };
};

export default usePresenter;
