import React from 'react';
import cx from 'classnames';

import Text from '../../atoms/Text';
import Image from '../../atoms/Image';
import CardInfoHeader from '../../molecules/CardInfoHeader';
import ExclusiveEventBanner from '../../molecules/ExclusiveEventBanner';
import ExclusiveEventInfo from '../../molecules/ExclusiveEventInfo';
import SelectTicket from '../../organisms/SelectTicket';
import TicketList from '../../organisms/TicketList';

import styles from './TicketTypeSelectionBlock.module.scss';
import usePresenter from './TicketTypeSelectionBlock.presenter';
import getProps from './TicketTypeSelectionBlock.props';
import { TicketTypeSelectionBlockCombinedProps } from './types';
import TicketAvailabilityCardSoldOut from '../../molecules/TicketAvailabilityCardSoldOut';
import PrecheckoutBlock from '../PrecheckoutBlock';


const TicketTypeSelectionBlock: React.FC<TicketTypeSelectionBlockCombinedProps> = (props) => {
  const {
    className,
    classes,
    image,
    exclusiveEventBanner,
    selectTicket,
    exclusiveEventInfo,
    description,
    cardInfoHeader,
    ticketList,
    // MANUAL OVERRIDE STARTS
    type,
    isSoldOut,
    ticketAvailabilityCardSoldOut,
    precheckout,
    // MANUAL OVERRIDE ENDS
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.ticketTypeSelectionBlock;

  const ticketViewDesktop = isSoldOut ? (
    <TicketAvailabilityCardSoldOut
      className={styles.ticketsSoldOut}
      {...ticketAvailabilityCardSoldOut}
    />
  ) : type === 'Precheckout' ? (
    <PrecheckoutBlock
      className={styles.preCheckOutContainer}
      {...precheckout}
    />
  ) : (
    <SelectTicket className={styles.selectTicket} {...selectTicket} />
  );
  const ticketViewMobile = isSoldOut ? (
    <TicketAvailabilityCardSoldOut
      className={styles.ticketsSoldOutMobile}
      {...ticketAvailabilityCardSoldOut}
    />
  ) : type === 'Precheckout' ? (
    <PrecheckoutBlock className={styles.ticketList} {...precheckout} />
  ) : (
    <TicketList className={styles.ticketList} {...ticketList} />
  );

  return (
    <div className={cx(currentStyle, className)}>
      <Image
        className={cx(styles.image, classes?.image)}
        {...internalProps.image}
        {...image}/>
      <ExclusiveEventBanner
        className={cx(styles.exclusiveEventBanner, classes?.exclusiveEventBanner)}
        {...internalProps.exclusiveEventBanner}
        {...exclusiveEventBanner}/>
      <div className={cx(styles.content, classes?.content)}>
        {ticketViewDesktop}
        <div className={cx(styles.rightContent, classes?.rightContent)}>
          <ExclusiveEventInfo
            className={cx(styles.exclusiveEventInfo, classes?.exclusiveEventInfo)}
            {...internalProps.exclusiveEventInfo}
            {...exclusiveEventInfo}/>
          <Text
            className={cx(styles.description, classes?.description)}
            {...internalProps.description}
            {...description}/>
        </div>
      </div>
      <CardInfoHeader
        className={cx(styles.cardInfoHeader, classes?.cardInfoHeader)}
        {...internalProps.cardInfoHeader}
        {...cardInfoHeader}/>
      {ticketViewMobile}
      <div className={cx(styles.descriptionContainer, classes?.descriptionContainer)}>
        <Text
          className={cx(styles.description, classes?.description)}
          {...internalProps.description}
          {...description}/>
      </div>
    </div>
  );
};

export default TicketTypeSelectionBlock;

