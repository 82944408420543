import React from 'react';
import { useExperiment  } from '@optimizely/react-sdk';
import { ReactNode } from 'react';

type OptimizelyExperimentProps = {
  experiment: string;
  control: ReactNode;
  variation1: ReactNode;
  showControlOverride?: boolean;
};

const OptimizelyExperiment: React.FC<OptimizelyExperimentProps> = (
  { 
    experiment, 
    control, 
    variation1,
    showControlOverride: showControlComponent,
  }) => {
  const [ variation ] = useExperiment(experiment);

  let currentVariation;

  if (showControlComponent === true) {
    currentVariation = control;
  } else {
    switch (variation) {
      case 'control':
        currentVariation = control;
        break;
      case 'variation_1':
        currentVariation = variation1;
        break;
    }
  }


  return (
    <>
      { currentVariation }
    </>
  );
};

export default OptimizelyExperiment;