import { TextProps } from '../../atoms/Text';
import { TagProps } from '../Tag';


type ExclusiveEventInfoInternalProps = {
  tag?: TagProps;
  title?: TextProps;
  eventInfo?: TextProps;
};

type ExclusiveEventInfoPropsValues = {
  '': ExclusiveEventInfoInternalProps;
};

const propValues: ExclusiveEventInfoPropsValues = {
  '': {
    tag: {
      type: 'Information',
      size: 'Regular',
    },
    title: {
      type: 'Subheading',
      size: 'Medium',
      style: 'Regular',
      colour: 'SubduedDark',
      align: 'Left',
    },
    eventInfo: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'SubduedLight',
      align: 'Left',
    },
  },
};

const getProps = (type: string): ExclusiveEventInfoInternalProps => {
  const values: ExclusiveEventInfoInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
