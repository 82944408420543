import React from 'react';
import cx from 'classnames';

import Button from '../../atoms/Button';
import CardTitleBanner from '../../molecules/CardTitleBanner';
import ExclusivesCardList from '../../organisms/ExclusivesCardList';

import styles from './ExclusivesEventBlock.module.scss';
import usePresenter from './ExclusivesEventBlock.presenter';
import getProps from './ExclusivesEventBlock.props';
import { ExclusivesEventBlockCombinedProps } from './types';
import Spinner from '../../atoms/Spinner';


const ExclusivesEventBlock: React.FC<ExclusivesEventBlockCombinedProps> = (props) => {
  const {
    className,
    classes,
    cardTitleBanner,
    exclusivesCardList,
    button,
    // MANUAL OVERRIDE STARTS
    isLoading,
    showButton,
    showWidget,
    // MANUAL OVERRIDE ENDS
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.exclusivesEventBlock;

  // MANUAL OVERRIDE STARTS
  if (isLoading) {
    return <Spinner />;
  }

  if (!showWidget) {
    return null;
  }

  const buttonView = showButton ? (
    <Button
      className={cx(styles.button, classes?.button)}
      {...internalProps.button}
      {...button}/>
  ) : null;

  return (
    <div className={cx(currentStyle, className)}>
      {/* MANUAL OVERRIDE - removed cardTitleBanner */}
      <div className={cx(styles.container, classes?.container)}>
        <CardTitleBanner
          className={cx(styles.cardTitleBanner, classes?.cardTitleBanner)}
          {...internalProps.cardTitleBanner}
          {...cardTitleBanner}/>
        <div className={cx(styles.content, classes?.content)}>
          <ExclusivesCardList
            className={cx(styles.exclusivesCardList, classes?.exclusivesCardList)}
            {...internalProps.exclusivesCardList}
            {...exclusivesCardList}/>
          {buttonView}
        </div>
      </div>
      {/* MANUAL OVERRIDE - removed second content div */}
    </div>
  );
};

export default ExclusivesEventBlock;

