import React from 'react';
import cx from 'classnames';

import Divider from '../../atoms/Divider';
import Icon from '../../atoms/Icon';
import Text from '../../atoms/Text';
import Tag from '../Tag';

import styles from './TicketAvailabilityCardSoldOut.module.scss';
import usePresenter from './TicketAvailabilityCardSoldOut.presenter';
import getProps from './TicketAvailabilityCardSoldOut.props';
import { TicketAvailabilityCardSoldOutCombinedProps } from './types';
import Button from '../../atoms/Button';


const TicketAvailabilityCardSoldOut: React.FC<TicketAvailabilityCardSoldOutCombinedProps> = (props) => {
  const {
    className,
    classes,
    tag,
    icon,
    message,
    text,
    divider,
    button, // MANUAL OVERRIDE
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.ticketAvailabilityCardSoldOut;

  return (
    <div className={cx(currentStyle, className)}>
      <div className={cx(styles.content, classes?.content)}>
        <Tag
          className={cx(styles.tag, classes?.tag)}
          {...internalProps.tag}
          {...tag}/>
        <div className={cx(styles.mainMessage, classes?.mainMessage)}>
          <Icon
            className={cx(styles.icon, classes?.icon)}
            {...internalProps.icon}
            {...icon}/>
          <Text
            className={cx(styles.message, classes?.message)}
            {...internalProps.message}
            {...message}/>
        </div>
        <Button 
          className={styles.button}
          {...internalProps.button}
          {...button} />
      </div>
      <Divider
        className={cx(styles.divider, classes?.divider)}
        {...internalProps.divider}
        {...divider}/>
    </div>
  );
};

export default TicketAvailabilityCardSoldOut;

