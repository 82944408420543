import { useContext, useEffect, useState } from 'react';
import { TicketSelectionPageCombinedProps } from './types';
import { AuthContext } from '../../../modules/auth';
import { ListingsContext } from '../../../modules/listings';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { TopNavContext } from '../../../modules/topNav';
import { C1_BROKER_ID, CP1_EXCLUSIVE_TAG } from '../../../lib/constants';
import { ListingMutation } from '../../../legacy/pages/SeatSelectionPage/utils';
import { ListingDetails } from '../../../modules/partnership';
import { RewardUnits, addQueryParam, checkSoldOut, getQueryParams, handleAmountFormattingString, handleDateFormat, handleDecimalValuesForDisplay, handleDisplayUnitName, handleLocationShort, handleTimeFormat, handleVariableImage, shouldShowSplitPaymentPrice } from '../../../lib/util';
import { useTranslation } from 'react-i18next';
import { TicketInformationCombinedProps } from '../../molecules/TicketInformation/types';
import useInteractor from './TicketSelectionPage.interactor';
import styles from './TicketSelectionPage.module.scss';

const usePresenter = (props: TicketSelectionPageCombinedProps): TicketSelectionPageCombinedProps => {
  const { event, fetchListing } = useInteractor(props);
  const { account } = useContext(AuthContext);
  const { selectors: { composedListings: listings } } = useContext(ListingsContext);
  const history = useHistory();
  const { t } = useTranslation();
  const { hasNoEventMap } = useContext(TopNavContext);
  const isExclusiveEvent = event?.tags?.includes(CP1_EXCLUSIVE_TAG);
  const { search, pathname } = useLocation();
  const { eventId } = useParams<{ eventId: string }>();
  const [listing, setListing] = useState<ListingDetails>();
  const [apiParams, setApiParams] = useState<ListingMutation>({
    event_id: '',
    ticket_id: '',
    params: {
      quantity: 0,
    },
  });

  useEffect(() => {
    const queryParams = { ...getQueryParams(search) };
    if (Object.keys(queryParams).length !== 0) {
      setApiParams({
        event_id: eventId || '',
        ticket_id: queryParams.ticket_id || '',
        params: {
          quantity: Number(queryParams.quantity) || 0,
          exclusive_listings: queryParams.exclusive_listings === 'true',
        },
      });
    }
  }, [search, eventId]);

  useEffect(() => {
    const doFetchListing = async (queryParams: ListingMutation) => {
      try {
        if (queryParams?.ticket_id) {
          const response = await fetchListing(queryParams);
          setListing(response);
        }
      } catch {
        setListing(undefined);
      }
    };
    void doFetchListing(apiParams);
  }, [apiParams, fetchListing]);
  let eventInfoString;
  let eventDateString;
  let eventTimeString;
  let eventLocationString;
  if (event) {
    eventDateString = handleDateFormat(event.local_date);
    eventTimeString = `at ${handleTimeFormat(event.local_date)}`;
    eventLocationString = handleLocationShort(event.venue);
    eventInfoString = `${eventDateString} ${eventTimeString} - ${event.venue.name}, ${eventLocationString}`;
  }

  const ticketInformationArray: TicketInformationCombinedProps[] = [];
  if (event && listings?.length) {
    const showSplitPaymentPrice = shouldShowSplitPaymentPrice(event);
    const displayUnitName = handleDisplayUnitName(account);
    listings.map((singleTicket) => {
      const formattedValue =
        account?.loyalty_program.loyalty_unit_name.toLowerCase() ===
        RewardUnits.MILES.toLowerCase()
          ? handleAmountFormattingString(singleTicket.loyalty_price.price_per)
          : `$${handleDecimalValuesForDisplay(
            handleAmountFormattingString(
              singleTicket.loyalty_price.price_per,
            ),
            true,
          )}`;
      let ticketInformation: TicketInformationCombinedProps = {
        type: isExclusiveEvent ? 'Default' : 'MoreDetails',
        title: {
          value: singleTicket.verbose_section_name || singleTicket.section,
        },
        location: {
          value: t('eventPage.location', {
            row: singleTicket.row,
          }),
        },
        value: {
          value: formattedValue,
        },
        valueType: {
          value: displayUnitName,
        },
        other: {
          value: t('eventPage.each'),
        },
        availability: {
          value: t('eventPage.availability', {
            quantity: singleTicket.quantity,
          }),
        },
        onClicked: () => {
          const params = addQueryParam(history.location.search, {
            ticket_id: singleTicket.id,
            quantity: String(singleTicket.quantities[0]),
            exclusive_listings: (
              singleTicket.broker_id === C1_BROKER_ID
            ).toString(),
          });

          if (params) {
            history.push({
              search: params,
            });
          }
        },
      };
      if (showSplitPaymentPrice) {
        ticketInformation = {
          ...ticketInformation,
          pointsValue: {
            value: `${formattedValue} ${displayUnitName}`,
          },
          value: {
            value: `$${singleTicket.price_per}`,
          },
          valueType: {
            value: t('eventPage.or'),
          },
        };
      }
      ticketInformationArray.push(ticketInformation);
    });
  }

  const isSoldOut = event && checkSoldOut(event);

  return {
    ...props,
    ticketTypeSelectionBlock: {
      type: listing ? 'Precheckout' : 'Listings',
      isSoldOut,
      ticketAvailabilityCardSoldOut: {
        tag: {
          label: {
            value: t('ticketSelectionPage.soldOut'),
            style: 'SemiBold',
            colour: 'Negative',
          },
        },
        message: {
          value: t('ticketSelectionPage.soldOutMessage'),
        },
        text: {
          value: t('ticketSelectionPage.checkOutExclusives'),
        },
        button: {
          text: {
            value: t('ticketSelectionPage.checkOutExclusives'),
          },
          href: '/exclusive-events',
        },
      },
      ticketList: {
        ticketInformations: ticketInformationArray,
      },
      selectTicket: {
        instructionLabel: {
          text: {
            value: t('ticketSelectionPage.chooseExperience'),
          },
        },
        ticketList: {
          ticketInformations: ticketInformationArray,
        },
      },
      image: {
        imageSrc: event && handleVariableImage(event, 'hero', 'desktop'),
      },
      exclusiveEventBanner: {
        imageSrc: event && handleVariableImage(event, 'hero', 'mobile'),
        tag: {
          label: {
            value: t('ticketSelectionPage.cardholderExclusive'),
          },
        },
      },
      exclusiveEventInfo: {
        title: {
          value: event?.name,
        },
        eventInfo: {
          value: eventInfoString,
        },
        tag: {
          label: {
            value: t('ticketSelectionPage.cardholderExclusive'),
          },
        },
      },
      cardInfoHeader: {
        title: {
          value: event?.name,
        },
        location: {
          value: eventLocationString,
        },
        date: {
          value: eventDateString,
        },
        time: {
          value: eventTimeString,
        },
      },
      description: {
        value:
          event?.supplemental_data &&
          event.supplemental_data.event_description
            ? event.supplemental_data.event_description
            : event?.description,
      },
      precheckout: {
        listing: listing,
        isExclusive: event?.tags?.includes(CP1_EXCLUSIVE_TAG),
        image: {
          imageSrc: '',
          className: styles.hidePreCheckoutImage,
        },
        onBackButtonClick: () => {
          setListing(undefined);
          history.push(pathname);
        },
        topNav: {
          backButton: {
            onClick: () => {
              setListing(undefined);
              history.push(pathname);
            },
          },
        },
      },
    },
  };
};

export default usePresenter;
