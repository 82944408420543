import React from 'react';
import cx from 'classnames';

import { PerformersPageProps } from './types';
import usePresenter from './PerformersPage.presenter';
import PerformerLandingPage from '../PerformerLandingPage';
import SportTeamsLandingPage from '../SportTeamsLandingPage';

const PerformersPage: React.FC<PerformersPageProps> = (props) => {
  const {
    isSports,
    performer,
    performerId,
    className,
    setTopDisclaimer,
  } = usePresenter(props);

  return (
    <>
      {
        isSports ?
          <SportTeamsLandingPage
            className={cx(className)}
            team={performer}
            performerId={performerId} />
          :
          <PerformerLandingPage
            className={cx(className)}
            performer={performer}
            performerId={performerId} />
      }
    </>
  );
};

export default PerformersPage;
