import { EventListingBlockProps } from '../../blocks/EventListingBlock';
import { ExclusivesEventBlockProps } from '../../blocks/ExclusivesEventBlock';
import { PrimaryBannerBlockProps } from '../../blocks/PrimaryBannerBlock';
import { SearchBlockProps } from '../../blocks/SearchBlock';


type HomepageInternalProps = {
  searchBlock?: SearchBlockProps;
  primaryBannerBlock?: PrimaryBannerBlockProps;
  exclusivesEventBlock?: ExclusivesEventBlockProps;
  eventListingBlock?: EventListingBlockProps;
};

type HomepagePropsValues = {
  '': HomepageInternalProps;
};

const propValues: HomepagePropsValues = {
  '': {
    searchBlock: {
    },
    primaryBannerBlock: {
    },
    exclusivesEventBlock: {
    },
    eventListingBlock: {
      state: 'Filled',
    },
  },
};

const getProps = (type: string): HomepageInternalProps => {
  const values: HomepageInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
