import React from 'react';
import cx from 'classnames';

import LandingPageBannerBlock from '../../blocks/LandingPageBannerBlock';
import EventListingBlock from '../../blocks/EventListingBlock';

import styles from './VenueLandingPage.module.scss';
import usePresenter from './VenueLandingPage.presenter';
import getProps from './VenueLandingPage.props';
import { VenueLandingPageCombinedProps } from './types';


const VenueLandingPage: React.FC<VenueLandingPageCombinedProps> = (props) => {
  const {
    className,
    classes,
    landingPageBannerBlock,
    venueEvents,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.venueLandingPage;

  return (
    <div className={cx(currentStyle, className)}>
      <div className={cx(styles.container, classes?.container)}>
        <LandingPageBannerBlock
          className={cx(styles.landingPageBannerBlock, classes?.landingPageBannerBlock)}
          {...internalProps.landingPageBannerBlock}
          {...landingPageBannerBlock}/>
        <EventListingBlock
          className={cx(styles.venueEvents, classes?.venueEvents)}
          {...internalProps.venueEvents}
          {...venueEvents}/>
      </div>
    </div>
  );
};

export default VenueLandingPage;

