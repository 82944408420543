

type IconInternalProps = {
};

type IconPropsValues = {
  'ArenaBaseDark': IconInternalProps;
  'ArenaWhite': IconInternalProps;
  'ArrowLeftActionBase': IconInternalProps;
  'ArrowLeftBaseDark': IconInternalProps;
  'ArrowLeftWhite': IconInternalProps;
  'ArrowRightFilledActionBase': IconInternalProps;
  'ArrowRightFilledActionHover': IconInternalProps;
  'ArrowRightFilledWhite': IconInternalProps;
  'ArrowRightActionBase': IconInternalProps;
  'ArrowRightWhite': IconInternalProps;
  'CalendarIllustrationDigitalGrey80': IconInternalProps;
  'CalendarLargeDigitalGrey30': IconInternalProps;
  'CalendarActionActive': IconInternalProps;
  'CalenderNewSubduedDark': IconInternalProps;
  'CheckboxEmptyDigitalGrey100': IconInternalProps;
  'CheckboxFilledDigitalGrey100': IconInternalProps;
  'CheckmarkInCircleFilledGreen60': IconInternalProps;
  'CheckmarkInCircleDigitalGrey80': IconInternalProps;
  'CheckmarkActionActive': IconInternalProps;
  'CheckmarkActionBase': IconInternalProps;
  'CheckmarkDigitalGrey80': IconInternalProps;
  'CheckmarkWhite': IconInternalProps;
  'ChevronDownFilledDigitalGrey100': IconInternalProps;
  'ChevronDownActionActive': IconInternalProps;
  'ChevronDownActionHover': IconInternalProps;
  'ChevronDownDigitalGrey80': IconInternalProps;
  'ChevronDownWhite': IconInternalProps;
  'ChevronLeftFilledDigitalGrey100': IconInternalProps;
  'ChevronLeftActionActive': IconInternalProps;
  'ChevronLeftActionBase': IconInternalProps;
  'ChevronLeftActionHover': IconInternalProps;
  'ChevronLeftDigitalGrey100': IconInternalProps;
  'ChevronLeftWhite': IconInternalProps;
  'ChevronRightFilledDigitalGrey100': IconInternalProps;
  'ChevronRightActionActive': IconInternalProps;
  'ChevronRightActionBase': IconInternalProps;
  'ChevronRightActionHover': IconInternalProps; // MANUAL OVERRIDE
  'ChevronRightDigitalGrey100': IconInternalProps;
  'ChevronRightWhite': IconInternalProps;
  'ChevronUpFilledDigitalGrey100': IconInternalProps;
  'ChevronUpActionActive': IconInternalProps;
  'ChevronUpActionHover': IconInternalProps;
  'ChevronUpDigitalGrey80': IconInternalProps;
  'ChevronUpWhite': IconInternalProps;
  'ClockLargeDigitalGrey60': IconInternalProps;
  'ClockBaseDark': IconInternalProps;
  'ClockWhite': IconInternalProps;
  'CloseCircleFillDigitalGrey100': IconInternalProps;
  'CloseCircleActionHover': IconInternalProps;
  'CloseCircleDigitalGrey100': IconInternalProps;
  'CloseCircleWhite': IconInternalProps;
  'CloseActionBase': IconInternalProps;
  'CloseDigitalGrey80': IconInternalProps;
  'CloseWhite': IconInternalProps;
  'DeliveryDigitalGrey80': IconInternalProps;
  'FootballDigitalGrey30': IconInternalProps;
  'InfoFilledDigitalGrey80': IconInternalProps;
  'InfoDigitalGrey80': IconInternalProps;
  'InfoWhite': IconInternalProps;
  'LocationMarkerSmallWhite': IconInternalProps;
  'LocationMarkerActionActive': IconInternalProps;
  'LocationPinFilledSubduedDark': IconInternalProps;
  'LocationPinActionBase': IconInternalProps;
  'LocationPinDigitalGrey100': IconInternalProps;
  'LocationPinWhite': IconInternalProps;
  'MailDigitalGrey80': IconInternalProps;
  'MasterCardDigitalGrey80': IconInternalProps;
  'MenuWhite': IconInternalProps;
  'MinusDigitalGrey60': IconInternalProps;
  'MinusActionBase': IconInternalProps; // MANUAL OVERRIDE
  'MobileScanDigitalGrey80': IconInternalProps;
  'MobileDigitalGrey80': IconInternalProps;
  'MyLocationActionBase': IconInternalProps;
  'MyLocationDigitalGrey100': IconInternalProps;
  'MyLocationWhite': IconInternalProps;
  'PlusActionActive': IconInternalProps;
  'PlusActionBase': IconInternalProps;
  'PlusDigitalGrey60': IconInternalProps;
  'PrinterActionActive': IconInternalProps;
  'PrinterActionBase': IconInternalProps;
  'ProfileDigitalGrey80': IconInternalProps;
  'RadioEmptyDigitalGrey100': IconInternalProps;
  'RadioFilledDigitalGrey100': IconInternalProps;
  'SearchSmallCharcoal20': IconInternalProps;
  'SearchSmallWhite': IconInternalProps;
  'SearchWhite': IconInternalProps;
  'SearchBaseDark': IconInternalProps;
  'SettingsDigitalGrey60': IconInternalProps;
  'ShirtBaseDark': IconInternalProps;
  'ShirtWhite': IconInternalProps;
  'ThumbtackDigitalGrey30': IconInternalProps;
  'TicketBigDigitalGrey30': IconInternalProps;
  'TicketCutDigitalGrey80': IconInternalProps;
  'TicketDigitalGrey80': IconInternalProps;
  'TicketWithLinesDigitalGrey80': IconInternalProps;
  'VSActionActive': IconInternalProps;
  'VisaDigitalGrey80': IconInternalProps;
  'WarningOutlineDigitalGrey60': IconInternalProps;
  'WarningDigitalGrey80': IconInternalProps;
};

const propValues: IconPropsValues = {
  'ArenaBaseDark': {
  },
  'ArenaWhite': {
  },
  'ArrowLeftActionBase': {
  },
  'ArrowLeftBaseDark': {
  },
  'ArrowLeftWhite': {
  },
  'ArrowRightFilledActionBase': {
  },
  'ArrowRightFilledActionHover': {
  },
  'ArrowRightFilledWhite': {
  },
  'ArrowRightActionBase': {
  },
  'ArrowRightWhite': {
  },
  'CalendarIllustrationDigitalGrey80': {
  },
  'CalendarLargeDigitalGrey30': {
  },
  'CalendarActionActive': {
  },
  'CalenderNewSubduedDark': {
  },
  'CheckboxEmptyDigitalGrey100': {
  },
  'CheckboxFilledDigitalGrey100': {
  },
  'CheckmarkInCircleFilledGreen60': {
  },
  'CheckmarkInCircleDigitalGrey80': {
  },
  'CheckmarkActionActive': {
  },
  'CheckmarkActionBase': {
  },
  'CheckmarkDigitalGrey80': {
  },
  'CheckmarkWhite': {
  },
  'ChevronDownFilledDigitalGrey100': {
  },
  'ChevronDownActionActive': {
  },
  'ChevronDownActionHover': {
  },
  'ChevronDownDigitalGrey80': {
  },
  'ChevronDownWhite': {
  },
  'ChevronLeftFilledDigitalGrey100': {
  },
  'ChevronLeftActionActive': {
  },
  'ChevronLeftActionBase': {
  },
  'ChevronLeftActionHover': {
  },
  'ChevronLeftDigitalGrey100': {
  },
  'ChevronLeftWhite': {
  },
  'ChevronRightFilledDigitalGrey100': {
  },
  'ChevronRightActionActive': {
  },
  // MANUAL OVERRIDE
  'ChevronRightActionHover': {
  },
  'ChevronRightActionBase': {
  },
  'ChevronRightDigitalGrey100': {
  },
  'ChevronRightWhite': {
  },
  'ChevronUpFilledDigitalGrey100': {
  },
  'ChevronUpActionActive': {
  },
  'ChevronUpActionHover': {
  },
  'ChevronUpDigitalGrey80': {
  },
  'ChevronUpWhite': {
  },
  'ClockLargeDigitalGrey60': {
  },
  'ClockBaseDark': {
  },
  'ClockWhite': {
  },
  'CloseCircleFillDigitalGrey100': {
  },
  'CloseCircleActionHover': {
  },
  'CloseCircleDigitalGrey100': {
  },
  'CloseCircleWhite': {
  },
  'CloseActionBase': {
  },
  'CloseDigitalGrey80': {
  },
  'CloseWhite': {
  },
  'DeliveryDigitalGrey80': {
  },
  'FootballDigitalGrey30': {
  },
  'InfoFilledDigitalGrey80': {
  },
  'InfoDigitalGrey80': {
  },
  'InfoWhite': {
  },
  'LocationMarkerSmallWhite': {
  },
  'LocationMarkerActionActive': {
  },
  'LocationPinFilledSubduedDark': {
  },
  'LocationPinActionBase': {
  },
  'LocationPinDigitalGrey100': {
  },
  'LocationPinWhite': {
  },
  'MailDigitalGrey80': {
  },
  'MasterCardDigitalGrey80': {
  },
  'MenuWhite': {
  },
  'MinusDigitalGrey60': {
  },
  // MANUAL OVERRIDE
  'MinusActionBase': {
  },
  'MobileScanDigitalGrey80': {
  },
  'MobileDigitalGrey80': {
  },
  'MyLocationActionBase': {
  },
  'MyLocationDigitalGrey100': {
  },
  'MyLocationWhite': {
  },
  'PlusActionActive': {
  },
  'PlusActionBase': {
  },
  'PlusDigitalGrey60': {
  },
  'PrinterActionActive': {
  },
  'PrinterActionBase': {
  },
  'ProfileDigitalGrey80': {
  },
  'RadioEmptyDigitalGrey100': {
  },
  'RadioFilledDigitalGrey100': {
  },
  'SearchSmallCharcoal20': {
  },
  'SearchSmallWhite': {
  },
  'SearchWhite': {
  },
  'SearchBaseDark': {
  },
  'SettingsDigitalGrey60': {
  },
  'ShirtBaseDark': {
  },
  'ShirtWhite': {
  },
  'ThumbtackDigitalGrey30': {
  },
  'TicketBigDigitalGrey30': {
  },
  'TicketCutDigitalGrey80': {
  },
  'TicketDigitalGrey80': {
  },
  'TicketWithLinesDigitalGrey80': {
  },
  'VSActionActive': {
  },
  'VisaDigitalGrey80': {
  },
  'WarningOutlineDigitalGrey60': {
  },
  'WarningDigitalGrey80': {
  },
};

const getProps = (type: string): IconInternalProps => {
  const values: IconInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
