import { AriaRole, KeyboardEventHandler } from 'react';
import { Account } from '../../../modules/auth/types';
import { TopNavTypeEnum } from './types';

export type AccessibilityProps = {
  tabIndex?: number;
  role?: AriaRole;
  onKeyDown?: KeyboardEventHandler<any>;
};
export type AccessibilityActions = {
  onClick?: () => void,
  onTab?: (forward: boolean) => void,
  onEsc?: () => void,
};
export const getClickAccessibilityProps = (actions: AccessibilityActions, addIndex?: boolean, role?: AriaRole): AccessibilityProps => {
  const elementProps: AccessibilityProps = {
    role: role || 'button',
    onKeyDown: (e) => {
      switch (e.key) {
        case 'Enter':
        case ' ':
        case 'Spacebar':
          if (actions.onClick) {
            actions.onClick();
          }
          break;
        case 'Escape':
          if (actions.onEsc) {
            actions.onEsc();
          }
          break;
        case 'Tab':
          if (actions.onTab) {
            actions.onTab(!e.shiftKey);
          }
          break;
      }
    },
  };
  if (addIndex) {
    elementProps.tabIndex = 0;
  }
  return elementProps;
};

/**
 * Positions define tab action, if an item is first, then
 * shift+tab should have a special action, if it's the last,
 * then tab should have a special action
 */
export enum MenuItemPositions {
  FIRST,
  LAST,
}

export const SEARCH_ICON_ON_TAB_BAR = 'search_icon_on_tab_bar';

export const getTopNavType = (pathname: string, account?: Account): TopNavTypeEnum => {
  const pathnameLowerCase = pathname.toLowerCase();
  // Homepage
  if (pathnameLowerCase === '/' || pathnameLowerCase.startsWith('/search')) {
    return 'WithoutSearchInNav';
  }
  if (pathnameLowerCase.startsWith('/events/')) {
    return 'TicketSeatSelection';
  }
  if (pathnameLowerCase.startsWith('/help') && !account) {
    return 'SignedOut';
  }
  return 'SearchInNav';
};
