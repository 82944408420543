import React from 'react';
import cx from 'classnames';

import Button from '../../atoms/Button';
import Text from '../../atoms/Text';
import Image from '../../atoms/Image';

import styles from './RecentlyViewedContent.module.scss';
import usePresenter from './RecentlyViewedContent.presenter';
import getProps from './RecentlyViewedContent.props';
import { RecentlyViewedContentCombinedProps } from './types';
import { Link } from 'react-router-dom';


const RecentlyViewedContent: React.FC<RecentlyViewedContentCombinedProps> = (props) => {
  const {
    className,
    classes,
    image,
    title,
    date,
    time,
    location,
    button,
    // MANUAL OVERRIDE STARTS
    linkPath,
    // MANUAL OVERRIDE ENDS
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.recentlyViewedContent;

  // MANUAL OVERRIDE STARTS
  const imageView = (
    <Image
      className={cx(styles.image, classes?.image)}
      {...internalProps.image}
      {...image}/>
  );

  const contentView = (
    <div className={cx(styles.content, classes?.content)}>
      <Text
        className={cx(styles.title, classes?.title)}
        {...internalProps.title}
        {...title}/>
      <div className={cx(styles.eventLogistics, classes?.eventLogistics)}>
        <div className={cx(styles.dateAndTime, classes?.dateAndTime)}>
          <Text
            className={cx(styles.date, classes?.date)}
            {...internalProps.date}
            {...date}/>
          <Text
            className={cx(styles.time, classes?.time)}
            {...internalProps.time}
            {...time}/>
        </div>
        <Text
          className={cx(styles.location, classes?.location)}
          {...internalProps.location}
          {...location}/>
      </div>
    </div>
  );

  const linkContentView = linkPath ? (
    <Link
      to={{ pathname: linkPath, state: { canGoBack: true } }}
      className={styles.linkContent}
    >
      {imageView}
      <div className={cx(styles.rightContent, classes?.rightContent)}>
        {contentView}
      </div>
    </Link>
  ) : contentView;

  return (
    <div className={cx(currentStyle, className)}>
      {linkContentView}
      <Button
        className={cx(styles.button, classes?.button)}
        {...internalProps.button}
        {...button}/>
    </div>
  );
  // MANUAL OVERRIDE ENDS
};

export default RecentlyViewedContent;

