import { FilterProps } from '../Filter';


type FilterListInternalProps = {
  filter?: FilterProps;
};

type FilterListPropsValues = {
  '': FilterListInternalProps;
};

const propValues: FilterListPropsValues = {
  '': {
    filter: {
      type: 'IconFilter',
    },
  },
};

const getProps = (type: string): FilterListInternalProps => {
  const values: FilterListInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
