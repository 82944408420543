import React from 'react';
import cx from 'classnames';

import TicketTypeSelectionBlock from '../../blocks/TicketTypeSelectionBlock';

import styles from './TicketSelectionPage.module.scss';
import usePresenter from './TicketSelectionPage.presenter';
import getProps from './TicketSelectionPage.props';
import { TicketSelectionPageCombinedProps } from './types';
import ErrorHandler from '../../../modules/error/ErrorHandler';
import Spinner from '../../atoms/Spinner';


const TicketSelectionPage: React.FC<TicketSelectionPageCombinedProps> = (props) => {
  const {
    className,
    classes,
    ticketTypeSelectionBlock,
    // MANUAL OVERRIDE STARTS
    apiError,
    isLoading,
    // MANUAL OVERRIDE ENDS
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.ticketSelectionPage;

  // MANUAL OVERRIDE STARTS
  if (apiError) {
    return <ErrorHandler error={apiError} />;
  }
  if (isLoading) {
    return (
      <div className={cx(styles.ticketSelectionPage, className)}>
        <Spinner></Spinner>
      </div>
    );
  }
  // MANUAL OVERRIDE ENDS

  return (
    <div className={cx(currentStyle, className)}>
      <div className={cx(styles.container, classes?.container)}>
        <TicketTypeSelectionBlock
          className={cx(styles.ticketTypeSelectionBlock, classes?.ticketTypeSelectionBlock)}
          {...internalProps.ticketTypeSelectionBlock}
          {...ticketTypeSelectionBlock}/>
      </div>
    </div>
  );
};

export default TicketSelectionPage;

