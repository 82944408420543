import React, { useContext } from 'react';
import { useMutation } from 'react-query';
import { AuthContext } from '../../../modules/auth/AuthContext';
import { getEvents } from '../../../modules/partnership/api';
import { RegionContext } from '../../../modules/region';
import { EventListingBlockCombinedProps } from './types';

const useInteractor = (
  props: EventListingBlockCombinedProps,
): EventListingBlockCombinedProps => {
  const { mutateAsync, isLoading } = useMutation(getEvents);
  const { currentRegion, loading: regionLoading } = useContext(RegionContext);
  const { account, loading: accountLoading } = useContext(AuthContext);

  return {
    ...props,
    fetchEvents: mutateAsync,
    isLoading,
    accountLoading,
    regionLoading,
    currentRegion,
    account,
  };
};
export default useInteractor;
