import { ButtonProps } from '../../atoms/Button';
import { TextProps } from '../../atoms/Text';
import { ImageProps } from '../../atoms/Image';


type RecentlyViewedContentInternalProps = {
  image?: ImageProps;
  title?: TextProps;
  date?: TextProps;
  time?: TextProps;
  location?: TextProps;
  button?: ButtonProps;
};

type RecentlyViewedContentPropsValues = {
  '': RecentlyViewedContentInternalProps;
};

const propValues: RecentlyViewedContentPropsValues = {
  '': {
    image: {
    },
    title: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'SubduedDark',
      align: 'Left',
    },
    date: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'SubduedDark',
      align: 'Left',
    },
    time: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'SubduedLight',
      align: 'Left',
    },
    location: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'SubduedLight',
      align: 'Left',
    },
    button: {
      type: 'Icon',
      style: 'Text',
      size: 'Small',
    },
  },
};

const getProps = (type: string): RecentlyViewedContentInternalProps => {
  const values: RecentlyViewedContentInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
