import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import { TERMS_MODAL } from '../../../lib/constants';
import { addQueryParam } from '../../../lib/util';

import { ButtonCombinedProps } from '../../atoms/Button';

import { FooterCombinedProps } from './types';
import { PRIVACY_URL } from '../../../lib/config';

const usePresenter = (props: FooterCombinedProps): FooterCombinedProps => {
  const { t } = useTranslation();
  const history = useHistory();

  const renderModal = (modalType: string) => {
    const params = addQueryParam(history.location.search, {
      'modal': modalType,
    });
    
    if (params) {
      history.push({
        search: params,
      });
    }
  };

  const buttons: ButtonCombinedProps[] = [];
  buttons.push({
    text: { 
      value: t('footer.help'),
    },
    type: 'Text',
    href: '/help',
  });
  buttons.push({
    text: {
      value: t('footer.terms'),
    },
    type: 'Text',
    onClick: () => { renderModal(TERMS_MODAL); },
  });
  buttons.push({
    text: {
      value: t('footer.privacy'),
    },
    type: 'Text',
    href: PRIVACY_URL,
    target: '_blank',
  });

  return {
    ...props,
    footerMenuList: {
      buttons,
    },
  };
};

export default usePresenter;