import { useContext, useEffect } from 'react';
import { useQuery, useMutation } from 'react-query';
import { useLocation, useParams } from 'react-router-dom';

import { CheckoutParams } from '../../../legacy/pages/CheckoutManualPage/utils';

import { useAnalyticsManager } from '../../../modules/analytics/useAnalyticsManager';
import { AuthContext } from '../../../modules/auth';
import { getListingDetails, getMyOrders, createOrder } from '../../../modules/partnership/api';

import { CheckoutManualPageCombinedProps, CheckoutManualPagePresenterProps, LocationState } from './types';

const useInteractor = (props: CheckoutManualPageCombinedProps): CheckoutManualPagePresenterProps => {
  const { account, refetchAccount, setAccountExternally, loading } = useContext(AuthContext);
  const location = useLocation<LocationState>();
  const query = new URLSearchParams(location.search);
  const step: number = +(query.get('step') || 1);
  const eventId: string | null = query.get('eventId');
  const quantity: number = +(query.get('quantity') || 1);
  const exclusiveListings: boolean = query.get('exclusive_listings') === 'true' || false;
  const deliveryId: number = +(query.get('delivery_id') || 0);
  const { ticketId } = useParams<CheckoutParams>();
  const { trackEvent } = useAnalyticsManager();

  useEffect(() => {
    trackEvent('begin_checkout');
  }, []);

  const { error, isLoading, data } = useQuery(
    ['getListing', eventId, ticketId, quantity],
    () => getListingDetails((eventId || ''), ticketId, { quantity, exclusive_listings: exclusiveListings, delivery_id: deliveryId }),
  );
  const { error: userOrdersError, mutateAsync: fetchOrders } = useMutation(getMyOrders);
  const { error: orderError, mutateAsync } = useMutation(createOrder);
  return {
    ...props,
    isLoading: isLoading || loading,
    account: account,
    step: step,
    ticketInfo: data,
    quantity: quantity,
    fetchOrders: fetchOrders,
    refetchAccount: refetchAccount,
    setAccountExternally: setAccountExternally,
    error: error as Error || userOrdersError as Error || userOrdersError as undefined,
    orderApiError: orderError as Error || undefined,
    createOrder: mutateAsync,
  };
};

export default useInteractor;
