import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CURRENT_LOCATION_FALLBACK_VALUE, DEFAULT_PER_PAGE } from '../../../lib/constants';
import { useAnalyticsManager } from '../../../modules/analytics/useAnalyticsManager';
import MLBBannarAsset from '../../../resources/images/MLBBannar.png';
import CX1Bannar1Asset from '../../../resources/images/C1XBannar1.png';

import { PerformerCategoryEnum, DEFAULT_PAGE, MUSIC_PERFORMERS_SECTION_PAGE_SIZE, DEFAULT_PAGE_SIZE } from './constants';
import useInteractor from './Homepage.interactor';
import { HomepageCombinedProps } from './types';

const usePresenter = (props: HomepageCombinedProps): HomepageCombinedProps => {
  const { currentRegion, locationList } = useInteractor(props);
  const { t } = useTranslation();

  const [regionId, setRegionId] = useState<number | undefined>(undefined);

  const { trackEvent } = useAnalyticsManager();

  return {
    ...props,
    primaryBannerBlock: {
      primaryBanner: {
        title: {
          value: 'Access cardholder-exclusive tickets plus thousands of additional events.',
        },
        message: {
          value: 'Use your rewards, card or both to enjoy sports, music, dining and more.',
        },
      },
    },
    exclusiveEvents: {
      showExclusiveEvents: true,
      defaultParams: {
        per_page: 4,
      },
      ignoreParams: ['region_id', 'date_end', 'date_start'],
      minimumDisplayCount: 3,
      blockHeader: {
        text: {
          value: 'Cardholder Exclusives',
        },
        button: {
          text: {
            colour: 'BaseLight',
            value: t('homepage.viewAll'),
          },
          onClick: () => {
            trackEvent('select_content_view_all_c1x');
          },
          href: '/exclusive-events',
        },
      },
    },
    mLBBanner: {
      secondaryBanner: {
        imageSrc: MLBBannarAsset,
        title: {
          value: t('mlbBanner.title'),
        },
        message: {
          value: t('mlbBanner.message'),
        },
        button: {
          text: {
            type: 'Body',
            size: 'Large',
            style: 'SemiBold',
            colour: 'ActionBase',
            align: 'Center',
            value: t('mlbBanner.button'),
          },
          href: '/mlb',
        },
      },
    },
    filtersBlock: {
      filterOne: {
        currentRegion,
        ariaLabel: t('regionFilters.locationFilterReaderText'),
        showUseMyLocation: true,
        defaultOption: {
          value: (currentRegion?.displayName ?? currentRegion?.city) || t('shopByWidget.anyLocation'),
          id: currentRegion ? String(currentRegion?.id) : CURRENT_LOCATION_FALLBACK_VALUE,
        },
        locationList,
        type: 'SearchLocationFilter',
        setOptionFilterId: (id) => setRegionId(id as number), 
      },
      filterTwo: {
        defaultDateOption: {
          title: t('dateFilters.any'),
          type: 'Default',
        },
        type: 'DateFilter',
        ariaLabel: t('dateFilters.dateFilterReaderText'),
      },
    },
    highlightedEvents: {
      defaultParams: {
        per_page: DEFAULT_PER_PAGE,
        region_id: regionId || currentRegion?.id,
      },
      ignoreParams: ['tag_filter'],
      blockHeader: {
        text: {
          value: 'Events near you',
        },
        tooltip: {
          text: t('eventCategory.tooltip.cardholderExclusive'),
          placement: 'top',
          ariaLabel: t('blockHeaderTooltip.highlightEvents'),
          toolTipId: 'nearby-events-tooltip',
          toolTipAccessibilityContent: t('eventCategory.tooltip.cardholderExclusive'),
        },
      },
    },
    musicEvents: {
      defaultParams: {
        categoryId: PerformerCategoryEnum.MUSIC,
        page: DEFAULT_PAGE,
        pageSize: MUSIC_PERFORMERS_SECTION_PAGE_SIZE,
      },
      ignoreParams: ['region_id', 'date_end', 'date_start'],
      blockHeader: {
        text: {
          value: t('eventCategory.music'),
        },
        tooltip: {
          text: t('eventCategory.tooltip.cardholderExclusive'),
          placement: 'top',
          ariaLabel: t('blockHeaderTooltip.musicEvents'),
          toolTipId: 'music-events-tooltip',
          toolTipAccessibilityContent: t('eventCategory.tooltip.cardholderExclusive'),
        },
      },
    },
    sportsEvents: {
      defaultParams: {
        categoryId: PerformerCategoryEnum.SPORTS,
        page: DEFAULT_PAGE,
        pageSize: DEFAULT_PAGE_SIZE,
      },
      ignoreParams: ['region_id', 'date_end', 'date_start'],
      blockHeader: {
        text: {
          value: t('eventCategory.sports'),
        },
        tooltip: {
          text: t('eventCategory.tooltip.cardholderExclusive'),
          placement: 'top',
          ariaLabel: t('blockHeaderTooltip.sportEvents'),
          toolTipId: 'sports-events-tooltip',
          toolTipAccessibilityContent: t('eventCategory.tooltip.cardholderExclusive'),
        },
      },
    },
    comedyTheaterEvents: {
      defaultParams: {
        categoryId: PerformerCategoryEnum.COMEDYTHEATRE,
        page: DEFAULT_PAGE,
        pageSize: DEFAULT_PAGE_SIZE,
      },
      ignoreParams: ['region_id', 'date_end', 'date_start'],
      blockHeader: {
        text: {
          value: t('eventCategory.comedyAndTheatre'),
        },
        tooltip: {
          text: t('eventCategory.tooltip.cardholderExclusive'),
          placement: 'top',
          ariaLabel: t('blockHeaderTooltip.comedyAndTheaterEvents'),
          toolTipId: 'comedy-and-theater-events-tooltip',
          toolTipAccessibilityContent: t('eventCategory.tooltip.cardholderExclusive'),
        },
      },
    },
    secondaryBannerLightBlock: {
      secondaryBanner: {
        imageSrc: CX1Bannar1Asset,
        title: {
          value: 'Enjoy cardholder-only experiences you\'ll love',
        },
        message: {
          value: 'Use your rewards, card or both to access premier events and reserved tickets just for cardholders.',
        },
        button: {
          text: {
            type: 'Body',
            size: 'Large',
            style: 'SemiBold',
            colour: 'ActionBase',
            align: 'Center',
            value: 'Explore exclusives',
          },
          onClick: () => {
            trackEvent('select_content_c1x_banner');
            window.location.href = '/exclusive-events';
          },
        },
      },
    },
  };
};

export default usePresenter;
