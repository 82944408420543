import React from 'react';
import cx from 'classnames';

import RecentlyViewedContent from '../../molecules/RecentlyViewedContent';

import styles from './RecentlyViewedList.module.scss';
import usePresenter from './RecentlyViewedList.presenter';
import getProps from './RecentlyViewedList.props';
import { RecentlyViewedListCombinedProps } from './types';


const RecentlyViewedList: React.FC<RecentlyViewedListCombinedProps> = (props) => {
  const {
    className,
    classes,
    recentlyViewedContents,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.recentlyViewedList;

  const recentlyViewedContentViews = recentlyViewedContents?.map((recentlyViewedContent, index) => (
    <RecentlyViewedContent
      key={index}
      className={cx(styles.recentlyViewedContent, classes?.recentlyViewedContent)}
      {...internalProps.recentlyViewedContent}
      {...recentlyViewedContent} />
  )) || [];

  return (
    <div className={cx(currentStyle, className)}>
      {recentlyViewedContentViews}
    </div>
  );
};

export default RecentlyViewedList;

