import React from 'react';
import cx from 'classnames';

import Text from '../../atoms/Text';
import Tag from '../Tag';

import styles from './ExclusiveEventInfo.module.scss';
import usePresenter from './ExclusiveEventInfo.presenter';
import getProps from './ExclusiveEventInfo.props';
import { ExclusiveEventInfoCombinedProps } from './types';


const ExclusiveEventInfo: React.FC<ExclusiveEventInfoCombinedProps> = (props) => {
  const {
    className,
    classes,
    tag,
    title,
    eventInfo,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.exclusiveEventInfo;

  return (
    <div className={cx(currentStyle, className)}>
      <Tag
        className={cx(styles.tag, classes?.tag)}
        {...internalProps.tag}
        {...tag}/>
      <div className={cx(styles.content, classes?.content)}>
        <Text
          className={cx(styles.title, classes?.title)}
          {...internalProps.title}
          {...title}/>
        <Text
          className={cx(styles.eventInfo, classes?.eventInfo)}
          {...internalProps.eventInfo}
          {...eventInfo}/>
      </div>
    </div>
  );
};

export default ExclusiveEventInfo;

