import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import { CANADA_PROVINCE_CODES, COUNTRIES, US_STATE_CODES } from '../../../lib/constants';
import { AriaLiveRegions, AriaRoles } from '../../../lib/types';
import { getFiveDigitsZipCode, getTwoDigitsCountryCode, handleCountryCodeUS, removeWhiteSpacesFromString } from '../../../lib/util';
import { ShippingInfoBlockCombinedProps } from './types';
import { OrderAddressState, getCountryCodeFromState, requiredFields, validateOrderAddressField, validatePostalCodeAgainstPOBoxZipCodeList } from './utils';
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../../modules/auth';
import { OrderAddress } from '../../../modules/partnership';
import { CheckBoxItemStateEnum } from '../../atoms/CheckBoxItem/types';
import { DropdownItem } from '../../atoms/DropdownSelect/types';

import customStyles from './Custom.module.scss';
import styles from './ShippingInfoBlock.module.scss';

const usePresenter = (props: ShippingInfoBlockCombinedProps): ShippingInfoBlockCombinedProps => {
  const { account } = useContext(AuthContext);
  const { formSubmitted, classes, className } = props;
  const { t } = useTranslation();
  const [billingState, setBillingState] = useState<boolean>(false);

  const [countryState, setCountryState] = useState<DropdownItem>();
  const [stateState, setStateState] = useState<DropdownItem>();
  const [isFormSubmitted, setIsFormSubmitted] = useState<boolean>(false);
  const [shippingDetails, setShippingDetails] = useState<OrderAddress>({
    first_name: '',
    last_name: '',
    address_line_1: '',
    address_line_2: '',
    city: '',
    postal_code: '',
    phone_number: '',
    region: '',
    country: '',
  });
  const [addressFieldsStates, setAddressFieldsStates] =
    useState<OrderAddressState>({} as OrderAddressState);
  const [totalErrors, setTotalErrors] = useState(0);
  const [showInvalidZipAgainstPOBoxError, setShowInvalidZipAgainstPOBoxError] = useState(false);
  let billingAddress: OrderAddress | undefined;
  const validatedCountryCode = getTwoDigitsCountryCode(
    account?.address.country_code || '',
  );
  if (account) {
    billingAddress = {
      first_name: account?.first_name,
      last_name: account?.last_name,
      address_line_1: account?.address.address_line1,
      address_line_2: account?.address.address_line2,
      city: account?.address.city,
      postal_code: account
        ? getFiveDigitsZipCode(
          account.address.country_code,
          account.address.postal_code,
        )
        : '',
      phone_number:
        `${props?.phoneNumber?.textInput?.textValue ||
        removeWhiteSpacesFromString(account?.phone)}`,
      region: account?.address.state_code,
      country: validatedCountryCode,
    };
  }

  const validateAllAddressFields = () => {
    const addressErrors = {} as OrderAddressState;
    requiredFields.forEach((field) => {
      if (
        !validateOrderAddressField(
          field,
          shippingDetails[field] as string,
          shippingDetails,
        )
      ) {
        addressErrors[field] = 'Error';
      }
    });
    const errors = Object.entries(addressErrors).length;
    setAddressFieldsStates(addressErrors);
    setTotalErrors(errors);
    return errors;
  };

  useEffect(() => {
    if (isFormSubmitted) {
      validateAllAddressFields();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shippingDetails]);
  //eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
  const handleChange = <T extends unknown>(key: string, value: T) => {
    setShippingDetails({
      ...shippingDetails,
      [key]: value,
    });
  };

  const handleInputChanged = (key: keyof OrderAddress) => (e) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    handleChange<string>(key, e.target.value);
    setBillingState(false);
  };

  const canadianProvinces: DropdownItem[] = [
    {
      label: t('billingInfo.enterState'),
      value: '',
    },
  ].concat(
    CANADA_PROVINCE_CODES.map((country) => {
      return {
        value: country.code,
        label: country.code,
        ariaLabel: t('shopByWidget.selectedOptionAnnouncement', {
          option: country.code,
        }),
      };
    }),
  );
  const usStates: DropdownItem[] = [
    {
      value: '',
      label: t('billingInfo.enterState'),
    },
  ].concat(
    US_STATE_CODES.map((country) => {
      return {
        value: country.code,
        label: country.code,
        ariaLabel: t('shopByWidget.selectedOptionAnnouncement', {
          option: country.code,
        }),
      };
    }),
  );
  const allStates: DropdownItem[] = canadianProvinces.concat(
    US_STATE_CODES.map((country) => {
      return {
        value: country.code,
        label: country.code,
        ariaLabel: t('shopByWidget.selectedOptionAnnouncement', {
          option: country.code,
        }),
      };
    }),
  );
  const getCorrectProvinces = (code: string | undefined) => {
    switch ((code || '').toLowerCase()) {
      case 'us':
        return usStates;
      case 'ca':
        return canadianProvinces;
      default:
        return allStates;
    }
  };
  const [stateOptions, setStateOptions] = useState<DropdownItem[]>(
    getCorrectProvinces(''),
  );
  return {
    ...props,
    className: cx(className, customStyles.customShippingInfoBlock),
    classes: {
      firstName: cx(classes?.firstName, customStyles.customTextField),
      lastName: cx(classes?.lastName, customStyles.customTextField),
      address: cx(classes?.address, customStyles.customTextField),
      secondaryAddress: cx(classes?.secondaryAddress, customStyles.customTextField),
      city: cx(classes?.city, customStyles.customTextField),
      postalCode: cx(classes?.postalCode, customStyles.customTextField),
      phoneNumber: cx(classes?.phoneNumber, customStyles.customTextField),
    },
    checkBoxItem: {
      text: {
        value: t('billingInfo.checkbox'),
      },
      state: billingState ? 'Selected' : 'Unselected',
      onCheckBoxItemClicked: (state?: CheckBoxItemStateEnum) => {
        setStateState({ value: account?.address.state_code || '' });
        setCountryState({ value: validatedCountryCode });
        setStateOptions(
          getCorrectProvinces(
            getTwoDigitsCountryCode(account?.address.country_code || ''),
          ),
        );
        setShippingDetails({
          ...shippingDetails,
          ...billingAddress,
        });
        setBillingState(state === 'Selected');
      },
    },
    blockTitle: {
      value: t('billingInfo.shippingTitle'),
    },
    firstName: {
      state: addressFieldsStates.first_name || 'Default',
      ariaLive: AriaLiveRegions.ASSERTIVE,
      label: {
        value: t('billingInfo.firstName'),
      },
      textInput: {
        textPlaceholder: t('billingInfo.enterFirstName'),
        state: 'Empty',
        onTextChanged: handleInputChanged('first_name'),
        textValue: shippingDetails.first_name,
      },
      error: {
        value: t('billingInfo.fieldErrorMessage', {
          field: t('billingInfo.firstName'),
        }),
        ariaRole: AriaRoles.ALERT,
        ariaAtomic: true,
      },
    },
    lastName: {
      state: addressFieldsStates.last_name || 'Default',
      ariaLive: AriaLiveRegions.ASSERTIVE,
      label: {
        value: t('billingInfo.lastName'),
      },
      textInput: {
        textPlaceholder: t('billingInfo.enterLastName'),
        state: 'Empty',
        onTextChanged: handleInputChanged('last_name'),
        textValue: shippingDetails.last_name,
      },
      error: {
        value: t('billingInfo.fieldErrorMessage', {
          field: t('billingInfo.lastName'),
        }),
        ariaRole: AriaRoles.ALERT,
        ariaAtomic: true,
      },
    },
    address: {
      state: addressFieldsStates.address_line_1 || 'Default',
      ariaLive: AriaLiveRegions.ASSERTIVE,
      label: {
        value: t('billingInfo.address'),
      },
      textInput: {
        textPlaceholder: t('billingInfo.enterAddress'),
        state: 'Empty',
        onTextChanged: handleInputChanged('address_line_1'),
        textValue: shippingDetails.address_line_1,
      },
      error: {
        value: t('billingInfo.fieldErrorMessage', {
          field: t('billingInfo.address'),
        }),
        ariaRole: AriaRoles.ALERT,
        ariaAtomic: true,
      },
    },
    secondaryAddress: {
      label: {
        value: t('billingInfo.secondaryAddress'),
      },
      textInput: {
        state: 'Empty',
        onTextChanged: handleInputChanged('address_line_2'),
        textValue: shippingDetails.address_line_2 || undefined,
      },
    },
    city: {
      state: addressFieldsStates.city || 'Default',
      ariaLive: AriaLiveRegions.ASSERTIVE,
      label: {
        value: t('billingInfo.city'),
      },
      textInput: {
        textPlaceholder: t('billingInfo.enterCity') || undefined,
        state: 'Empty',
        onTextChanged: handleInputChanged('city'),
        textValue: shippingDetails.city,
        maxLength: 35,
        removeChar: /[^a-zA-Z ]/g,
      },
      error: {
        value: t('billingInfo.fieldErrorMessage', {
          field: t('billingInfo.city'),
        }),
        ariaRole: AriaRoles.ALERT,
        ariaAtomic: true,
      },
    },
    state: {
      className: cx(styles.state, customStyles.customDropdown),
      classes: {
        dropdownSelect: addressFieldsStates.country === 'Error'
          ? customStyles.customDropdownSelectError
          : customStyles.customDropdownSelect,
      },
      state: addressFieldsStates.region || 'Default',
      ariaLive: AriaLiveRegions.ASSERTIVE,
      label: {
        value: t('billingInfo.state'),
      },
      dropdownSelect: {
        onDropdownSelectClicked: (stateCode) => {
          const addressDetails = { ...shippingDetails };
          addressDetails.region = stateCode || '';
          if (stateCode) {
            const country = getCountryCodeFromState(stateCode);
            setCountryState({ value: country });
            addressDetails.country = country;
          }
          setShippingDetails(addressDetails);
          setStateState({ value: stateCode || '' });
          setBillingState(false);
        },
        selection: stateState,
        dropdownItems: stateOptions,
        text: {
          value: billingState ? account?.address.address_line1 : undefined,
        },
      },
      error: {
        value: t('billingInfo.fieldErrorMessage', {
          field: t('billingInfo.state'),
        }),
        ariaRole: AriaRoles.ALERT,
        ariaAtomic: true,
      },
    },
    postalCode: {
      state: addressFieldsStates.postal_code || 'Default',
      ariaLive: AriaLiveRegions.ASSERTIVE,
      label: {
        value: t('billingInfo.postalCode'),
      },
      textInput: {
        textPlaceholder: t('billingInfo.enterPostalCode') || undefined,
        state: 'Empty',
        onTextChanged: handleInputChanged('postal_code'),
        textValue: shippingDetails.postal_code,
        maxLength: handleCountryCodeUS(shippingDetails.country) ? 5 : 6,
        removeChar: /[^a-zA-Z0-9]/g,
      },
      error: {
        value: t('billingInfo.fieldErrorMessage', {
          field: t('billingInfo.postalCode'),
        }),
        ariaRole: AriaRoles.ALERT,
        ariaAtomic: true,
      },
    },
    country: {
      className: cx(styles.country, customStyles.customDropdown),
      classes: {
        dropdownSelect: addressFieldsStates.country === 'Error'
          ? customStyles.customDropdownSelectError
          : customStyles.customDropdownSelect,
      },
      state: addressFieldsStates.country || 'Default',
      ariaLive: AriaLiveRegions.ASSERTIVE,
      label: {
        value: t('billingInfo.country'),
      },
      dropdownSelect: {
        onDropdownSelectClicked: (countryCode) => {
          const addressDetails = { ...shippingDetails };
          addressDetails.country = countryCode || '';
          setCountryState({ value: countryCode || '' });
          setStateOptions(getCorrectProvinces(countryCode));

          // To change state only when a country is selected
          if (countryCode) {
            // Every time country changes the state options changes and selection changes to blank string, this is to update stateState and shippingDetails
            addressDetails.region = '';
            setStateState({ value: '' });
          }

          setShippingDetails(addressDetails);
          setBillingState(false);
        },
        selection: countryState,
        text: {
          value: billingState
            ? getTwoDigitsCountryCode(account?.address.country_code || '')
            : undefined,
        },
        dropdownItems: [
          {
            label: t('billingInfo.enterCountry'),
            value: '',
          },
        ].concat(
          COUNTRIES.map((country) => {
            return {
              value: country.code,
              label: country.code,
              ariaLabel: t('shopByWidget.selectedOptionAnnouncement', {
                option: country.code,
              }),
            };
          }),
        ),
      },
      error: {
        value: t('billingInfo.fieldErrorMessage', {
          field: t('billingInfo.country'),
        }),
        ariaRole: AriaRoles.ALERT,
        ariaAtomic: true,
      },
    },
    phoneNumber: {
      state: addressFieldsStates.phone_number || 'Default',
      ariaLive: AriaLiveRegions.ASSERTIVE,
      label: {
        value: t('billingInfo.phoneNumber'),
      },
      textInput: {
        textPlaceholder: t('billingInfo.enterPhoneNumber') || undefined,
        state: 'Empty',
        onTextChanged: handleInputChanged('phone_number'),
        textValue: shippingDetails.phone_number,
        maxLength: 10,
        removeChar: /[^0-9]/g,
      },
      error: {
        value: t('billingInfo.fieldErrorMessage', {
          field: t('billingInfo.phoneNumber'),
        }),
        ariaRole: AriaRoles.ALERT,
        ariaAtomic: true,
      },
    },
    button: {
      text: {
        value: t('billingInfo.button'),
      },
    },
    handleSubmit: (e) => {
      e?.preventDefault();
      setIsFormSubmitted(true);
      const errors = validateAllAddressFields();
      const isValidZipCode = validatePostalCodeAgainstPOBoxZipCodeList(shippingDetails.postal_code);
      setShowInvalidZipAgainstPOBoxError(!isValidZipCode);
      if (errors === 0 && isValidZipCode && formSubmitted) {
        formSubmitted(shippingDetails);
      }
    },
    showError: totalErrors > 0,
    highlightMessage: {
      message: {
        value: t('billingInfo.addressErrorMessage'),
      },
    },
    showInvalidZipAgainstPOBoxCodeListError: showInvalidZipAgainstPOBoxError,
    invalidZipCodeAgainPOBoxCodeListError: {
      message: {
        value: t('billingInfo.invalidZipCodeAgainPOBoxCodeListError'),
      },
    },
  };
};

export default usePresenter;
