import { EventListingBlockProps } from '../../blocks/EventListingBlock';
import { HeroCTABlockProps } from '../../blocks/HeroCTABlock';
import { PrimaryBannerBlockProps } from '../../blocks/PrimaryBannerBlock';
import { SecondaryBannerBlockProps } from '../../blocks/SecondaryBannerBlock';


type ExclusivesEventsLandingPageInternalProps = {
  primaryBannerBlock?: PrimaryBannerBlockProps;
  topBanner?: PrimaryBannerBlockProps;
  heroCTABlock?: HeroCTABlockProps;
  eventListingBlock?: EventListingBlockProps;
  secondaryBannerBlock?: SecondaryBannerBlockProps;
};

type ExclusivesEventsLandingPagePropsValues = {
  '': ExclusivesEventsLandingPageInternalProps;
};

const propValues: ExclusivesEventsLandingPagePropsValues = {
  '': {
    primaryBannerBlock: {
    },
    topBanner: {
    },
    heroCTABlock: {
    },
    eventListingBlock: {
      state: 'Filled',
    },
    secondaryBannerBlock: {
    },
  },
};

const getProps = (type: string): ExclusivesEventsLandingPageInternalProps => {
  const values: ExclusivesEventsLandingPageInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
