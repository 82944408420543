import { TextProps } from '../../atoms/Text';
import { FilterListProps } from '../../molecules/FilterList';
import { FilterProps } from '../../molecules/Filter';
import { TabListProps } from '../../molecules/TabList';


type FilterGroupInternalProps = {
  text?: TextProps;
  titleFilter?: FilterProps;
  filterList?: FilterListProps;
  tabList?: TabListProps;
  otherFilter?: FilterProps;
};

type FilterGroupPropsValues = {
  'TitleTwoPairs': FilterGroupInternalProps;
  'TwoPairsOtherFilter': FilterGroupInternalProps;
};

const propValues: FilterGroupPropsValues = {
  'TitleTwoPairs': {
    text: {
      type: 'Subheading',
      size: 'Medium',
      style: 'Regular',
      colour: 'SubduedDark',
      align: 'Left',
    },
    titleFilter: {
      type: 'TextIconFilter',
    },
    filterList: {
    },
  },
  'TwoPairsOtherFilter': {
    titleFilter: {
      type: 'TextIconFilter',
    },
    tabList: {
    },
    filterList: {
    },
    otherFilter: {
      type: 'TextIconFilter',
    },
  },
};

const getProps = (type: string): FilterGroupInternalProps => {
  const values: FilterGroupInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
