import { useTranslation } from 'react-i18next';
import useInteractor from './VenueLandingPage.interactor';
import { VenueLandingPageCombinedProps } from './types';
import { useEffect } from 'react';
import { dateFilterOptions } from '../../molecules/Filter/utils';

const usePresenter = (props: VenueLandingPageCombinedProps): VenueLandingPageCombinedProps => {
  const { venue, venueId, categories, fetchVenue } = useInteractor(props);
  const { t } = useTranslation();
  useEffect(() => {
    if (fetchVenue && venueId) {
      void fetchVenue(venueId);
    }
  }, [venueId, fetchVenue]);

  return {
    ...props,
    setTopDisclaimer: () => undefined,
    landingPageBannerBlock: {
      landingPageBanner: {
        image: {
          imageSrc: venue?.image,
        },
        text: {
          value: venue?.name,
        },
      },
    },
    venueEvents: {
      cardType: 'Default',
      defaultParams: { venue_id: Number(venueId) },
      ignoreParams: ['region_id', 'per_page'],
      filterGroup: {
        titleValue: t('shopByWidget.shopBy'),
        categoryName: 'category',
        categories: categories,
        filters: [
          {
            type: 'IconFilter',
            filterType: 'DateFilter',
            options: dateFilterOptions,
            filterIcon:'Calendar',
          },
        ],
      },
    },
  };
};

export default usePresenter;
