import React from 'react';
import { Modal } from 'react-bootstrap';
import styles from './TermsAndPrivacy.module.scss';
import { TermsAndPrivacyProps } from './types';

const TermsAndPrivacy: React.FC<TermsAndPrivacyProps> = ({
  modalBodyContent = '',
  handleClose,
  show,
}) => {

  return (
    <Modal
      className={styles.modal}
      dialogClassName={styles.modalDialog}
      contentClassName={styles.modalContent}
      show={show}
      onHide={handleClose}
    >
      <Modal.Header className={styles.modalHeader}
        closeButton
        closeLabel=''>
      </Modal.Header>
      <div dangerouslySetInnerHTML={{ __html: modalBodyContent }}></div>
    </Modal>
  );
};

export default TermsAndPrivacy;