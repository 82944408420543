import { DividerValueProps } from '../../atoms/Divider';
import { LogoProps } from '../../atoms/Logo';
import { FooterMenuListProps } from '../../molecules/FooterMenuList';


type FooterInternalProps = {
  logo?: LogoProps;
  footerMenuList?: FooterMenuListProps;
  divider?: DividerValueProps; // MANUAL OVERRIDE
};

type FooterPropsValues = {
  '': FooterInternalProps;
};

const propValues: FooterPropsValues = {
  '': {
    // MANUAL OVERRIDE STARTS
    divider: {
      type: 'Horizontal',
      style: 'Thin',
      colour: 'DigitalGrey15',
    },
    // MANUAL OVERRIDE ENDS
    logo: {
      asset: 'LogoC1Default',
    },
    footerMenuList: {
    },
  },
};

const getProps = (type: string): FooterInternalProps => {
  const values: FooterInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
