export const QUERY_DATE_FROM = 'date_start';
export const QUERY_DATE_TO = 'date_end';
export const QUERY_HOME_OR_AWAY = 'home_or_away';
export const CURRENT_LOCATION_FALLBACK_VALUE = 'current_location';
export const CHECKOUT_BRAINTREE_FORM = 'checkout-brainTree-form';
export const CHECKOUT_NONE_BRAINTREE_FORM = 'checkout-none-brainTree-form';
export const CHECKOUT_SHIPPING_FORM = 'checkout-shipping-form';
export const TERMS_MODAL = 'terms-modal';
export const CONTENT_ID = 'content';

export const CP1_EXCLUSIVE_TAG = 'C1_EXCLUSIVE';
export const CP1_NOT_EXCLUSIVE_TAG = 'NOT C1_EXCLUSIVE';
export const SOLD_OUT_TAG = 'SOLD_OUT';
export const CASH_ONLY_TAG = 'CREDIT_CARD_PAYMENT';
export const SPLIT_PAY_TAG = 'SPLIT_PAYMENT';
export const REWARDS_ONLY_TAG = 'LOYALTY_REWARDS_PAYMENT';
export const PURCHASE_LIMIT_TAG = 'PURCHASE_LIMIT';
export const C1_EXCLUSIVE_LISTING = 'C1_EXCLUSIVE_LISTING';
export const GUEST_LIST_EVENT = 'GUEST_LIST';
export const ALWAYS_SUPPRESS_VSINVENTORY_TAG = 'ALWAYS_SUPPRESS_VSINVENTORY';
export const C1_NOT_COLLAPSED_TAG = 'NOT COLLAPSED';
export const C1_BROKER_ID = '29014';
export const DELIVERY_REQUIRING_SHIPPING = ['Shipped via UPS', 'Local Pickup'];

export const TAYLOR_SWIFT_PERFORMER_ID = 12245;

// the height for the map + 'Choose ticket' Label + one ticket
export const MIN_MAP_ZOOM_HEIGHT = 432;
export const MOBILE_BREAKPOINT = 1024;

type LocationItem = {
  name: string,
  code: string,
};

export const COUNTRIES: LocationItem[] = [
  { name: 'Canada', code: 'CA' },
  { name: 'United States', code: 'US' },
];
export const CANADA_PROVINCE_CODES: LocationItem[] = [
  { name: 'Alberta', code: 'AB' },
  { name: 'British Columbia', code: 'BC' },
  { name: 'Manitoba', code: 'MB' },
  { name: 'New Brunswick', code: 'NB' },
  { name: 'Newfoundland and Labrador', code: 'NL' },
  { name: 'Northwest Territories', code: 'NT' },
  { name: 'Nova Scotia', code: 'NS' },
  { name: 'Nunavut', code: 'NU' },
  { name: 'Ontario', code: 'ON' },
  { name: 'Prince Edward Island', code: 'PE' },
  { name: 'Quebec', code: 'QC' },
  { name: 'Saskatchewan', code: 'SK' },
  { name: 'Yukon', code: 'YT' },
];
export const US_STATE_CODES: LocationItem[] = [
  { name: 'Alaska', code: 'AK' },
  { name: 'Alabama', code: 'AL' },
  { name: 'Arizona', code: 'AZ' },
  { name: 'Arkansas', code: 'AR' },
  { name: 'California', code: 'CA' },
  { name: 'Colorado', code: 'CO' },
  { name: 'Connecticut', code: 'CT' },
  { name: 'Delaware', code: 'DE' },
  { name: 'District of Columbia', code: 'DC' },
  { name: 'Florida', code: 'FL' },
  { name: 'Georgia', code: 'GA' },
  { name: 'Guam', code: 'GU' },
  { name: 'Hawaii', code: 'HI' },
  { name: 'Idaho', code: 'ID' },
  { name: 'Illinois', code: 'IL' },
  { name: 'Indiana', code: 'IN' },
  { name: 'Iowa', code: 'IA' },
  { name: 'Kansas', code: 'KS' },
  { name: 'Kentucky', code: 'KY' },
  { name: 'Louisiana', code: 'LA' },
  { name: 'Maine', code: 'ME' },
  { name: 'Marshall Islands', code: 'MH' },
  { name: 'Maryland', code: 'MD' },
  { name: 'Massachusetts', code: 'MA' },
  { name: 'Michigan', code: 'MI' },
  { name: 'Minnesota', code: 'MN' },
  { name: 'Mississippi', code: 'MS' },
  { name: 'Missouri', code: 'MO' },
  { name: 'Montana', code: 'MT' },
  { name: 'Nebraska', code: 'NE' },
  { name: 'Nevada', code: 'NV' },
  { name: 'New Hampshire', code: 'NH' },
  { name: 'New Jersey', code: 'NJ' },
  { name: 'New Mexico', code: 'NM' },
  { name: 'New York', code: 'NY' },
  { name: 'North Carolina', code: 'NC' },
  { name: 'North Dakota', code: 'ND' },
  { name: 'Ohio', code: 'OH' },
  { name: 'Oklahoma', code: 'OK' },
  { name: 'Oregon', code: 'OR' },
  { name: 'Palau', code: 'PW' },
  { name: 'Pennsylvania', code: 'PA' },
  { name: 'Puerto Rico', code: 'PR' },
  { name: 'Rhode Island', code: 'RI' },
  { name: 'South Carolina', code: 'SC' },
  { name: 'South Dakota', code: 'SD' },
  { name: 'Tennessee', code: 'TN' },
  { name: 'Texas', code: 'TX' },
  { name: 'Utah', code: 'UT' },
  { name: 'U.S. Virgin Islands', code: 'VI' },
  { name: 'Vermont', code: 'VT' },
  { name: 'Virginia', code: 'VA' },
  { name: 'Washington', code: 'WA' },
  { name: 'West Virginia', code: 'WV' },
  { name: 'Wisconsin', code: 'WI' },
  { name: 'Wyoming', code: 'WY' },
];

export const BREAKPOINTS = {
  mobile: 375,
  tablet: 768,
  desktop: 1024,
  desktopXl: 1440,
};

export const HEADER_HEIGHT = {
  mobile: 48,
  tablet: 64,
  desktop: 64,
  desktopXl: 64,
};

export const DEFAULT_PER_PAGE = 24;
