import { TextProps } from '../../atoms/Text';
import { ImageProps } from '../../atoms/Image';
import { CardInfoHeaderProps } from '../../molecules/CardInfoHeader';
import { ExclusiveEventBannerProps } from '../../molecules/ExclusiveEventBanner';
import { ExclusiveEventInfoProps } from '../../molecules/ExclusiveEventInfo';
import { SelectTicketProps } from '../../organisms/SelectTicket';
import { TicketListProps } from '../../organisms/TicketList';


type TicketTypeSelectionBlockInternalProps = {
  image?: ImageProps;
  exclusiveEventBanner?: ExclusiveEventBannerProps;
  selectTicket?: SelectTicketProps;
  exclusiveEventInfo?: ExclusiveEventInfoProps;
  description?: TextProps;
  cardInfoHeader?: CardInfoHeaderProps;
  ticketList?: TicketListProps;
};

type TicketTypeSelectionBlockPropsValues = {
  '': TicketTypeSelectionBlockInternalProps;
};

const propValues: TicketTypeSelectionBlockPropsValues = {
  '': {
    image: {
    },
    exclusiveEventBanner: {
    },
    selectTicket: {
    },
    exclusiveEventInfo: {
    },
    description: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'SubduedDark',
      align: 'Left',
    },
    cardInfoHeader: {
    },
    ticketList: {
    },
  },
};

const getProps = (type: string): TicketTypeSelectionBlockInternalProps => {
  const values: TicketTypeSelectionBlockInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
