import { ButtonProps } from '../../atoms/Button';
import { ImageProps } from '../../atoms/Image';
import { TextProps } from '../../atoms/Text';
import { SearchResultProps } from '../../molecules/SearchResult';
import { EventsCardListProps } from '../../organisms/EventsCardList';


type SearchMatchesBlockInternalProps = {
  searchResult?: SearchResultProps;
  eventsCardList?: EventsCardListProps;
  seeMoreButton?: ButtonProps;
  image?: ImageProps;
  label?: TextProps;
  searchedValue?: TextProps;
  message?: TextProps;
  button?: ButtonProps;
};

type SearchMatchesBlockPropsValues = {
  'Match': SearchMatchesBlockInternalProps;
  'NoMatch': SearchMatchesBlockInternalProps;
};

const propValues: SearchMatchesBlockPropsValues = {
  'Match': {
    searchResult: {
    },
    eventsCardList: {
    },
    seeMoreButton: {
      type: 'Text',
      style: 'Text',
      size: 'Small',
    },
  },
  'NoMatch': {
    image: {
    },
    label: {
      type: 'Subheading',
      size: 'Large',
      style: 'Light',
      colour: 'SubduedDark',
      align: 'Left',
    },
    searchedValue: {
      type: 'Subheading',
      size: 'Large',
      style: 'Regular',
      colour: 'SubduedDark',
      align: 'Left',
    },
    message: {
      type: 'Body',
      size: 'Large',
      style: 'Regular',
      colour: 'SubduedDark',
      align: 'Center',
    },
    button: {
      type: 'Text',
      style: 'Contained',
      size: 'Large',
    },
  },
};

const getProps = (type: string): SearchMatchesBlockInternalProps => {
  const values: SearchMatchesBlockInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
