import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { PerformerLandingPageCombinedProps } from './types';
import utils from './utils';
import { RegionContext } from '../../../modules/region';

/**
 * Custom hook that constructs the presenter props for the PerformerLandingPage component.
 *
 * @param {PerformerLandingPageCombinedProps} props - Props passed to the presenter.
 * @returns {PerformerLandingPageCombinedProps} - Presenter props with additional computed values.
 */
const usePresenter = (props: PerformerLandingPageCombinedProps): PerformerLandingPageCombinedProps => {
  // Destructure the performerId from the input props.
  const { performerId } = props;

  // Access context values from RegionContext.
  const { allRegions, setPerformerId } = useContext(RegionContext);

  // Call setPerformerId with performerId if it exists in the context.
  useEffect(() => {
    if (setPerformerId) {
      setPerformerId(performerId);
    }
  }, [setPerformerId, performerId]);

  // Initialize the translation function 't' using useTranslation.
  const { t } = useTranslation();

  // Return the result of calling a utility function getPerformerLandingPageProps from 'utils'.
  // This utility function is used to structure and organize the props.
  return utils.getPerformerLandingPageProps(props, t, allRegions);
};

export default usePresenter;
