import React from 'react';
import cx from 'classnames';

import Text from '../../atoms/Text';
import FilterList from '../../molecules/FilterList';
import Filter from '../../molecules/Filter';
import TabList from '../../molecules/TabList';

import styles from './FilterGroup.module.scss';
import usePresenter from './FilterGroup.presenter';
import getProps from './FilterGroup.props';
import { FilterGroupCombinedProps } from './types';


const FilterGroup: React.FC<FilterGroupCombinedProps> = (props) => {
  const {
    type,
    className,
    classes,
    text,
    titleFilter,
    filterList,
    tabList,
    otherFilter,
    // MANUAL OVERRIDE STARTS
    categories,
    otherFilterOptions,
    titleValue,
    // MANUAL OVERRIDE ENDS
  } = usePresenter(props);

  const variantName = `${type}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`filterGroup${variantName}`];

  // MANUAL OVERRIDE STARTS
  const textView = titleValue ? (
    <Text
      className={cx(styles.text, classes?.text)}
      {...internalProps.text}
      {...text}/>
  ) : null;

  const titleFilterView = categories?.length ? (
    <Filter
      className={cx(styles.titleFilter, classes?.titleFilter)}
      {...internalProps.titleFilter}
      {...titleFilter}/>
  ) : null;

  const tabListView = categories?.length ? (
    <TabList
      className={cx(styles.tabList, classes?.tabList)}
      {...internalProps.tabList}
      {...tabList}/>
  ) : null;

  const otherFilterView = otherFilterOptions ? (
    <Filter
      className={cx(styles.otherFilter, classes?.otherFilter)}
      {...internalProps.otherFilter}
      {...otherFilter}/>
  ) : null;

  let componentView;

  switch (variantName) {
    case 'TitleTwoPairs': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          {textView}
          <div className={cx(styles.content, classes?.content)}>
            {titleFilterView}
            {tabListView}
            <FilterList
              className={cx(styles.filterList, classes?.filterList)}
              {...internalProps.filterList}
              {...filterList}/>
          </div>
        </div>
      );
      break;
    }
    case 'TwoPairsOtherFilter': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <div className={cx(styles.content, classes?.content)}>
            {titleFilterView}
            {tabListView}
            <FilterList
              className={cx(styles.filterList, classes?.filterList)}
              {...internalProps.filterList}
              {...filterList}/>
          </div>
          {otherFilterView}
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }
  // MANUAL OVERRIDE ENDS

  return componentView;
};

export default FilterGroup;