import { OptimizelyContext } from '@optimizely/react-sdk';
import { useContext, useMemo } from 'react';
import { EventKeys } from './types';
import TagManager from 'react-gtm-module';
import { EXPERIMENTS } from '../optimizely/constants';
import { AuthContext } from '../auth';

type UseAnalyticsManagerResponse = {
  trackEvent: (event: EventKeys, extraData?: Record<string, unknown>) => void;
};


const getSha1Hash = async (email: string): Promise<string> => {
  // Convert email to lowercase and remove spaces
  const formattedEmail = email.toLowerCase().replace(/\s/g, '');

  // Generate SHA-1 hash
  const encoder = new TextEncoder();
  const data = encoder.encode(formattedEmail);
  const hashBuffer = await crypto.subtle.digest('SHA-1', data);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray.map(byte => byte.toString(16).padStart(2, '0')).join('');

  return hashHex;
};

export const useAnalyticsManager = (): UseAnalyticsManagerResponse => {
  const { optimizely } = useContext(OptimizelyContext);
  const { account } = useContext(AuthContext);
  const accountId = useMemo(() => account?.id, [account?.id]);
  const emailHash = useMemo(() => getSha1Hash(account?.email || ''), [account?.email]);
  const trackOptimizelyEvent = (event: EventKeys) => {
    optimizely?.track(event);
  };

  const trackGTMEvent = (event: EventKeys, extraData?: Record<string, unknown>) => {
    const eventData = {
      event,
      variation: optimizely?.getVariation(EXPERIMENTS.NEW_DESIGN),
      userId: optimizely?.user.id,
      user: {
        user_id: accountId,
        crm_id: emailHash,
      },
      ...extraData,
    };
    TagManager.dataLayer({ dataLayer: eventData });
  };

  const trackEvent = (event: EventKeys, extraData?: Record<string, unknown>) => {
    trackOptimizelyEvent(event);
    trackGTMEvent(event, extraData);
  };

  return {
    trackEvent,
  };
};