import { RecentlyViewedContentProps } from '../../molecules/RecentlyViewedContent';


type RecentlyViewedListInternalProps = {
  recentlyViewedContent?: RecentlyViewedContentProps;
};

type RecentlyViewedListPropsValues = {
  '': RecentlyViewedListInternalProps;
};

const propValues: RecentlyViewedListPropsValues = {
  '': {
    recentlyViewedContent: {
    },
  },
};

const getProps = (type: string): RecentlyViewedListInternalProps => {
  const values: RecentlyViewedListInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
