import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { typedDeepMerge } from '../../../lib/objectUtils';
import { getUnitDisplaySettings, handleAmountFormattingString, handleDecimalValuesForDisplay, handleDisplayUnitName } from '../../../lib/util';
import { AuthContext } from '../../../modules/auth';
import CardAsset from '../../../resources/legacyIcons/CreditCard.svg';

import { DropdownMenuItemCombinedProps } from '../../atoms/DropdownMenuItem/types';
import { AccountMenuBlockCombinedProps } from '../../blocks/AccountMenuBlock/types';

import { AccountMenuCombinedProps } from './types';

export enum UserStatus {
  Collapsed = 'Collapsed',
  Expanded = 'Expanded',
}

const usePresenter = (props: AccountMenuCombinedProps): AccountMenuCombinedProps => {
  const { account, loading } = useContext(AuthContext);
  const history = useHistory();
  const { t } = useTranslation();
  const rewardType = account?.loyalty_program.loyalty_unit_name.toLowerCase();
  const { rewardSign, useDecimals } = getUnitDisplaySettings(rewardType);
  const numberUnits = handleDecimalValuesForDisplay(
    handleAmountFormattingString(account?.loyalty_program?.number_of_units),
    useDecimals,
  );
  const [actionMenuDropDownState, setActionMenuDropDownState] =
      useState<UserStatus>(props.renderType ? UserStatus.Expanded : UserStatus.Collapsed);
  const accountMenuBlock: AccountMenuBlockCombinedProps = {
    isModalOpen: actionMenuDropDownState === UserStatus.Expanded,
    accountMenu: {
      state: actionMenuDropDownState,
      closeButton: {
        onClick: () =>
          setActionMenuDropDownState(UserStatus.Collapsed),
        ariaLabel: t('topnav.close'),
      },
    },
  };

  const dropdownSelectListItems: DropdownMenuItemCombinedProps[] = [
    {
      text: {
        value: t('topnav.accountMenu.myTickets'),
        colour: 'ActionActive',
      },
      type: 'Text',
      onDropdownMenuItemClicked: () => {
        setActionMenuDropDownState(UserStatus.Collapsed);
        history.push('/mytickets');
      },
    },
    {
      text: {
        value: t('topnav.accountMenu.logOut'),
        colour: 'ActionActive',
      },
      type: 'Text',
      onDropdownMenuItemClicked: () => history.push('/logout'),
    },
  ];

  return {
    ...typedDeepMerge(props, {
      state: actionMenuDropDownState,
      greetingFirstName: {
        value: t('topnav.welcome', {
          greetingFirstName: account?.first_name,
        }),
      },
      cardImage: {
        imageSrc: account?.loyalty_program?.program_logo_url,
        imageFallback: CardAsset,
      },
      cardNumber: {
        value: t('topnav.cardNumber', {
          cardNumber: account?.loyalty_program?.last_four || '4242',
        }),
      },
      loyaltyPoints: {
        value: `${rewardSign}${numberUnits}  ${handleDisplayUnitName(account)}`,
      },
      expandButton: {
        icon: {
          asset: 'ChevronDown',
          style: 'White',
        },
        onClick: () => setActionMenuDropDownState(UserStatus.Expanded),
        ariaLabel: t('topnav.expand'),
      },
      collapseButton: {
        icon: {
          asset: 'ChevronUp',
          style: 'White',
        },
        onClick: () => setActionMenuDropDownState(UserStatus.Collapsed),
        ariaLabel: t('topnav.close'),
      },
      closeButton: {
        icon: {
          asset: 'Close',
          style: 'White',
        },
        onClick: () => setActionMenuDropDownState(UserStatus.Collapsed),
        ariaLabel: t('topnav.close'),
      },
      dropdownSelectList: {
        dropdownMenuItems: dropdownSelectListItems,
      },
      accountMenuBlock: accountMenuBlock,
      handleToggle: (isOpen: boolean) => {
        setActionMenuDropDownState(
          isOpen ? UserStatus.Expanded : UserStatus.Collapsed,
        );
      },
      isLoading: loading,
    }),
  };
};

export default usePresenter;
