import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { removePastEvents } from '../ExclusivesEventBlock/util';
import { EventInfoTypeEnum } from '../../../components/molecules/EventInfo/types';

import { CP1_EXCLUSIVE_TAG } from '../../../lib/constants';
import { checkSoldOut, formatDateToMonthDay, getQueryParams, getWeekday, getYear, handleDateFormat, handleLocation, handleLocationShort, handleTagFilter, handleTimeFormat, handleVariableImage, removeIgnoredParams } from '../../../lib/util';
import { Event, EventsQueryParams } from '../../../modules/partnership';
import { CardCombinedProps } from '../../molecules/Card/types';
import { defaultDateToTodayIfEmpty } from '../../molecules/Filter/utils';
import { TagCombinedProps } from '../../molecules/Tag/types';

import { EventListingBlockCombinedProps } from './types';
import useInteractor from './EventListingBlock.interactor';

const usePresenter = (props: EventListingBlockCombinedProps): EventListingBlockCombinedProps => {
  const { defaultParams,
    cardsPerPage, ignoreParams,
    cardType, requiredFields,
    emptyStateBanner,
    fetchEvents,
    isLoading,
    currentRegion,
    regionLoading,
    account,
    accountLoading } = useInteractor(props);

  const { t } = useTranslation();

  const { pathname, search } = useLocation();
  const shouldUpdateScroll = useRef<boolean>(false);
  const [events, setEvents] = useState<Event[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const isExclusivePage = pathname.startsWith('/exclusive-events');

  let hasAllRequiredParams = true; // check if all required params is defined or not
  const initialQueryParams = removeIgnoredParams(
    getQueryParams(search),
    ignoreParams || [],
  );
  const [apiParams, setApiParams] = useState<EventsQueryParams>({
    page: 1,
    per_page: cardsPerPage || 10,
    category: isExclusivePage ? 'exclusive' : '',
    ...initialQueryParams,
  });
  const [currentPage, setCurrentPage] = useState({
    page: 1,
    numberOfPages: 1,
  });
  useEffect(() => {
    setEvents([]);
    const queryParams = {
      ...removeIgnoredParams(getQueryParams(search), ignoreParams || []),
    };
    let currentRegionParams;
    if (!isExclusivePage) {
      currentRegionParams = {
        region_id: currentRegion?.id,
      };
    }

    let exclusivePageFilter = {};
    if (isExclusivePage && !queryParams.category) {
      exclusivePageFilter = {
        category: 'exclusive',
      };
    }
    if (isExclusivePage && !queryParams.date_start) {
      queryParams.date_start = defaultDateToTodayIfEmpty(
        queryParams.date_start,
      );
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    setApiParams({
      ...currentRegionParams,
      ...defaultParams,
      ...queryParams,
      ...exclusivePageFilter,
      page: 1,
      per_page: ignoreParams?.includes('per_page')
        ? undefined
        : cardsPerPage || 10,
    });
  }, [search, currentRegion, defaultParams, cardsPerPage, ignoreParams]);
  useEffect(() => {
    if (accountLoading || ('region_id' in apiParams && regionLoading)) {
      return;
    }
    let fetchingEvents = true;
    const doFetchEvents = async (queryParams: EventsQueryParams) => {
      try {
        if (account) {
          const updatedQueryParam = handleTagFilter(
            queryParams,
            account,
            !isExclusivePage && queryParams.category !== 'exclusive',
          );
          if (fetchEvents) {
            const response = await fetchEvents(updatedQueryParam);
            response.items = removePastEvents(response.items);
            setLoading(false);
            setCurrentPage({
              page: response.page,
              numberOfPages: response.number_of_pages,
            });
            if (fetchingEvents) {
              const updatedEvents =
                response.page >= 2
                  ? [...events, ...response.items]
                  : response.items;
              setEvents(updatedEvents);
            }
          }
        }
      } catch {
        // TODO
      }
    };
    const queryParams = { ...apiParams };
    if (apiParams.opponent_id === -1) {
      queryParams.opponent_id = undefined;
    }

    if (requiredFields && requiredFields.length) {
      for (let index = 0; index < requiredFields.length; index++) {
        if (!queryParams[requiredFields[index]]) {
          hasAllRequiredParams = false;
          break;
        }
      }
    }

    if (hasAllRequiredParams) {
      void doFetchEvents(queryParams);
    }

    // To avoid race conditions to change events
    return () => {
      fetchingEvents = false;
    };
  }, [apiParams, fetchEvents, account, regionLoading, accountLoading]);
  const showMoreData = () => {
    setApiParams({
      ...apiParams,
      page: (apiParams.page || 1) + 1,
    });
    shouldUpdateScroll.current = true;
  };
  const handleExclusiveView = (tags?: string[]) => {
    let stateType: EventInfoTypeEnum = 'Default';
    if (tags?.includes(CP1_EXCLUSIVE_TAG)) {
      stateType = 'Exclusive';
    }
    return stateType;
  };
  const handleExclusiveTag = (tags?: string[]): TagCombinedProps => {
    let tagValue: TagCombinedProps = {};
    if (tags?.includes(CP1_EXCLUSIVE_TAG)) {
      tagValue = {
        type: 'Information',
        size: 'Small',
        label: {
          type: 'Body',
          size: 'Small',
          style: 'SemiBold',
          colour: 'Action',
          align: 'Left',
          value: 'Cardholder Exclusive',
        },
      };
    }
    return tagValue;
  };
  const eventCards = events.map((item): CardCombinedProps => {
    const isSoldOut = checkSoldOut(item, true);
    return {
      type: cardType,
      image: {
        imageSrc: item && handleVariableImage(item, 'modal', 'mobile'),
      },
      eventInfo: {
        type: handleExclusiveView(item.tags),
        state: isSoldOut ? 'SoldOut' : 'Default',
        exclusive: handleExclusiveTag(item.tags),
        title: {
          value: item.name,
        },
        date: {
          value: handleDateFormat(item.local_date),
        },
        time: {
          value: t('exclusiveWidget.atTime', {
            time: handleTimeFormat(item.local_date),
          }),
        },
        location: {
          value: handleLocation(item.venue),
        },
        tag: {
          label: {
            value: t('exclusiveWidget.soldOut'),
            style: 'SemiBold',
          },
        },
      },
      performerInfo: {
        state: isSoldOut ? 'SoldOut' : 'Default',
        title: {
          value: item.name,
        },
        date: {
          value: handleDateFormat(item.local_date),
        },
        time: {
          value: t('exclusiveWidget.atTime', {
            time: handleTimeFormat(item.local_date),
          }),
        },
        location: {
          value: handleLocation(item.venue),
        },
        locationMobile: {
          value: handleLocationShort(item.venue),
        },
        tag: {
          label: {
            value: t('exclusiveWidget.soldOut'),
            style: 'SemiBold',
          },
        },
      },
      day: {
        value: getWeekday(item.local_date),
      },
      date: {
        value: formatDateToMonthDay(item.local_date),
      },
      year: {
        value: getYear(item.local_date),
      },
      time: {
        value: handleTimeFormat(item.local_date),
      },
      button: {
        text: {
          value: isSoldOut
            ? t('shopByWidget.learnMore')
            : t('shopByWidget.getTickets'),
        },
        href: `/events/${item.id}`,
      },
      icon: {
        style: 'ActionHover',
      },
      linkPath: `/events/${item.id}`,
    };
  });

  return {
    ...props,
    state: eventCards.length ? 'Filled' : 'Empty',
    filterGroup: props.filterGroup,
    eventsCardList: {
      cards: eventCards,
      shouldUpdateScroll: shouldUpdateScroll.current,
    },
    showMoreButton:
      currentPage.numberOfPages > currentPage.page
        ? {
          text: {
            value: t('shopByWidget.showMore'),
          },
          onClick: showMoreData,
        }
        : undefined,
    emptyStateBanner: emptyStateBanner || {
      title: {
        value: t('search.noEventsTitle'),
      },
      message: {
        value: isExclusivePage
          ? [
            t('search.noExclusiveEventsMessagePart1'),
            // eslint-disable-next-line react/jsx-key
            <br />,
            t('search.noExclusiveEventsMessagePart2'),
          ]
          : t('search.noEventsMessage'),
      },
      button: {
        text: {
          value: t('search.buttonSeeAll'),
        },
        href: '/',
      },
    },
    isLoading: loading || isLoading,
  };
};

export default usePresenter;
