import { SecondaryBannerProps } from '../../molecules/SecondaryBanner';


type SecondaryBannerBlockInternalProps = {
  secondaryBanner?: SecondaryBannerProps;
};

type SecondaryBannerBlockPropsValues = {
  '': SecondaryBannerBlockInternalProps;
};

const propValues: SecondaryBannerBlockPropsValues = {
  '': {
    secondaryBanner: {
    },
  },
};

const getProps = (type: string): SecondaryBannerBlockInternalProps => {
  const values: SecondaryBannerBlockInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
