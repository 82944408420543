import { useTranslation } from 'react-i18next';
import useInteractor from './SearchMatchesBlock.interactor';
import { SearchMatchesBlockCombinedProps } from './types';
import { useLocation } from 'react-router-dom';
import { checkSoldOut, getLocalDate, getLocalTime, getQueryParams, handleLocationShort, handleTagFilterForSearch, handleVariableImage } from '../../../lib/util';
import { useEffect, useRef, useState } from 'react';
import { SearchQueryParams, Event } from '../../../modules/partnership';
import { defaultDateToTodayIfEmpty, exclusiveDateFilterOptions } from '../../molecules/Filter/utils';
import { CardCombinedProps } from '../../molecules/Card/types';
import { CP1_EXCLUSIVE_TAG } from '../../../lib/constants';
import { TagCombinedProps } from '../../molecules/Tag/types';
import { EventInfoTypeEnum } from '../../molecules/EventInfo/types';
import NotFoundErrorAsset from '../../../resources/icons/NotFoundError.svg';

const usePresenter = (props: SearchMatchesBlockCombinedProps): SearchMatchesBlockCombinedProps => {
  const { account, fetchSearchResults, isLoading } = useInteractor(props);
  const shouldUpdateScroll = useRef<boolean>(false);
  const { t } = useTranslation();
  const { search } = useLocation();
  const params = getQueryParams(search);
  const searchTerm = decodeURIComponent(params.q) || '';
  const dateStart = decodeURIComponent(params.date_start);

  const [searchMatches, setSearchMataches] = useState(0);
  const [events, setEvents] = useState<Event[]>([]);
  const [apiParams, setApiParams] = useState<SearchQueryParams>({
    q: searchTerm,
    page: 1,
    per_page: 10,
    date_start: defaultDateToTodayIfEmpty(dateStart),
    tag_filter: handleTagFilterForSearch(account),
  });

  useEffect(() => {
    setEvents([]);
    const queryParams = getQueryParams(search);
    queryParams.date_start = defaultDateToTodayIfEmpty(queryParams.date_start);
    setApiParams({
      ...queryParams,
      q: decodeURIComponent(queryParams.q) || '',
      page: 1,
      per_page: 10,
      tag_filter: handleTagFilterForSearch(account),
    });
  }, [search]);

  useEffect(() => {
    const doFetchSearchResults = async (queryParams: SearchQueryParams) => {
      try {
        const response = await fetchSearchResults(queryParams);
        setSearchMataches(response.total);
        setEvents([...events, ...response.items]);
      } catch {
        // TODO handle error
      }
    };
    void doFetchSearchResults(apiParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiParams, fetchSearchResults]);


  const showMoreData = () => {
    setApiParams({
      ...apiParams,
      page: (apiParams.page || 1) + 1,
    });
    shouldUpdateScroll.current = true;
  };

  const handleExclusiveView = (tags?: string[]) => {
    let stateType: EventInfoTypeEnum = 'Default';
    if (tags?.includes(CP1_EXCLUSIVE_TAG)) {
      stateType = 'Exclusive';
    }
    return stateType;
  };
  const handleExclusiveTag = (tags?: string[]): TagCombinedProps => {
    let tagValue: TagCombinedProps = {};

    if (tags?.includes(CP1_EXCLUSIVE_TAG)) {
      tagValue = {
        type: 'Information',
        size: 'Small',
        label: {
          type: 'Body',
          size: 'Small',
          style: 'SemiBold',
          colour: 'Action',
          align: 'Left',
          value: 'Cardholder Exclusive',
        },
      };
    }
    return tagValue;
  };

  const eventCards = events.map((item): CardCombinedProps => {
    const isSoldOut = checkSoldOut(item, true);
    return {
      button: {
        text: {
          value: isSoldOut
            ? t('shopByWidget.learnMore')
            : t('search.getTickets'),
        },
        href: `/events/${item.id}`,
      },
      linkPath: `/events/${item.id}`,
      type: 'Default',
      image: {
        imageSrc: handleVariableImage(item, 'modal', 'mobile'),
      },
      eventInfo: {
        type: handleExclusiveView(item.tags),
        state: isSoldOut ? 'SoldOut' : 'Default',
        exclusive: handleExclusiveTag(item.tags),
        title: {
          value: item.name,
        },
        date: {
          value: `${getLocalDate(item.local_date.toString())}`,
        },
        time: {
          value: `at ${getLocalTime(item.local_date.toString())}`,
        },
        location: {
          value: handleLocationShort(item.venue),
        },
        tag: {
          label: {
            value: t('exclusiveWidget.soldOut'),
            style: 'SemiBold',
          },
        },
      },
    };
  });
  return {
    ...props,
    state: isLoading ? 'Loading' : (events.length ? 'Match' : 'NoMatch'),
    searchResult: {
      filter: {
        filterType: 'DateFilter',
        defaultOption: exclusiveDateFilterOptions[0],
        options: exclusiveDateFilterOptions,
        filterIcon: 'Calendar',
      },
      label: {
        value: t('search.searchResultsFor'),
      },
      searchedValue: {
        value: searchTerm ? `"${searchTerm}"` : undefined,
      },
      numberOfMatches: {
        value: `${searchMatches}`,
      },
      eventsMatchString: {
        value: t('search.eventsMatchYourSearch'),
      },
    },
    eventsCardList: {
      cards: eventCards,
      shouldUpdateScroll: shouldUpdateScroll.current,
    },
    seeMoreButton: events.length >= searchMatches ? undefined : {
      text: {
        value: t('search.showMore'),
      },
      onClick: showMoreData,
    },
    image: {
      imageSrc: NotFoundErrorAsset,
    },
    label: {
      value: `${t('search.searchResultsFor')}`,
    },
    searchedValue: {
      value: `"${searchTerm}"`,
    },
    message: {
      value: `${t('search.noEventsTitle')}`,
    },
    button: {
      text: {
        value: t('search.buttonSeeAll'),
      },
      href: '/',
    },
  };
};

export default usePresenter;
