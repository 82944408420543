import React from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import styles from './BreakdownBlock.module.scss';
import { displayWithQuantity } from '../../../legacy/pages/SeatSelectionPage/utils';
import { formatter } from '../../../legacy/pages/CheckoutManualPage/utils';

export type BreakdownBlockProps = {
  price?: number;
  service_fee?: number;
  delivery_fee?: number;
  total?: number;
  className?: string;
  quantity?: number;
};
export const deliveryFeeClassName = cx({
  'alignRight': true,
  'deliveryFee': true,
});

const BreakdownBlock: React.FC<BreakdownBlockProps> = ({
  price,
  service_fee,
  delivery_fee,
  total,
  className,
  quantity,
}) => {
  const { t } = useTranslation();
  return (
        <div className={cx(styles.breakDownBlock, className)}>
            <ul>
                <li><span className={styles.alignLeft}>{t('breakdown.tickets')}</span><span className={styles.alignRight}>{displayWithQuantity(quantity, formatter.format(price || 0))}</span></li>
                <li><span className={styles.alignLeft}>{t('breakdown.serviceFee')}</span><span className={styles.alignRight}>{displayWithQuantity(quantity, formatter.format(service_fee || 0))}</span></li>
                {delivery_fee === 0 && (
                    <li>
                        <span className={styles.alignLeft}>{t('breakdown.deliveryFee')}</span>
                        <span className={styles.delivery}>{t('breakdown.deliveryFeeFree')}</span>
                    </li>
                )}
                <li className={styles.lastRow}><span className={styles.totalLabel}>{t('breakdown.total')}</span><span className={styles.total}>{displayWithQuantity(quantity, formatter.format(total || 0))}</span></li>
            </ul>
        </div>
  );
};
export default BreakdownBlock;
