import React from 'react';
import cx from 'classnames';

import { CheckoutSteps } from '../../../lib/util';
import ErrorHandler from '../../../modules/error/ErrorHandler';

import Button from '../../atoms/Button';
import Spinner from '../../atoms/Spinner';
import BillingInfoBlock from '../../blocks/BillingInfoBlock';
import CardInfoBlock from '../../blocks/CardInfoBlock';
import CardInfoNotRequiredBlock from '../../blocks/CardInfoNotRequiredBlock';
import { BlackBoxInput } from '../../blocks/CardInfoNotRequiredBlock/util';
import CustomerInfoBlock from '../../blocks/CustomerInfoBlock';
import CustomerInfoReadOnlyBlock from '../../blocks/CustomerInfoReadOnlyBlock';
import ErrorBlock from '../../blocks/ErrorBlock';
import LegalBlock from '../../blocks/LegalBlock';
import LoyaltyBlock from '../../blocks/LoyaltyBlock';
import OrderInfo from '../../blocks/OrderInfo';
import PaymentInformationReadOnlyBlock from '../../blocks/PaymentInformationReadOnlyBlock';
import ShippingInfoBlock from '../../blocks/ShippingInfoBlock';
import StepperBlock from '../../blocks/StepperBlock';
import TicketAlertModal from '../../organisms/TicketAlertModal';

import customStyles from './Custom.module.scss';
import styles from './CheckoutManualPage.module.scss';
import usePresenter from './CheckoutManualPage.presenter';
import getProps from './CheckoutManualPage.props';
import { CheckoutManualPageCombinedProps, ContentSwicherProps } from './types';
import { getCodeFromStep } from './utils';

// MANUAL OVERRIDE STARTS
const ContentSwitchable: React.FC<ContentSwicherProps> = ({
  step,
  blockProps,
  isRewardsOnly,
  showRewards,
  rewardsCost,
  onFormSubmit,
  hardTicket,
  showTermsAndConditionsCheckbox,
  hasAcceptedTerms,
  checkBoxItem,
}) => {
  const code: CheckoutSteps = getCodeFromStep(step, hardTicket === true);
  const orderInfoMobile = (
    <OrderInfo
      className={customStyles.orderInfoMobile}
      {...blockProps?.orderInfoBlock}
    />
  );
  switch (code) {
    case CheckoutSteps.CUSTOMERINFO:
    default:
      return (
        <CustomerInfoBlock
          className={customStyles.checkoutFlowBlock}
          {...blockProps?.customerInfo}
        />
      );
    case CheckoutSteps.BILLINGINFO:
      return (
        <BillingInfoBlock
          className={customStyles.checkoutFlowBlock}
          {...blockProps?.billingInfo}
        />
      );
    case CheckoutSteps.SHIPPINGINFO:
      return (
        <ShippingInfoBlock
          className={customStyles.checkoutFlowBlock}
          {...blockProps?.shippingInfo}
          formSubmitted={(event) => {
            if (onFormSubmit) {
              onFormSubmit(event);
            }
          }}
        />
      );
    case CheckoutSteps.PAYMENTINFO:
      if (isRewardsOnly) {
        return (
          <React.Fragment>
            <CardInfoNotRequiredBlock
              onFormSubmit={onFormSubmit}
              rewardsCost={rewardsCost}
              className={customStyles.checkoutFlowBlock}
              {...blockProps?.cardInfoNotRequired}
            />
            {orderInfoMobile}
          </React.Fragment>
        ); // if exclusive event
      } else {
        return (
          <React.Fragment>
            {showRewards && (
              <LoyaltyBlock
                className={customStyles.checkoutFlowBlock}
                {...blockProps?.loyalty}
              />
            )}
            <CardInfoBlock
              className={customStyles.checkoutFlowBlock}
              {...blockProps?.cardInfoBlock}
            />
            {orderInfoMobile}
          </React.Fragment>
        );
      }
    case CheckoutSteps.CONFIRMATION:
      return (
        <React.Fragment>
          <CustomerInfoReadOnlyBlock
            className={customStyles.checkoutFlowBlock}
            {...blockProps?.customerInfoReadOnly}
          />
          <PaymentInformationReadOnlyBlock
            className={customStyles.checkoutFlowBlock}
            {...blockProps?.paymentInfoReadOnly}
          />
          {orderInfoMobile}
          <LegalBlock
            className={customStyles.checkoutFlowBlock}
            showTermsAndConditionsCheckbox={showTermsAndConditionsCheckbox}
            checkBoxItem={checkBoxItem}
            hasAcceptedTerms={hasAcceptedTerms}
            {...blockProps?.legalBlock}
          />
        </React.Fragment>
      );
  }
};
// MANUAL OVERRIDE ENDS


const CheckoutManualPage: React.FC<CheckoutManualPageCombinedProps> = (props) => {
  const {
    className,
    classes,
    stepperBlock,
    customerInfoBlock,
    message,
    button,
    orderInfo,

    // MANUAL OVERRIDE STARTS
    billingInfoBlock,
    cardInfoBlock,
    cardInfoNotRequiredBlock,
    checkBoxItem,
    currentForm,
    customerInfoReadOnly,
    error,
    hardTicket,
    hasAcceptedTerms,
    ioBlackBoxRef,
    isLoading,
    isRewardsOnly,
    legalBlock,
    loyaltyBlock,
    onFormSubmit,
    orderApiError,
    orderErrorBlock,
    paymentInfoReadOnly,
    rewardsCost,
    shippingInfoBlock,
    showRewards,
    showTermsAndConditionsCheckbox,
    step,
    ticketAlertModalProps,
    // MANUAL OVERRIDE ENDS
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.checkoutManualPage;

  // MANUAL OVERRIDE STARTS
  const switchableProps: ContentSwicherProps = {
    blockProps: {
      customerInfo: customerInfoBlock,
      billingInfo: billingInfoBlock,
      shippingInfo: shippingInfoBlock,
      cardInfoNotRequired: cardInfoNotRequiredBlock,
      orderInfoBlock: orderInfo,
      loyalty: loyaltyBlock,
      customerInfoReadOnly,
      paymentInfoReadOnly,
      legalBlock,
      cardInfoBlock,
    },
    isRewardsOnly: isRewardsOnly,
    step: step || 1,
    showRewards: showRewards,
    rewardsCost: rewardsCost,
    onFormSubmit,
    hardTicket,
    showTermsAndConditionsCheckbox,
    hasAcceptedTerms,
    checkBoxItem,
  };

  const code: CheckoutSteps = getCodeFromStep(
    switchableProps.step,
    hardTicket === true,
  );

  if (error) {
    return <ErrorHandler error={error} />;
  }
  if (orderApiError)
    return (
      <ErrorBlock className={styles.orderErrorContainer} {...orderErrorBlock} />
    );
  if (isLoading) {
    return (
      <div className={cx(styles.checkoutManualPage, className)}>
        <div className={styles.container}>
          <Spinner />
        </div>
      </div>
    );
  }

  return (
    <div className={cx(currentStyle, className)}>
      {/* // MANUAL OVERRIDE - moved StepperBlock in leftContent */}
      <div className={cx(styles.container, classes?.container)}>
        {/* // MANUAL OVERRIDE - removed CustomerInfoBlock */}
        <div className={cx(styles.leftContent, classes?.leftContent)}>
          <StepperBlock
            activeStep={step}
            className={cx(styles.stepperBlock, classes?.stepperBlock)}
            {...internalProps.stepperBlock}
            {...stepperBlock}/>
          {/* MANUAL OVERRIDE - replaced CustomerInfoBlock with ContentSwitchable */}
          <ContentSwitchable {...switchableProps} />
        </div>
        <div className={cx(styles.bottomContent, classes?.bottomContent)}>
          {/* // MANUAL OVERRIDE - removed message */}
          <Button
            buttonForm={currentForm}
            className={cx(styles.button, classes?.button)}
            {...internalProps.button}
            {...button}/>
        </div>
        <div className={cx(styles.rightContent, classes?.rightContent)}>
          <OrderInfo
            className={cx(styles.orderInfo, classes?.orderInfo, customStyles.orderInfo)} /* MANUAL OVERRIDE - added custom style class */
            {...internalProps.orderInfo}
            {...orderInfo}/>
        </div>
      </div>
      <TicketAlertModal {...ticketAlertModalProps} />
      <BlackBoxInput ioBlackBoxRef={ioBlackBoxRef} />
    </div>
  );
  // MANUAL OVERRIDE ENDS
};

export default CheckoutManualPage;

