import { getRegions } from '../../../modules/partnership/api';

import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { PerformerLandingPageCombinedProps, PerformerLandingPagePresenterProps } from './types';
import { DropdownOption } from '../../molecules/Filter/types';

const useInteractor = (props: PerformerLandingPageCombinedProps): PerformerLandingPagePresenterProps => {
  const { performerId } = props;
  const { t } = useTranslation();
  const { data } = useQuery(
    ['getRegions'], () => getRegions({ performer_id: performerId }),
  );

  const sorted = data?.sort((a, b) => (a.displayName ?? a.city).localeCompare(b.displayName ?? b.city)); //eslint-disable-line
  const cities: DropdownOption[] = sorted?.map((location) => {
    return ({
      title: location.displayName || location.city,
      type: 'SelectValue',
      value: {
        value: String(location.id),
      },
    });
  }) || [];

  const anyLocation: DropdownOption = {
    title: t('shopByWidget.anyLocation'),
    type: 'SelectValue',
    value: {},
  };

  return {
    ...props,
    locationOptions: [anyLocation, ...cities],
  };
};

export default useInteractor;