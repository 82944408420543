import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { typedDeepMerge } from '../../../lib/objectUtils';
import CardAsset from '../../../resources/icons/CreditCard.svg';
import { AuthContext } from '../../../modules/auth';
import useWindowSize from '../../../lib/useWindowSize';
import { 
  getUnitDisplaySettings, 
  handleAmountFormattingString, 
  handleDecimalValuesForDisplay, 
  handleDisplayUnitName,
} from '../../../lib/util';
import { AccountMenuBlockCombinedProps } from '../../blocks/AccountMenuBlock/types';

import { AccountMenuCombinedProps } from './types';

export enum UserStatus {
  Collapsed = 'Collapsed',
  Expanded = 'Expanded',
}

const usePresenter = (props: AccountMenuCombinedProps): AccountMenuCombinedProps => {
  const { width: innerWidth } = useWindowSize();
  const history = useHistory();
  const { account } = useContext(AuthContext);
  const isDesktop = innerWidth && innerWidth > 1023;
  const { setAccountMenuDropdownState, accountMenuDropdownState } = props;
  const { t } = useTranslation();
  const rewardType = account?.loyalty_program.loyalty_unit_name.toLowerCase();
  const { rewardSign, useDecimals } = getUnitDisplaySettings(rewardType);
  const numberUnits = handleDecimalValuesForDisplay(handleAmountFormattingString(account?.loyalty_program?.number_of_units), useDecimals);
  
  const accountMenuBlock: AccountMenuBlockCombinedProps = {
    isModalOpen: accountMenuDropdownState === 'Expanded' && !isDesktop,
    accountMenu: {
      state: accountMenuDropdownState,
      accountMenuDropdownState,
      setAccountMenuDropdownState,
      closeButton: {
        onClick: () => setAccountMenuDropdownState?.(UserStatus.Collapsed),
        ariaLabel: t('topnav.close'),
      },
    },
  };
  return {
    ...typedDeepMerge(props, {
      state: accountMenuDropdownState,
      greetingFirstName: {
        value: `${t('topnav.welcome', {
          greetingFirstName: account?.first_name,
        })}`,
      },
      cardImage: {
        imageSrc: account?.loyalty_program?.program_logo_url,
        imageFallback: CardAsset,
      },
      cardNumber: {
        value: t('topnav.cardNumber', {
          cardNumber: account?.loyalty_program?.last_four || '4242',
        }),
      },
      loyaltyPoints: {
        value: `${rewardSign}${numberUnits}  ${handleDisplayUnitName(account)}`,
      },
      expandButton: {
        onClick: () => setAccountMenuDropdownState?.(UserStatus.Expanded),
        ariaLabel: t('topnav.expand'),
      },
      closeButton: {
        onClick: () => setAccountMenuDropdownState?.(UserStatus.Collapsed),
        ariaLabel: t('topnav.close'),
      },
      collapseButton: {
        icon: {
          asset: accountMenuDropdownState === 'Expanded' ? 'ChevronUp' : 'ChevronDown',
        },
        onClick: () => {
          setAccountMenuDropdownState?.(UserStatus.Collapsed);
        },
        ariaLabel: t('topnav.collapse'),
      },
      accountMenuBlock: accountMenuBlock,
      handleToggle: (isOpen: boolean) => {
        // to only handle dropdown toggle on desktop breakpoint
        if (isDesktop) {
          setAccountMenuDropdownState?.(isOpen ? UserStatus.Expanded : UserStatus.Collapsed);
        }
      },
      myTickets: {
        title: {
          value: t('topnav.accountMenu.myTickets'),
        },
        type: 'Default',
        onClick: () => history.push('/mytickets'),
      },
      logOut: {
        title: {
          value: t('topnav.accountMenu.logOut'),
        },
        type: 'Critical',
        onClick: () => history.push('/logout'),
      },
      isDropdownOpen: accountMenuDropdownState === 'Expanded',
    }),
  };
};

export default usePresenter;
