import { useTranslation } from 'react-i18next';
import { PerformerLandingPageCombinedProps } from './types';
import useInteractor from './PerformerLandingPage.interactor';
import { handlePerformerImage } from '../../../lib/util';
import { dateFilterOptions } from '../../molecules/Filter/utils';

const usePresenter = (props: PerformerLandingPageCombinedProps): PerformerLandingPageCombinedProps => {
  const { t } = useTranslation();
  const { performer, performerId, locationOptions } = useInteractor(props);

  return {
    ...props,
    landingPageBannerBlock: {
      landingPageBanner: {
        image: {
          imageSrc: handlePerformerImage('hero', 'desktop', performer),
        },
        text: {
          value: performer?.name,
        },
      },
    },
    performerEvents: {
      cardType: 'NoThumbnailImage',
      defaultParams: {
        performer_id: performerId,
        region_id: undefined,
      },
      cardsPerPage: 5,
      filterGroup: {
        filters: [
          {
            type: 'IconTextFilter',
            filterType: 'KeyValueFilter',
            filterIcon: 'LocationMarker',
            filterName: 'region_id',
            options: locationOptions || [],
          },
          {
            type: 'IconFilter',
            filterType: 'DateFilter',
            filterIcon: 'Calendar',
            options: dateFilterOptions,
          },
        ],
      },
      emptyStateBanner: {
        title: {
          value: t('search.noEventsTitle'),
        },
        message: {
          value: t('search.noEventsMessage'),
        },
        type: 'NoButton',
      },
    },
  };
};

export default usePresenter;
