import axios from 'axios';
import { BFF_URL } from '../../lib/config';
import { handleError } from '../error/utils';

export const getDataFile = async (): Promise<object | string> => {
  try {
    const { data } = await axios.get<object | string>(
      `${BFF_URL}/optimizelyDatafile`,
    );
  
    return data;
  } catch (error) {
    throw handleError(error);
  }
};