import React from 'react';
import cx from 'classnames';

import LandingPageBannerBlock from '../../blocks/LandingPageBannerBlock';
import EventListingBlock from '../../blocks/EventListingBlock';

import styles from './SportTeamsLandingPage.module.scss';
import usePresenter from './SportTeamsLandingPage.presenter';
import getProps from './SportTeamsLandingPage.props';
import { SportTeamsLandingPageCombinedProps } from './types';


const SportTeamsLandingPage: React.FC<SportTeamsLandingPageCombinedProps> = (props) => {
  const {
    className,
    classes,
    landingPageBannerBlock,
    sportTeamsOpponent,
    sportTeamsSchedule,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.sportTeamsLandingPage;

  return (
    <div className={cx(currentStyle, className)}>
      <div className={cx(styles.container, classes?.container)}>
        <LandingPageBannerBlock
          className={cx(styles.landingPageBannerBlock, classes?.landingPageBannerBlock)}
          {...internalProps.landingPageBannerBlock}
          {...landingPageBannerBlock}/>
        <EventListingBlock
          className={cx(styles.sportTeamsOpponent, classes?.sportTeamsOpponent)}
          {...internalProps.sportTeamsOpponent}
          {...sportTeamsOpponent}/>
        <EventListingBlock
          className={cx(styles.sportTeamsSchedule, classes?.sportTeamsSchedule)}
          {...internalProps.sportTeamsSchedule}
          {...sportTeamsSchedule}/>
      </div>
    </div>
  );
};

export default SportTeamsLandingPage;

