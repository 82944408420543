import { useTranslation } from 'react-i18next';
import { C1XEventDetailsSliderBlockCombinedProps } from './types';
import { useSnapList } from '../../../modules/snap/SnapListContext/useSnapList';
import { useEffect, useState } from 'react';
import { handleCarouselImages, useWindowSize } from '../../../lib/util';
import customStyles from './Custom.module.scss';
import { BREAKPOINTS } from '../../../lib/constants';
import { ImageCombinedProps } from '../../atoms/Image/types';

const usePresenter = (props: C1XEventDetailsSliderBlockCombinedProps): C1XEventDetailsSliderBlockCombinedProps => {
  const { event } = props;
  const { t } = useTranslation();
  const {
    listElementRef,
    canGoBackward,
    canGoForward,
    onBackwardClicked,
    onForwardClicked,
    updateItemCount,
    updateScrollBy,
    updateScrollOffset,
  } = useSnapList();

  const [images, setImages] = useState<ImageCombinedProps[]>();
  const [snapEnabled, setSnapEnabled] = useState<boolean>(false);
  const { width } = useWindowSize();

  useEffect(() => {
    updateScrollBy(1);
    updateScrollOffset(0);
  }, []);

  useEffect(() => {
    const imageUrls = event && handleCarouselImages(event, 'carousel');
    if (typeof imageUrls === 'object') {
      const imageProps = imageUrls.length === 1 ? [
        {
          className: customStyles.singleContainer,
          imageSrc: imageUrls[0],
        },
      ] : imageUrls?.map((imageUrl) => ({
        className: imageUrls.length === 2 ? customStyles.doubleImageContainer : customStyles.multipleImageContainer,
        imageSrc: imageUrl,
      }));
      setImages(imageProps);
    } else {
      setImages([{
        className: customStyles.singleContainer,
        imageSrc: imageUrls,
      }]);
    }
  }, [event]);

  useEffect(() => {
    if (images && width) {
      if (width && width >= BREAKPOINTS.desktop) {
        updateItemCount(images.length - 2);
      } else {
        updateItemCount(images.length - 1);
      }

      if (width < BREAKPOINTS.desktop) {
        setSnapEnabled(false);
      } else {
        setSnapEnabled(images.length > 2 ? true : false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [images, width]);

  return {
    ...props,
    isSnapEnabled: snapEnabled,
    classes: {
      container: customStyles.snapItemListContainer,
      containerWrapper: customStyles.containerWrapper,
      slideBackwardButton: canGoBackward ? customStyles.slideBackwardButton : customStyles.hideSlideButton,
      slideForwardButton: canGoForward ? customStyles.slideForwardButton : customStyles.hideSlideButton,
    },
    slideBackwardButton: {
      onClick: onBackwardClicked,
      ariaLabel: t('sliderButton.backwardButton'),
    },
    slideForwardButton: {
      onClick: onForwardClicked,
      ariaLabel: t('sliderButton.forwardButton'),
    },
    imageList: {
      listElementRef,
      images,
    },
  };
};

export default usePresenter;
