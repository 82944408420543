import { ButtonProps } from '../../atoms/Button';
import { DividerProps } from '../../atoms/Divider';
import { IconProps } from '../../atoms/Icon';
import { TextProps } from '../../atoms/Text';
import { TagProps } from '../Tag';


type TicketAvailabilityCardSoldOutInternalProps = {
  tag?: TagProps;
  icon?: IconProps;
  message?: TextProps;
  text?: TextProps;
  divider?: DividerProps;
  button?: ButtonProps; // MANUAL OVERRIDE
};

type TicketAvailabilityCardSoldOutPropsValues = {
  '': TicketAvailabilityCardSoldOutInternalProps;
};

const propValues: TicketAvailabilityCardSoldOutPropsValues = {
  '': {
    tag: {
      type: 'Negative',
      size: 'Regular',
    },
    icon: {
      asset: 'CalendarLarge',
      style: 'DigitalGrey30',
    },
    message: {
      type: 'Body',
      size: 'Large',
      style: 'Regular',
      colour: 'SubduedDark',
      align: 'Center',
    },
    text: {
      type: 'Body',
      size: 'Large',
      style: 'Regular',
      colour: 'ActionBase',
      align: 'Center',
    },
    divider: {
      type: 'Horizontal',
      style: 'Thin',
      colour: 'DigitalGrey10',
    },
    // MANUAL OVERRIDE STARTS
    button: {
      type: 'Text',
      style: 'Text',
      size: 'Small',
    },
    // MANUAL OVERRIDE ENDS
  },
};

const getProps = (type: string): TicketAvailabilityCardSoldOutInternalProps => {
  const values: TicketAvailabilityCardSoldOutInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
