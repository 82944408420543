import { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import { addQueryParam, getQueryParams } from '../../../lib/util';

import { TabCombinedProps } from '../../atoms/Tab/types';
import { getDefaultOption } from '../../molecules/Filter/utils';
import { DropdownOption, FilterCombinedProps } from '../../molecules/Filter/types';

import { FilterGroupCombinedProps } from './types';

const usePresenter = (props: FilterGroupCombinedProps): FilterGroupCombinedProps => {
  const { titleValue, categoryName, categories, filters, otherFilterOptions } = props;
  const { search } = useLocation();
  const history = useHistory();
  const defaultCategory = categories ? categories[0].id : 'all';
  const [selectedTab, setSelectedTab] = useState(defaultCategory);

  useEffect(() => {
    if (categoryName === 'category') {
      const queryParams = { ...getQueryParams(search) };
      setSelectedTab(queryParams.category || defaultCategory);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, categoryName]);

  const handleTabClick = (id: string) => {
    setSelectedTab(id);

    if (categoryName) {
      const newSearch = addQueryParam(search, {
        [categoryName]: id,
      });
      history.push({
        search: newSearch,
      });
    }
  };

  const filterProps = filters?.map((filter): FilterCombinedProps => {
    return {
      type: filter.type,
      filterType: filter.filterType,
      filterTitle: filter.filterTitle,
      filterName: filter.filterName,
      filterIcon: filter.filterIcon,
      defaultOption: getDefaultOption(filter.options || []),
      defaultSelectedOption: filter.defaultSelectedOption,
      options: filter.options,
      onTextChange: filter.onTextChanged,
    };
  });

  const tabList: TabCombinedProps[] = [];
  const categoryDropdownOptions: DropdownOption[] = [];
  if (categories) {
    categories.forEach((item) => {
      const selectedCategory = item.id === selectedTab;
      categoryDropdownOptions.push({
        title: item.name,
        type: 'SelectValue',
        value: {
          value: item.id,
        },
      });

      tabList.push({
        style: 'Rounded',
        role: item.role,
        type: selectedCategory ? 'Active' : 'Default',
        colour: 'Dark',
        text: {
          value: item.name,
          style: 'SemiBold',
          colour: 'BaseDark',
          size: 'Large',
        },
        onClick: () => handleTabClick(item.id),
      });
    });
  }

  return {
    ...props,
    filtersProps: filters?.length ? filterProps : undefined,
    otherFilter: otherFilterOptions && {
      type: otherFilterOptions.type,
      filterType: otherFilterOptions.filterType,
      filterTitle: otherFilterOptions.filterTitle,
      filterName: otherFilterOptions.filterName,
      filterIcon: otherFilterOptions.filterIcon,
      defaultOption: getDefaultOption(otherFilterOptions.options),
      defaultSelectedOption: otherFilterOptions.defaultSelectedOption,
      options: otherFilterOptions.options,
      onTextChange: otherFilterOptions.onTextChanged,
    },
    titleValue,
    text: {
      value: titleValue,
    },
    titleFilter: tabList.length ? {
      type: 'TextIconFilter',
      filterType: 'KeyValueFilter',
      filterTitle: titleValue,
      filterName: categoryName,
      defaultOption: categoryDropdownOptions ? categoryDropdownOptions[0] : undefined,
      options: categoryDropdownOptions,
    } : undefined,
    tabList: tabList.length ? {
      tabs: tabList,
    } : undefined,
    filterList: filterProps && {
      filters: filterProps,
    },

  };
};

export default usePresenter;