import React from 'react';
import cx from 'classnames';

import Divider from '../../atoms/Divider';
import Button from '../../atoms/Button';
import Text from '../../atoms/Text';
import EmptyStateBanner from '../../molecules/EmptyStateBanner';
import EventsCardList from '../../organisms/EventsCardList';
import FilterGroup from '../../organisms/FilterGroup';

import customStyles from './Custom.module.scss';
import styles from './EventListingBlock.module.scss';
import usePresenter from './EventListingBlock.presenter';
import getProps from './EventListingBlock.props';
import { EventListingBlockCombinedProps } from './types';
import Spinner from '../../atoms/Spinner';


const EventListingBlock: React.FC<EventListingBlockCombinedProps> = (props) => {
  const {
    state,
    className,
    classes,
    text,
    filterGroup,
    emptyStateBanner,
    divider,
    eventsCardList,
    showMoreButton,
    // MANUAL OVERRIDE STARTS
    isLoading,
    showTitleMobile,
    // MANUAL OVERRIDE ENDS
  } = usePresenter(props);

  const variantName = `${state}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`eventListingBlock${variantName}`];

  let componentView;

  // MANUAL OVERRIDE STARTS
  const titleView = text ? (
    <div className={cx(styles.title, classes?.title)}>
      <Text
        className={cx(styles.text, classes?.text)}
        {...internalProps.text}
        {...text} />
    </div>
  ) : null;

  const mobileTitleView = showTitleMobile ? (
    <div className={cx(styles.titleMobile, classes?.titleMobile)}>
      <Text
        className={cx(styles.text, classes?.text)}
        {...internalProps.text}
        {...text} />
    </div>
  ) : undefined;

  const filterGroupView = filterGroup ? (
    <FilterGroup
      className={cx(styles.filterGroup, classes?.filterGroup)}
      {...internalProps.filterGroup}
      {...filterGroup} />
  ) : null;

  const showMoreButtonView = showMoreButton ? (
    <Button
      className={cx(styles.showMoreButton, classes?.showMoreButton)}
      {...internalProps.showMoreButton}
      {...showMoreButton} />
  ) : null;

  if (isLoading) {
    return (
      <div className={cx(currentStyle, className)}>
        {titleView}
        {mobileTitleView}
        {filterGroupView}
        <Spinner className={customStyles.spinner} />
      </div>
    );
  }

  switch (variantName) {
    case 'Empty': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          {titleView}
          {filterGroupView}
          <EmptyStateBanner
            className={cx(styles.emptyStateBanner, classes?.emptyStateBanner)}
            {...internalProps.emptyStateBanner}
            {...emptyStateBanner} />
          {/* MANUAL OVERRIDE - removed divider */}
        </div>
      );
      break;
    }
    case 'Filled': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          {titleView}
          {mobileTitleView}
          {filterGroupView}
          <EventsCardList
            className={cx(styles.eventsCardList, classes?.eventsCardList)}
            {...internalProps.eventsCardList}
            {...eventsCardList} />
          {showMoreButtonView}
          {/* MANUAL OVERRIDE - removed divider */}
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }
  // MANUAL OVERRIDE ENDS

  return componentView;
};

export default EventListingBlock;

