import React from 'react';
import cx from 'classnames';

import Filter from '../Filter';

import styles from './FilterList.module.scss';
import usePresenter from './FilterList.presenter';
import getProps from './FilterList.props';
import { FilterListCombinedProps } from './types';


const FilterList: React.FC<FilterListCombinedProps> = (props) => {
  const {
    className,
    classes,
    filters,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.filterList;

  const filterViews = filters?.map((filter, index) => (
    <Filter
      key={index}
      className={cx(styles.filter, classes?.filter)}
      {...internalProps.filter}
      {...filter} />
  )) || [];

  return (
    <div className={cx(currentStyle, className)}>
      {filterViews}
    </div>
  );
};

export default FilterList;

