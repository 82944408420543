import React from 'react';
import cx from 'classnames';

import EventListingBlock from '../../blocks/EventListingBlock';
import HeroCTABlock from '../../blocks/HeroCTABlock';
import PrimaryBannerBlock from '../../blocks/PrimaryBannerBlock';
import SecondaryBannerBlock from '../../blocks/SecondaryBannerBlock';

import styles from './ExclusivesEventsLandingPage.module.scss';
import usePresenter from './ExclusivesEventsLandingPage.presenter';
import getProps from './ExclusivesEventsLandingPage.props';
import { ExclusivesEventsLandingPageCombinedProps } from './types';


const ExclusivesEventsLandingPage: React.FC<ExclusivesEventsLandingPageCombinedProps> = (props) => {
  const {
    className,
    classes,
    primaryBannerBlock,
    topBanner,
    heroCTABlock,
    eventListingBlock,
    secondaryBannerBlock,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.exclusivesEventsLandingPage;

  return (
    <div className={cx(currentStyle, className)}>
      <div className={cx(styles.topContainer, classes?.topContainer)}>
        <PrimaryBannerBlock
          className={cx(styles.primaryBannerBlock, classes?.primaryBannerBlock)}
          {...internalProps.primaryBannerBlock}
          {...primaryBannerBlock}/>
        <PrimaryBannerBlock
          className={cx(styles.topBanner, classes?.topBanner)}
          {...internalProps.topBanner}
          {...topBanner}/>
        <HeroCTABlock
          className={cx(styles.heroCTABlock, classes?.heroCTABlock)}
          {...internalProps.heroCTABlock}
          {...heroCTABlock}/>
      </div>
      <div className={cx(styles.container, classes?.container)}>
        <EventListingBlock
          className={cx(styles.eventListingBlock, classes?.eventListingBlock)}
          {...internalProps.eventListingBlock}
          {...eventListingBlock}/>
        <SecondaryBannerBlock
          className={cx(styles.secondaryBannerBlock, classes?.secondaryBannerBlock)}
          {...internalProps.secondaryBannerBlock}
          {...secondaryBannerBlock}/>
      </div>
    </div>
  );
};

export default ExclusivesEventsLandingPage;

