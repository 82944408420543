import { SearchMatchPageCombinedProps } from './types';

const usePresenter = (props: SearchMatchPageCombinedProps): SearchMatchPageCombinedProps => {
  return {
    ...props,
    searchBlock: {
      searchContainer: {
        state: 'Default',
      },
    },
  };
};

export default usePresenter;
