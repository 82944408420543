import React from 'react';
import cx from 'classnames';

import Divider from '../../atoms/Divider';
import Text from '../../atoms/Text';

import styles from './CardTitleBanner.module.scss';
import usePresenter from './CardTitleBanner.presenter';
import getProps from './CardTitleBanner.props';
import { CardTitleBannerCombinedProps } from './types';


const CardTitleBanner: React.FC<CardTitleBannerCombinedProps> = (props) => {
  const {
    className,
    classes,
    title,
    divider,
    message,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.cardTitleBanner;

  return (
    <div className={cx(currentStyle, className)}>
      <Text
        className={cx(styles.title, classes?.title)}
        {...internalProps.title}
        {...title}/>
      <Divider
        className={cx(styles.divider, classes?.divider)}
        {...internalProps.divider}
        {...divider}/>
      <Text
        className={cx(styles.message, classes?.message)}
        {...internalProps.message}
        {...message}/>
    </div>
  );
};

export default CardTitleBanner;

