import { useTranslation } from 'react-i18next';
import useInteractor from './RecentlyViewedBlock.interactor';
import { RecentlyViewedBlockCombinedProps } from './types';
import { RecentlyViewedContentCombinedProps } from '../../molecules/RecentlyViewedContent/types';
import { handleVariableImage, handleDateFormat, handleTimeFormat, handleLocationShort } from '../../../lib/util';

const usePresenter = (props: RecentlyViewedBlockCombinedProps): RecentlyViewedBlockCombinedProps => {
  const { recentEvents, removeRecentEvent } = useInteractor(props);

  const { t } = useTranslation();

  return {
    ...props,
    showRecentEventList: recentEvents && recentEvents.length > 0,
    title: {
      value: t('homepage.recentlyViewed'),
    },
    recentlyViewedList: {
      recentlyViewedContents: recentEvents?.map((item): RecentlyViewedContentCombinedProps => ({
        linkPath: `/events/${item.id}`,
        image: {
          imageSrc: item && handleVariableImage(item, 'hero', 'mobile'),
        },
        title: {
          value: item.name,
        },
        date: {
          value: handleDateFormat(item.local_date),
        },
        time: {
          value: t('exclusiveWidget.atTime', {
            time: handleTimeFormat(item.local_date),
          }),
        },
        location: {
          value: handleLocationShort(item.venue),
        },
        button: {
          icon: {
            asset: 'Close',
          },
          ariaLabel: t(
            'homepage.recentlyViewedCloseButtonScreenReaderText',
          ),
          onClick: () => removeRecentEvent && removeRecentEvent(item.id),
        },
      })),
    },
  };
};

export default usePresenter;
