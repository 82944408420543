/* eslint-disable max-classes-per-file,class-methods-use-this */

import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import Cookie from 'js-cookie';
import { DOMAIN, SESSION_COOKIE_NAME, SIGN_OUT_URL, UNAUTHORIZED_URL, CLIENT_ID } from '../../lib/config';
import { verifyToken, getAuthData, logoutUser } from './AuthApi';
import { Account, VerifyTokenPayload } from './types';
import { UserIdleContext } from '../userIdle';
import { addQueryParam } from '../../lib/util';
import { Props } from '../../lib/types';

export type AuthContextValue = {
  error?: Error;
  loading?: boolean;
  account?: Account;
  refetchAccount: () => void;
  verifyAccessToken: (payload:VerifyTokenPayload) => void;
  setAccountExternally: (account: Account) => void;
};

export const Logout: React.FC<{}> = () => {
  async function logout() {
    await logoutUser();
    // handle removing cookie & fallback cookie
    // https://web.dev/samesite-cookie-recipes/#handling-incompatible-clients
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    Cookie.remove(SESSION_COOKIE_NAME, {
      domain: DOMAIN,
      secure: true,
    });
    Cookie.remove(`${SESSION_COOKIE_NAME}-legacy`, {
      domain: DOMAIN,
      secure: false,
    });
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    window.location.assign(SIGN_OUT_URL);
  }
  void logout();
  return null;
};

const getHashRedirectUrl = (url:string):number=>{
  let hash = 0;
  let char, i;
  for (i = 0; i < url.length; i++) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    char = url.charCodeAt(i);
    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    hash = ((hash << 5) - hash) + char;
    hash = hash & hash;
  }
  return hash;
};
export const UnauthorizedRedirect: React.FC<{ redirectUrl:string }> = ({ redirectUrl }) => {
  const originUrl = window.location.origin;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  Cookie.remove(SESSION_COOKIE_NAME, {
    domain: DOMAIN,
    secure: true,
  });
  Cookie.remove(`${SESSION_COOKIE_NAME}-legacy`, {
    domain: DOMAIN,
    secure: false,
  });
  Cookie.set('redirect_url', redirectUrl);
  let unauthorizedUrl = UNAUTHORIZED_URL;
  const clientId = CLIENT_ID;
  const hashRedirectUrl = getHashRedirectUrl(originUrl);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  unauthorizedUrl = addQueryParam(unauthorizedUrl,
    { redirect_uri:encodeURIComponent(originUrl),
      state: hashRedirectUrl.toString(),
      client_id: clientId,
      scope : 'openid',
      response_type: 'code' });
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  window.location.assign(unauthorizedUrl);
  return null;
};

const initialAuthContext: AuthContextValue = {
  error: undefined,
  loading: false,
  account: undefined,
  refetchAccount: () => { },
  setAccountExternally: () => { },
  verifyAccessToken: () => { },
};

type AccountState = {
  account?: Account;
  loading?: boolean;
  initialLoading?: boolean;
  error?: Error;
};

export const AuthContext = createContext<AuthContextValue>(initialAuthContext);

export const AuthProvider: React.FC<Props> = ({ children }) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  const cookie = Cookie.get(SESSION_COOKIE_NAME);
  const { startSession } = useContext(UserIdleContext);
  const [accountState, setAccountState] = useState<AccountState>({
    initialLoading: cookie ? true : undefined,
  });

  const fetchAccount = useCallback(async () => {
    if (!accountState.initialLoading) {
      setAccountState({ account: accountState.account, loading: true });
    }
    try {
      const data = await getAuthData();
      setAccountState({ account: data });
      startSession();
    } catch (e) {
      setAccountState({ error: e as Error });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startSession]);

  const verifyAccessToken = useCallback(async (payload:VerifyTokenPayload) => {
    try {
      setAccountState({ loading: true });
      await verifyToken(payload);
      await fetchAccount();
    } catch (e) {
      setAccountState({ error: e as Error });
    }
  }, [fetchAccount]);

  useEffect(() => {
    const shouldFetch = accountState.initialLoading ||
      (!accountState.error && !accountState.loading && !accountState.account);
    if (cookie && shouldFetch) {
      void fetchAccount();
    }
  }, [cookie, accountState, fetchAccount]);

  const refetchAccount = () => {
    void fetchAccount();
  };

  const setAccountExternally = (account: Account) => {
    setAccountState({ account: account });
  };

  return (
    <AuthContext.Provider value={{
      ...accountState,
      loading: accountState.initialLoading || accountState.loading,
      refetchAccount,
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      verifyAccessToken,
      setAccountExternally,
    }}>
      {children}
    </AuthContext.Provider>
  );
};

