import { LandingPageBannerBlockProps } from '../../blocks/LandingPageBannerBlock';
import { EventListingBlockProps } from '../../blocks/EventListingBlock';


type PerformerLandingPageInternalProps = {
  landingPageBannerBlock?: LandingPageBannerBlockProps;
  performerEvents?: EventListingBlockProps;
};

type PerformerLandingPagePropsValues = {
  '': PerformerLandingPageInternalProps;
};

const propValues: PerformerLandingPagePropsValues = {
  '': {
    landingPageBannerBlock: {
    },
    performerEvents: {
      state: 'Filled',
    },
  },
};

const getProps = (type: string): PerformerLandingPageInternalProps => {
  const values: PerformerLandingPageInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
