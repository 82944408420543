import { ButtonProps } from '../../atoms/Button';
import { IconProps } from '../../atoms/Icon';


type HeroCTABlockInternalProps = {
  button?: ButtonProps;
  icon?: IconProps;
};

type HeroCTABlockPropsValues = {
  '': HeroCTABlockInternalProps;
};

const propValues: HeroCTABlockPropsValues = {
  '': {
    button: {
      type: 'Text',
      style: 'TextWhite',
      size: 'Medium',
    },
    icon: {
      asset: 'ChevronRight',
      style: 'White',
    },
  },
};

const getProps = (type: string): HeroCTABlockInternalProps => {
  const values: HeroCTABlockInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
