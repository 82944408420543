import useInteractor from './PerformersPage.interactor';
import { PerformersPageProps } from './types';

export type PerformersPagePresenterProps = PerformersPageProps;

const usePresenter = (props: PerformersPagePresenterProps): PerformersPagePresenterProps => {
  const { isSports, performer, performerId } = useInteractor(props);

  return {
    ...props,
    isSports,
    performerId,
    performer,
  };
};

export default usePresenter;
