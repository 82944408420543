import React from 'react';
import cx from 'classnames';

import EventListingBlock from '../../blocks/EventListingBlock';
import ExclusivesEventBlock from '../../blocks/ExclusivesEventBlock';
import PrimaryBannerBlock from '../../blocks/PrimaryBannerBlock';
import RecentlyViewedBlock from '../../blocks/RecentlyViewedBlock';
import SearchBlock from '../../blocks/SearchBlock';

import styles from './Homepage.module.scss';
import usePresenter from './Homepage.presenter';
import getProps from './Homepage.props';
import { HomepageCombinedProps } from './types';


const Homepage: React.FC<HomepageCombinedProps> = (props) => {
  const {
    className,
    classes,
    searchBlock,
    primaryBannerBlock,
    exclusivesEventBlock,
    eventListingBlock,
    // MANUAL OVERRIDE STARTS
    recentlyViewedBlock,
    // MANUAL OVERRIDE ENDS
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.homepage;

  return (
    <div className={cx(currentStyle, className)}>
      <div className={cx(styles.topContainer, classes?.topContainer)}>
        <SearchBlock
          className={cx(styles.searchBlock, classes?.searchBlock)}
          {...internalProps.searchBlock}
          {...searchBlock}/>
        <PrimaryBannerBlock
          className={cx(styles.primaryBannerBlock, classes?.primaryBannerBlock)}
          {...internalProps.primaryBannerBlock}
          {...primaryBannerBlock}/>
      </div>
      {/* MANUAL OVERRIDE - removed extra exclusivsEventBlock  */}
      <div className={cx(styles.container, classes?.container)}>
        {/* MANUAL OVERRIDE - added recentlyViewedBlock */}
        <RecentlyViewedBlock
          className={styles.recentlyViewedBlock}
          {...recentlyViewedBlock}
        />
        <ExclusivesEventBlock
          className={cx(styles.exclusivesEventBlock, classes?.exclusivesEventBlock)}
          {...internalProps.exclusivesEventBlock}
          {...exclusivesEventBlock}/>
        <EventListingBlock
          className={cx(styles.eventListingBlock, classes?.eventListingBlock)}
          {...internalProps.eventListingBlock}
          {...eventListingBlock}/>
      </div>
    </div>
  );
};

export default Homepage;

