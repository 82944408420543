import { useParams } from 'react-router-dom';
import { getVenue } from '../../../modules/partnership/api';
import categoryEvents from '../../../modules/navigation/CategoryEvents.json';
import { useMutation } from 'react-query';
import { DropdownOption } from '../../atoms/NewDropdown/types';
import { TopNavTab } from '../../../modules/navigation/types';
import { VenueLandingPageCombinedProps, VenueLandingPagePresenterProps } from './types';

const useInteractor = (props: VenueLandingPageCombinedProps): VenueLandingPagePresenterProps => {

  const { venueId } = useParams<{ venueId: string }>();
  const { mutateAsync, data } = useMutation(getVenue);
  const topNavTabCategories: TopNavTab[] = JSON.parse(JSON.stringify(categoryEvents));
  const eventCategory: DropdownOption[] = topNavTabCategories.map(category => {
    return {
      id: category.id,
      value: category.name,
    };
  });
  const allCategory: DropdownOption = {
    id: '',
    value: 'All Events',
  };
  return {
    ...props,
    venueId: venueId,
    fetchVenue: mutateAsync,
    venue: data,
    categories: [allCategory, ...eventCategory],
  };
};
export default useInteractor;