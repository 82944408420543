import { useCallback, useContext, useMemo, useRef, useState } from 'react';
import { SeatSelectionBlockCombinedProps } from './types';
import { FilterContext } from '../../../modules/filter';
import { AuthContext } from '../../../modules/auth';
import { TicketInformationCombinedProps } from '../../molecules/TicketInformation/types';
import { Account } from '../../../modules/auth/types';
import { Listing } from '../../../modules/partnership';
import { RewardUnits, addQueryParam, handleAmountFormattingString, handleDecimalValuesForDisplay, handleDisplayUnitName, shouldShowSplitPaymentPrice } from '../../../lib/util';
import { getTooltipPositionFromDimensions } from '../../../modules/map/utils';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ListingsContext } from '../../../modules/listings';
import { MapContext } from '../../../modules/map';
import { C1_BROKER_ID, CP1_EXCLUSIVE_TAG } from '../../../lib/constants';
import { MinPrice } from '../../../modules/map/types';

let ticketInformationArray: TicketInformationCombinedProps[] = [];

function getFormattedRewardsValue(
  props: SeatSelectionBlockCombinedProps & {
    account?: Account;
    filteredListings: Listing[];
  },
  singleTicket: Listing,
) {
  return props.account?.loyalty_program.loyalty_unit_name.toLowerCase() ===
    RewardUnits.MILES.toLowerCase()
    ? handleAmountFormattingString(singleTicket.loyalty_price.price_per)
    : `$${handleDecimalValuesForDisplay(
      handleAmountFormattingString(singleTicket.loyalty_price.price_per),
      true,
    )}`;
}

const getSectionPosition = (sectionId: string) => {
  const section =
    sectionId !== '0'
      ? document.getElementById(sectionId)
      : document.getElementById('svg-map');
  if (section !== null) {
    const { x, y, width, height } = section.getBoundingClientRect();
    return getTooltipPositionFromDimensions(x, y, width, height);
  }
};

const usePresenter = (props: SeatSelectionBlockCombinedProps): SeatSelectionBlockCombinedProps => {
  const {
    selectors: {
      filteredListingsSelector: filteredListings,
    },
  } = useContext(FilterContext);
  const { account } = useContext(AuthContext);
  const { t } = useTranslation();
  const history = useHistory();
  const { event } = props;
  const hoveredSection = useRef<HTMLElement | null>(null);
  const [toolTipProps, setToolTipProps] = useState<{
    minPrice: { id: string; value: number };
    pos: { x: number; y: number };
  }>({ minPrice: { id: '', value: 0 }, pos: { x: 0, y: 0 } });

  const {
    selectors: filterSelectors,
    dispatch: { onSectionHighlightAction, onResetSectionSelectionAction },
    state: { quantityFilterState, showAllInPrice },
  } = useContext(FilterContext);

  const { selectors: listingsSelectors } = useContext(ListingsContext);
  const hasNoInteractiveMap = !listingsSelectors.mapJsonFileName;

  const {
    selectors: { mapState },
    dispatch: { setMapState },
  } = useContext(MapContext);

  // Create a memoized hash of sectionId => minPrice which can be quickly looked up to build the tooltip on mouseOver.
  const minPrices = useMemo(() => {
    // create a hash of sectionId => minPrice
    const minPricesMap: { [key: string]: number } = {};
    filterSelectors.filteredListingsSelector.forEach((listing) => {
      const minPrice = minPricesMap[listing.section];
      if (minPrice === undefined || listing.price_per < minPrice) {
        if (showAllInPrice) {
          minPricesMap[listing.section] =
            filterSelectors.filteredSectionsWithPrice[listing.section]
              .allInPrice ?? listing.price_per;
        } else {
          minPricesMap[listing.section] = listing.price_per;
        }
      }
    });
    return minPricesMap;
  }, [
    filterSelectors.filteredListingsSelector,
    filterSelectors.filteredSectionsWithPrice,
    showAllInPrice,
  ]);

  const handleMouseOver = useCallback(
    (id: string) => {
      if (hoveredSection.current) {
        hoveredSection.current.classList.remove('hovered');
      }
      const section =
        id !== '0'
          ? document.getElementById(id)
          : document.getElementById('svg-map');
      if (section !== null) {
        section.classList.add('hovered');
        hoveredSection.current = section;
      }

      // Reset the filterSelectors.getHilightedSections if we are hovering over a new section AND getHilightedSections
      // is not empty - this is an expensive operation as it causes a full re-render of the map.
      if (
        filterSelectors.getHighlightedSections.length > 0 &&
        filterSelectors.getHighlightedSections[0] !== id
      ) {
        onResetSectionSelectionAction();
      }
      const newToolTipProps = { ...toolTipProps };
      const pos = getSectionPosition(id);
      if (pos) {
        newToolTipProps.pos = pos;
      }

      newToolTipProps.minPrice = {
        id: id,
        value: minPrices[id] ?? 0,
      };
      setToolTipProps(newToolTipProps);
      // minPrices should be the only dependency here. We do not want state vars to be in the dependency array.
    },
    [minPrices],
  );

  const handleMouseLeave = useCallback(() => {
    if (hoveredSection?.current?.id) {
      hoveredSection.current.classList.remove('hovered');
    }
    setToolTipProps({
      minPrice: { id: '', value: 0 },
      pos: { x: 0, y: 0 },
    });
    // there are no dependencies needed here.
  }, []);

  const handleMouseClick = useCallback((singleTicket: any) => {
    const params = addQueryParam(history.location.search, {
      ticket_id: singleTicket.id,
      quantity: props.quantity
        ? String(props.quantity)
        : String(singleTicket.quantities[0]),
      exclusive_listings: (
        singleTicket.broker_id === C1_BROKER_ID
      ).toString(),
      inventoryType: singleTicket.inventoryType || '',
    });

    // Set the filter state so the map will highlight the section when the new page loads
    onSectionHighlightAction({ id: singleTicket.section });

    // remove the hovered class from the map section because the style will be applied via the filter state
    if (hoveredSection?.current?.id) {
      hoveredSection.current.classList.remove('hovered');
      setToolTipProps({
        minPrice: { id: '', value: 0 },
        pos: { x: 0, y: 0 },
      });
    }

    if (params) {
      history.push({
        search: params,
      });
    }
    // leave the deps array empty here.
  }, []);

  ticketInformationArray = useMemo(() => {
    const ticketInfoTmp: TicketInformationCombinedProps[] = [];
    if (props.event && filteredListings.length) {
      const isCP1Exclusive = props.event.tags?.includes(CP1_EXCLUSIVE_TAG);
      const showSplitPaymentPrice = shouldShowSplitPaymentPrice(props.event);

      const len = filteredListings.length;
      // using for loop here as a performance optimization - map and forEach are about 6x slower.
      for (let i = 0; i < len; i++) {
        const singleTicket = filteredListings[i];
        if (
          !props.quantity ||
          (props.quantity && singleTicket.quantities.includes(props.quantity))
        ) {
          const ticketType = 'Default';
          const ticketValueType = handleDisplayUnitName(account);
          const formattedValue =
            account?.loyalty_program?.loyalty_unit_name.toLowerCase() ===
            RewardUnits.MILES.toLowerCase()
              ? handleAmountFormattingString(
                singleTicket.loyalty_price.price_per,
              )
              : `$${handleDecimalValuesForDisplay(
                handleAmountFormattingString(
                  singleTicket.loyalty_price.price_per,
                ),
                true,
              )}`;
          let ticketInformation: TicketInformationCombinedProps = {
            handleMouseOver: (e: React.SyntheticEvent) => {
              e.nativeEvent.stopImmediatePropagation();
              // to not show tooltip when there is no interactive map
              if (!hasNoInteractiveMap) {
                handleMouseOver(singleTicket.seating.section);
              }
            },
            handleMouseLeave: (e: React.SyntheticEvent) => {
              e.nativeEvent.stopImmediatePropagation();
              handleMouseLeave();
            },
            type: ticketType,
            title: {
              value:
                singleTicket.verbose_section_name || singleTicket.section,
            },
            location: {
              value: t('eventPage.location', {
                row: singleTicket.row,
              }),
            },
            value: {
              value: formattedValue,
            },
            valueType: {
              value: ticketValueType,
            },
            other: {
              value: t('eventPage.each'),
            },
            availability: {
              value: t('eventPage.availability', {
                quantity: singleTicket.quantity,
              }),
            },
            onClicked: () => {
              handleMouseClick(singleTicket);
            },
          };

          if (showSplitPaymentPrice) {
            // format the value to either miles or dollars
            const formattedRewardsValue = getFormattedRewardsValue(
              { account, filteredListings },
              singleTicket,
            );

            const displayUnitName = handleDisplayUnitName(account);
            const rewardsUnit = `${formattedRewardsValue} ${displayUnitName}`;
            ticketInformation = {
              ...ticketInformation,
              value: {
                value: `$${singleTicket.price_per}`,
              },
              pointsValue: {
                value: rewardsUnit,
              },
              valueType: {
                value: t('eventPage.or'),
              },
              other: {
                value: t('eventPage.each'),
              },
            };
          }
          ticketInfoTmp.push(ticketInformation);
        }
      }
    }

    return ticketInfoTmp;
  }, [filteredListings]);

  const seatSelectionBlockProps: SeatSelectionBlockCombinedProps = useMemo(() => {
    return {
      instructionLabel: {
        ...props.instructionLabel,
        text: {
          ...props.instructionLabel?.text,
          value: 'Choose Your Seats.',
        },
      },
      ticketList: {
        ticketInformations: ticketInformationArray,
      },
      setMinPrice: (minPrice: MinPrice) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        setMapState({ ...mapState, minPrice });
      },
      seatNumberAriaLabel: t(
        'eventPage.filterSetting.numberOfTicketsScreenReaderText',
        {
          numberOfTickets: ticketInformationArray.length,
        },
      ),
      toolTipProps: toolTipProps,
    };
  }, [mapState, props.instructionLabel, setMapState, t, toolTipProps]);

  return {
    ...props,
    ...seatSelectionBlockProps,
  };
};

export default usePresenter;
