import React from 'react';
import cx from 'classnames';

import SecondaryBannerImage from '../../../resources/legacyImages/SecondaryBanner.png';

import Button from '../../atoms/Button';
import Divider from '../../atoms/Divider';
import Text from '../../atoms/Text';

import styles from './SecondaryBanner.module.scss';
import usePresenter from './SecondaryBanner.presenter';
import getProps from './SecondaryBanner.props';
import { SecondaryBannerCombinedProps } from './types';


const SecondaryBanner: React.FC<SecondaryBannerCombinedProps> = (props) => {
  const {
    className,
    classes,
    imageSrc = SecondaryBannerImage,
    imageSrcAlt,
    imageFallback,
    title,
    divider,
    message,
    // MANUAL OVERRIDE STARTS
    button,
    useButton,
    // MANUAL OVERRIDE ENDS
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.secondaryBanner;

  // MANUAL OVERRIDE STARTS
  let dividerView;
  if (divider) {
    dividerView = (
      <Divider
        className={cx(styles.divider, classes?.divider)}
        {...internalProps.divider}
        {...divider}/>
    );
  }
  let messageView;
  if (useButton) {
    messageView = <Button className={styles.button} {...internalProps.button} {...button} />;
  } else {
    messageView = (
      <Text
        className={cx(styles.message, classes?.message)}
        {...internalProps.message}
        {...message}/>
    );
  }

  return (
    <div className={cx(currentStyle, className)} style={{ backgroundImage: `url(${imageSrc}), url(${imageFallback})` }}>
      <div className={cx(styles.messageContent, classes?.messageContent)}>
        <Text
          className={cx(styles.title, classes?.title)}
          {...internalProps.title}
          {...title}/>
        {dividerView}
        {messageView}
      </div>
    </div>
  );
  // MANUAL OVERRIDE ENDS
};

export default SecondaryBanner;

