import React, { useContext, useMemo } from 'react';
import { OptimizelyProvider, createInstance  } from '@optimizely/react-sdk';
import { AuthContext } from '../auth';
import { IS_DEV, OPTIMIZELY_SDK } from '../../lib/config';
import { Props } from '../../lib/types';
import { getDataFile } from './api';
import { useQuery } from 'react-query';

const OptimizelyProviderWrapper: React.FC<Props> = ({ children }) => {
  const { account } = useContext(AuthContext);
  const { data: datafile, isLoading } = useQuery({ queryKey: ['datafile'], queryFn: getDataFile });
  const accountId = useMemo(() => account?.id, [account?.id]);

  const optimizelyErrorHandler = (error: Error) => {
    if (IS_DEV) {
      console.log('OPTIMIZELY ERROR');
      console.log('-------------------');
      console.log(error);
      console.log('-------------------');
    }
  };

  const optimizelyInstance = useMemo(() => {
    return createInstance({
      sdkKey: OPTIMIZELY_SDK,
      datafile: datafile,
      errorHandler: {
        handleError: optimizelyErrorHandler,
      },
    });
  }, [datafile]);

  if (accountId) {
    if (!isLoading) {
      return (
        <OptimizelyProvider
          optimizely={optimizelyInstance}
          user={{ id: accountId.toString() }}>
            {children}
        </OptimizelyProvider>
      );
    } else {
      return null;
    }
  } else {
    return <>{children}</>;
  }
};

export default OptimizelyProviderWrapper;