import React from 'react';

export interface TextSVG {
  stroke?: string
  fill?: string
  'font-size'?: string
  'font-family'?: string
  'font-weight'?: string
  'text-anchor'?: string
  text: string
  type: string
  transform?: string
  x?: string
  y?: string
  id?: string
}

export interface PathSVG {
  stroke?: string
  fill?: string
  path: string
  'fill-opacity'?: string
  opacity?: string
  type: string
  transform?: string
  id?: string
}

export interface JSONMap {
  height?: string
  width?: string
  elements: Array<TextSVG | PathSVG>
}

interface MapSelectors {
  mapContent?: JSONMap
  mapState: MapState
}

export type MapStateType = {
  fetching?: boolean;
  data?: JSONMap;
  error?: string | null;
};

export interface MapState {
  minPrice: MinPrice;
  pos: Pos;
  hoveredSectionId: string;
  isMouseDown: boolean;
  isDragging: boolean;
  isDoubleClicked: boolean;
}

export interface MapDispatch {
  setMinPrice?: (minPrice: MinPrice) => void;
  setPos?: (pos: Pos) => void;
  setHoveredSectionId?: (id: string) => void;
  setIsMouseDown: (isDown: boolean) => void;
  setIsDragging: (isDragging: boolean) => void;
  setIsDoubleClicked: (isDoubleClicked: boolean) => void;
  setMapState: (mapState: MapState) => void;
}

export interface MapContextType {
  state: MapStateType;
  selectors: MapSelectors;
  dispatch: MapDispatch;
}

type MapParams = { mapId?: string };

export interface MapProviderProps {
  enabled?: boolean;
  initialProps?: MapStateType;
  initialParams?: MapParams;
  initialFetchDisabled?: boolean;
  initialEnabled?: boolean;
  children?:React.ReactNode;
}

export enum ActionType {
  SET_MIN_PRICE,
  SET_POS,
  SET_HOVERED_SECTION_ID,
  SET_MOUSE_DOWN,
  SET_DRAGGING,
  SET_DOUBLE_CLICK,
  SET_MAP_STATE,
}

export interface MinPrice {
  id: string;
  value: number;
}

export interface MinPriceAction {
  type: ActionType.SET_MIN_PRICE;
  payload: MinPrice;
}

export interface Pos {
  x: number;
  y: number;
}

export interface PosAction {
  type: ActionType.SET_POS;
  payload: Pos;
}

export interface HoveredSectionAction {
  type: ActionType.SET_HOVERED_SECTION_ID;
  payload: string;
}

export interface MouseAction {
  type: ActionType.SET_DRAGGING | ActionType.SET_DOUBLE_CLICK | ActionType.SET_MOUSE_DOWN;
  payload: boolean;
}
