import { useState, useContext, useEffect } from 'react';
import { useMutation } from 'react-query';
import { CP1_EXCLUSIVE_TAG } from '../../../lib/constants';
import { handleLoyaltyUnitTags, checkSoldOut } from '../../../lib/util';
import { AuthContext } from '../../../modules/auth';
import useRecentlyViewedEvents from '../../../modules/localstorage/useRecentlyViewEvents';
import { Event } from '../../../modules/partnership';
import { getEvents } from '../../../modules/partnership/api';
import { RecentlyViewedBlockCombinedProps, RecentlyViewedBlockPresenterProps } from './types';

const useInteractor = (props: RecentlyViewedBlockCombinedProps): RecentlyViewedBlockPresenterProps => {
  const { recentEventIds, removeRecentEvent } = useRecentlyViewedEvents();
  const { mutateAsync: fetchEvents } = useMutation(getEvents);
  const [recentEvents, setRecentEvents] = useState<Event[]>([]);
  const { account } = useContext(AuthContext);
  useEffect(() => {
    const doFetchEvents = async (eventIds: string) => {
      try {
        const response = await fetchEvents({ event_ids: eventIds });
        const recentlyViewedEventArray: Event[] = [];
        const handleRemoveEvent = (event: Event): boolean => {
          const isExclusive = event?.tags?.includes(CP1_EXCLUSIVE_TAG);
          const exclusiveEventUnitName = account && account.loyalty_program && isExclusive && (!event?.tags?.includes(handleLoyaltyUnitTags(account.loyalty_program ? account.loyalty_program.loyalty_unit_name : '')));
          const exclusiveEventProgramType = account && account.loyalty_program && (isExclusive && !event?.tags?.includes(account.loyalty_program ? account?.loyalty_program?.program_type_tag : ''));
          const eventProcessingNetwork = isExclusive && (!event?.tags?.includes(account?.loyalty_program?.processing_network ? account?.loyalty_program?.processing_network.toUpperCase() : ''));
          if (exclusiveEventUnitName || exclusiveEventProgramType || eventProcessingNetwork) {
            return true;
          }
          return false;
        };
        const eventIdList = eventIds.split(',');
        eventIdList.forEach(singleEventId => {
          response.items.forEach((singleEvent) => {
            if (singleEvent.id === Number(singleEventId)) {
              const isSoldOut = checkSoldOut(singleEvent);
              if (isSoldOut || singleEvent.is_expired) {
                removeRecentEvent(singleEvent.id);
              } else {
                const removeEvent = handleRemoveEvent(singleEvent);
                if (removeEvent) {
                  removeRecentEvent(singleEvent.id);
                } else {
                  recentlyViewedEventArray.push(singleEvent);
                }
              }
            }
          });
        });
        setRecentEvents(recentlyViewedEventArray);
      } catch {
        // noop
      }
    };
    if (recentEventIds.length > 0) {
      void doFetchEvents(recentEventIds.join(','));
    } else {
      setRecentEvents([]);
    }
  }, [recentEventIds, fetchEvents]);

  return {
    ...props,
    recentEvents,
    removeRecentEvent,
  };
};

export default useInteractor;