import { LandingPageBannerBlockProps } from '../../blocks/LandingPageBannerBlock';
import { EventListingBlockProps } from '../../blocks/EventListingBlock';


type SportTeamsLandingPageInternalProps = {
  landingPageBannerBlock?: LandingPageBannerBlockProps;
  sportTeamsOpponent?: EventListingBlockProps;
  sportTeamsSchedule?: EventListingBlockProps;
};

type SportTeamsLandingPagePropsValues = {
  '': SportTeamsLandingPageInternalProps;
};

const propValues: SportTeamsLandingPagePropsValues = {
  '': {
    landingPageBannerBlock: {
    },
    sportTeamsOpponent: {
      state: 'Filled',
    },
    sportTeamsSchedule: {
      state: 'Filled',
    },
  },
};

const getProps = (type: string): SportTeamsLandingPageInternalProps => {
  const values: SportTeamsLandingPageInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
