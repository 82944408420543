import { CardProps } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { DatePartsType, checkSoldOut, handleVariableImage, handleLocationShort } from '../../../lib/util';
import { useAnalyticsManager } from '../../../modules/analytics/useAnalyticsManager';
import MLBEventImage from '../../../resources/legacyImages/mlbEventImage.png';

import { CardCombinedProps } from '../../molecules/Card/types';

import useInteractor from './ExclusivesEventBlock.interactor';
import { ExclusivesEventBlockCombinedProps } from './types';

const usePresenter = (props: ExclusivesEventBlockCombinedProps): ExclusivesEventBlockCombinedProps => {
  const { t } = useTranslation();
  const { trackEvent } = useAnalyticsManager();
  const { events, showMoreEventsButton } = useInteractor(props);
  let exclusiveEventCards: CardProps[] = [];

  const handleDateFormat = (date: Date) => {
    const dateParts: DatePartsType = new Intl.DateTimeFormat('en-US', {
      weekday: 'short',
      month: 'short',
      day: 'numeric',
    })
      .formatToParts(new Date(date))
      .reduce((acc, part) => {
        acc[part.type] = part.value;
        return acc;
      }, {});

    return `${dateParts.weekday}, ${dateParts.month} ${dateParts.day}`;
  };
  const handleTimeFormat = (date: Date) => {
    return new Intl.DateTimeFormat('en-US', {
      hour: 'numeric',
      minute: 'numeric',
    }).format(new Date(date));
  };

  // Intial Static Data for Pinned MLB event.
  const exclusiveEventCard: CardCombinedProps = {
    type: 'Default',
    format: 'Square',
    image: {
      imageSrc: MLBEventImage,
    },
    linkPath: 'mlb',
    eventInfo: {
      title: {
        value: t('exclusiveWidget.mlbEventTitle'),
      },
      date: {
        value: t('exclusiveWidget.mlbEventDate'),
      },
      location: {
        value: t('exclusiveWidget.mlbEventCity'),
      },
    },
    ariaLabel: t('exclusiveWidget.exclusiveEventScreenReaderText'),
  };

  if (events && events.length) {
    const eventCards: CardCombinedProps[] = [];
    for (let index = 0; index < events.length; index++) {
      const item = events[index];
      const isSoldOut = checkSoldOut(item);
      eventCards.push({
        type: 'Default',
        format: 'Square',
        image: {
          imageSrc: item && handleVariableImage(item, 'widget', 'mobile'),
        },
        linkPath: `/events/${item.id}`,
        eventInfo: {
          state: isSoldOut ? 'SoldOut' : 'Default',
          title: {
            value: item.name,
          },
          date: {
            value: handleDateFormat(item.local_date),
          },
          time: {
            value: t('exclusiveWidget.atTime', {
              time: handleTimeFormat(item.local_date),
            }),
          },
          location: {
            value: handleLocationShort(item.venue),
          },
          tag: {
            label: {
              value: t('exclusiveWidget.soldOut'),
              style: 'SemiBold',
            },
          },
        },
        ariaLabel: t('exclusiveWidget.exclusiveEventScreenReaderText'),
      });
    }
    exclusiveEventCards = [exclusiveEventCard, ...eventCards];
  }

  return {
    ...props,
    cardTitleBanner: {
      title: {
        value: t('exclusiveWidget.cardHolderTitle'),
      },
      message: {
        value: t('exclusiveWidget.cardHolderMessage'),
      },
    },
    exclusivesCardList: {
      cards: exclusiveEventCards,
    },
    button: {
      text: {
        value: t('exclusiveWidget.seeAllButton'),
      }, 
      onClick: () => {
        trackEvent('select_content_view_all_c1x');
      },
      href: '/exclusive-events',
    },
    showButton: showMoreEventsButton,
    showWidget: exclusiveEventCards.length >= 3,
  };
};

export default usePresenter;
