import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { useAnalyticsManager } from '../../../modules/analytics/useAnalyticsManager';
import exclusiveCategoryEvents from '../../../modules/navigation/ExclusiveCategoryEvents.json';
import { TopNavTab } from '../../../modules/navigation/types';
import { RegionContext } from '../../../modules/region';
import { DropdownOption } from '../../molecules/Filter/types';
import { dynamicRegionList, exclusiveDateFilterOptions, getLocation, locationFilterDefaultOptions } from '../../molecules/Filter/utils';

import { ExclusivesEventsLandingPageCombinedProps } from './types';

const presalesUrl = 'https://capitalone.com/pre-sales';
const diningUrl = 'https://www.capitalone.com/credit-cards/benefits/dining/';

const usePresenter = (props: ExclusivesEventsLandingPageCombinedProps): ExclusivesEventsLandingPageCombinedProps => {
  const eventCategory: TopNavTab[] = JSON.parse(JSON.stringify(exclusiveCategoryEvents));
  const { currentRegion, allRegions: locationList } = useContext(RegionContext);
  const { trackEvent } = useAnalyticsManager();
  const { t } = useTranslation();
  const { search } = useLocation();

  const [locationFilters, setLocationFilter] = useState<DropdownOption[]>([]);
  useEffect(() => {
    setLocationFilter(locationFilterDefaultOptions(currentRegion, true));
  }, [currentRegion]);
  
  const query = new URLSearchParams(search);
  const regionId = query.get('region_id');
  const defaultLocation = getLocation(locationList, regionId);

  const allCategory: TopNavTab = {
    id: 'exclusive',
    name: 'All Events',
    children: [],
  };

  const categories = [allCategory, ...eventCategory];

  useEffect(() => {
    trackEvent('exclusive_landing_page_view');
  }, []);

  const handleRegionFilter = (value: string) => {
    if (value.length >= 3) {
      if (locationList) {
        const options: DropdownOption[] = [
          ...locationFilterDefaultOptions(currentRegion, true),
          ...dynamicRegionList(locationList, value),
        ];
        setLocationFilter(options);
      }
    } else {
      const options: DropdownOption[] = [
        ...locationFilterDefaultOptions(currentRegion, true),
      ];
      setLocationFilter(options);
    }
  };

  return {
    ...props,
    eventListingBlock: {
      cardType: 'Exclusive',
      filterGroup: {
        categoryName: 'category',
        categories: categories,
        titleValue: t('shopByWidget.shopBy'),
        filters: [
          {
            type: 'IconTextFilter',
            filterIcon: 'LocationMarker',
            filterType: 'SearchLocationFilter',
            filterTitle: t('shopByWidget.near'),
            filterName: 'region_id',
            options: locationFilters,
            defaultSelectedOption: defaultLocation,
            onTextChanged: handleRegionFilter,
          },
          {
            type: 'IconFilter',
            filterType: 'DateFilter',
            filterIcon: 'Calendar',
            options: exclusiveDateFilterOptions,
          },
        ],
      },
    },
    primaryBannerBlock: {
      primaryBanner: {
        title: {
          value: t('exclusiveWidget.cardHolderTitle'),
          type: 'Subheading',
          size: 'Large',
          style: 'Regular',
          colour: 'BaseLight',
          align: 'Left',
        },
        message: {
          value: t('exclusiveWidget.cardHolderMessage'),
          type: 'Body',
          size: 'Large',
          style: 'Regular',
          colour: 'BaseLight',
          align: 'Left',
        },
        isExclusive: true,
      },
    },
    topBanner: {
      primaryBanner: {
        title: {
          value: t('exclusiveWidget.cardHolderTitle'),
          type: 'Subheading',
          size: 'Large',
          style: 'Regular',
          colour: 'BaseLight',
          align: 'Left',
        },
        message: {
          value: t('exclusiveWidget.cardHolderMessage'),
          type: 'Body',
          size: 'Large',
          style: 'Regular',
          colour: 'BaseLight',
          align: 'Left',
        },
        isExclusive: true,
      },
    },
    secondaryBannerBlock: {
      secondaryBanner: {
        title: {
          value: t('exclusiveBottomBanner.title'),
        },
        useButton: true,
        divider: undefined,
        button: {
          href: diningUrl,
          icon: undefined,
          text: {
            type: 'Body',
            size: 'Large',
            style: 'Regular',
            colour: 'BaseLight',
            align: 'Center',
            value: t('exclusiveBottomBanner.message'),
          },
        },
      },
    },
    heroCTABlock: {
      button: {
        text: {
          value: t('exclusiveWidget.presaleButton'),
        },
        href: presalesUrl,
        newTab: true,
      },
    },
  };
};

export default usePresenter;
